import axiosInstance from "../axios/axios";

class LoginService {
  async adminLogin(email, password) {
    try {
      const res = await axiosInstance.post("/auth/admin-login", {
        query: email,
        password: password,
      });

      console.log("res=>", res);
      return res;
    } catch (err) {
      console.log(err.message);
    }
  }

  async sendOtp(email) {
    try {
      const res = await axiosInstance.get(
        `/auth/get-forgetPassword-otp?query=${email}&getOtpFor=admin`
      );

      console.log("res=>", res);
      return res;
    } catch (err) {
      console.log(err.message);
    }
  }

  async resetPassword(newPass, conf, email) {
    try {
      const res = await axiosInstance.post(`/auth/reset-password`, {
        query: email,
        newPassword: newPass,
        confirmPassword: conf,
        resetPasswordFor: "admin",
      });

      console.log("res=>", res);
      return res;
    } catch (err) {
      console.log(err.message);
    }
  }

  async changePassword(newPass, oldPass) {
    try {
      const res = await axiosInstance.post(`/admin/changepassword`, {
        currentPassword: newPass,
        newPassword: oldPass,
      });

      // console.log("res=>", res);
      if (res.data.success) {
        return true;
      } else {
        return false;
      }
    } catch (err) {
      console.log(err.message);
    }
  }
}

export const loginService = new LoginService();
