import React from 'react'
import { Link, useLocation } from "react-router-dom";

const titles = {
    "/dashboard": "Dashboard",
    "/community": "Community",
    "/message": "Message",
    "/sub-admin/add-subAdmin":"Add Sub Admin",
    "/sub-admin/view-subDomin": "Sub Admin Details",
    "/sub-admin/edit-subDomin": "Edit Sub Admin",
    "/sub-admin": "Sub Admin",
    "/reported-post": "Reported Post",
    "/notification": "Notification",

    "/cms/edit-containt": "Edit CMS",
    "/cms": "CMS",

    "/interest-edit": "Edit Category",
    "/interest": { parent: "Configuration", child: "Interest" },

    "/report/view":  "Report Details" ,
    "/report": { parent: "Configuration", child: "Report" },
    "/education/view": "Education Details",
    "/education": { parent: "Configuration", child: "Education" },

    "/business-category/view": "Business Category Details",
    "/business-category": { parent: "Configuration", child: "Business Category" },
    "/area-of-expertise/view": "Area of Expertise Details",
    "/area-of-expertise": { parent: "Configuration", child: "Area of Expertise" },

    "/user-list": { parent: "User", child: "User List" },
    "/user-details": { parent: "User", child: "User Details" },
    "/verify-account": { parent: "User", child: "Verify Account" },
    "/expert-request": { parent: "User", child: "Expert Request" },
    "/upgradation-request": { parent: "User", child: "Upgradation Request" },
  };
  

function HeadTitle() {
    const location = useLocation();

    const getTitle = (path) => {
      for (const [key, value] of Object.entries(titles)) {
        // console.log(key, value);
        if (path.startsWith(key)) {
          if (typeof value === "string") {
            return <h3 className="HeaderTitle">{value}</h3>;
          }
          if (typeof value === "object") {
            return (
              <h3 className="HeaderTitle">
                <span>{value.parent}</span>
                <i className="fa-solid fa-chevron-right"></i>
                {value.child}
              </h3>
            );
          }
        }
      }
      return null; // Default case if no match
    };
  
    return <>{getTitle(location.pathname)}</>;
}

export default HeadTitle