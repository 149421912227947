import thumbnail from "../../assets/images/thumbnail.svg";
import graph from "../../assets/images/graph.svg";
import { configurationService } from "../../services/ConfigurationService";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setLoader } from "../../store/LoaderSlice";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { showNotification } from "../../helper/helper";
import AreaOfExpertiesPop from "./Popups/AreaOfExpertiesPop";
import Swal from "sweetalert2";
import AddAreaOfExpertise from "./Popups/AddAreaOfExpertise";
import UnauthorzePage from "./UnauthorzePage";

const AreaofExpertise = () => {
  const dispatch = useDispatch();
  const [hasAccess, setHasAccess] = useState(true); 
  const permissions = useSelector((state) => state.admin.value.permissions);

  const [editEducation,setEducationReport]=useState("");

  const [currentClick,setCurrentClick]=useState("")
  const [reLoadPage,setReLoadPage] = useState(true)
  const [reportStatus,setReportStatus]=useState("")
  const [areaOfExpt, setareaOfExpt] = useState([]);

    // ==============pagination code===================

    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
  
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = areaOfExpt.slice(indexOfFirstItem, indexOfLastItem);
  
  const totalPages = Math.ceil(areaOfExpt.length / itemsPerPage);
  
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  
  
    // =================pagination code ends =================





  const [interestsBackup, setinterestsBackup] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showAddReportModal, setShowAddReportModal] = useState(false);

  //states for search limit and pagination.

  const [search, setsearch] = useState("");
  const [searchedString, setsearchedString] = useState("");
  const [limit, setlimit] = useState(10);

  // .....................................=====modal data==========
  const handleClose = () => setShowModal(false);
  const handleCloseAddReport = () =>setShowAddReportModal(false);
  
  const handleShow = (id,status,item) =>{
    if(permissions.some((perm) => perm.module == "Configuration / Area Of Expertise" && perm.fullAccess != true)){
      return showNotification("error","Access denied","you are not allowed to access")
    };
    setReportStatus(status)
    setEducationReport(item.areaOfExpertiseName)
    setCurrentClick(id);
    setShowModal(true);
  } 

  const handleShowModel=()=>{
    if(permissions.some((perm) => perm.module == "Configuration / Area Of Expertise" && perm.fullAccess != true)){
      return showNotification("error","Access denied","you are not allowed to access")
    };

    setShowAddReportModal(true)
   }


  

  useEffect(() => {

    const access = permissions.some(
      (perm) =>
        perm.module === "Configuration / Area Of Expertise" && perm.readAccess === true
    );

    if (!access) {
      setHasAccess(false); 
      return; 
    }


    getInterests();
  }, [reLoadPage]);

  useEffect(() => {
    if (search !== "") {
      const lowerCaseSearch = search.toLowerCase();
      const filtered =areaOfExpt.filter(
        (record) =>
          record.areaOfExpertiseName.toLowerCase().includes(lowerCaseSearch) ||
          record.status.toLowerCase().includes(lowerCaseSearch)
        
      );
      if (filtered.length) {
        setareaOfExpt(filtered);
      } else {
        toast("No match found.");
      }
    }
  }, [search]);

  useEffect(() => {
    const limitedData = interestsBackup.slice(0, limit);
    if (limitedData.length) {
    }
    console.log("Limit =", limitedData);
  }, [limit]);

  async function getInterests() {
    dispatch(setLoader(true));

    const res = await configurationService.getInterest("areaOfExpertise");

    if (res?.success) {
      // console.log("businessCategory",res.data)
      setareaOfExpt(res.data);
      setinterestsBackup(res.data);
    }

    dispatch(setLoader(false));
  }



  async function deleteConfigHandler(id) {
    if(permissions.some((perm) => perm.module == "Configuration / Area Of Expertise" && perm.fullAccess != true)){
      return showNotification("error","Access denied","you are not allowed to access")
    };
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "OK",
      cancelButtonText: "Cancel",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        const data = {
          configType: "areaOfExpertise",
          reportId: id,
        };
  
        try {
          const res = await configurationService.deleteConfigData(data);
          if (res?.success) {
            console.log("delete", res);
            showNotification("success", "deleted", "deleted successfully");
            setReLoadPage(prevStatus => !prevStatus);
            setinterestsBackup(res.data);
          }
        } catch (error) {
          console.error("Error deleting config:", error);
        } finally {
          dispatch(setLoader(false));
        }
      }
    });
  }
  

  const editFormHandler=async(e)=>{

    e.preventDefault()
    if(editEducation.trim() !== ""){
    const data=  {
      configType:"areaOfExpertise",
      reportId: currentClick,
      areaOfExpertiseName:editEducation,
  
      // status:isActive ? "active" : 'inactive',
  
    }      
  
    const res = await configurationService.updateConfigData(data);
    console.log("response",res)
    if (res?.success) {
      // console.log("Reports",res?.data)
      showNotification("success","update","updated successfully")
      setReLoadPage(prevStatus =>!prevStatus)
      setEducationReport("")
      setinterestsBackup(res.data);
      handleClose()
    }
    dispatch(setLoader(false));

  }else{
    toast("Please provide an input.")
  }
  
  }

     // ================================Switch Handler Code===============================
     const statusHandler=async(item)=>{

      if(permissions.some((perm) => perm.module == "Configuration / Area Of Expertise" && perm.fullAccess != true)){
        return showNotification("error","Access denied","you are not allowed to access")
      };
      const newStatus = item.status === "active" ? "inactive" : "active";
      const data=  {
        configType:"areaOfExpertise",
        reportId: item._id,
        status:newStatus
      }      
      const res = await configurationService.updateConfigData(data);
   
      if (res?.success) {
  
   // ==============getintrest data-==============================
        dispatch(setLoader(true));
        const res = await configurationService.getInterest("areaOfExpertise");
        if (res?.success) {
          // console.log("businessCategory", res.data);
          setareaOfExpt(res.data);
          setinterestsBackup(res.data);
        }
    
      dispatch(setLoader(false));
    
      // ==============genintrest data end-==============================
  
    
        setinterestsBackup(res.data);
      }
      dispatch(setLoader(false));
    }
    
  
 // ====================== Render UnauthorzePage if no access===================
 if (!hasAccess) {
  return <UnauthorzePage />; 
}

  return (
    <>
      <div className="WrapperArea">
        <div className="WrapperBox">
        
   {/* ===============filter body================= */}
   <div className="FilterBody">
            <div className="FilterLeft d-flex gap-1">
              <div className="form-group">
                <input
                  type="text"
                  value={searchedString}
                  className="form-control"
                  placeholder="Search For Area Of Expertise"
                  onChange={(e) => setsearchedString(e.target.value)}
                />
                <span className="Icon">
                  <i className="fa-solid fa-magnifying-glass" />
                </span>
              </div>
              <div className="d-flex gap-1">
                {searchedString.length ? (
                  <button
                    className="btn btn-outline-dark mx-2"
                    onClick={() => {
                      searchedString !== ""
                        ? setsearch(searchedString)
                        : toast("Please provide an input.");
                    }}
                  >
                    Search
                  </button>
                ) : null}
                {search.length ? (
                  <button
                    className="Button"
                    onClick={() => {
                      // setinterests(interestsBackup);
                      setareaOfExpt(interestsBackup);
                      setsearch("");
                      setsearchedString("");
                    }}
                  >
                    Clear
                  </button>
                ) : null}
              </div>
            </div>
            <div className="FilterRight">
              <Link className="Button" onClick={()=>handleShowModel()}>
                Add New &nbsp; <i className="fa-solid fa-circle-plus" />
              </Link>
            </div>
          </div>
          {/* =================filter body=================== */}
          <div className="TableHeader">
            <h5>Total Area of Expertise: {currentItems?.length}/{areaOfExpt.length}</h5>
          </div>
          <div className="TableList">
            <table>
              <thead>
                <tr>
                  <th>Sr No.</th>
                  <th>Area of Expertise</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {
                  currentItems.length?

                   currentItems?.map((item,index)=>(

                <tr>
                  <td>{index+1}</td>
                  <td>{item.areaOfExpertiseName}</td>
                  <td>
                    <div className="Actions">
                      <label className="switch">
                      <input
                            type="checkbox"
                            checked={item.status== "active"} onClick={()=>statusHandler(item)}
                         />
                        <span className="slider" />
                      </label>
                    </div>
                  </td>
                  <td>
                    <div className="Actions">
                      <Link className="Blue" to={"/area-of-expertise/view"} state={{item:item}}>
                        <i className="fa fa-eye" />
                      </Link>
                      <a
                        className="Green"
                        data-toggle="modal"
                        data-target="#EditAreasOfExpertise"
                       onClick={()=>handleShow(item._id,item.status,item)}
                      >
                        <i className="fa fa-pencil" />
                      </a>
                      <a
                        className="Red"
                        data-toggle="modal"
                        data-target="#DeleteExpertise"
                      >
                        <i className="fa fa-trash" onClick={()=>deleteConfigHandler(item._id)} />
                      </a>
                    </div>
                  </td>
                </tr>
                  ))
                  :<div>No data found</div>
                }
             
              </tbody>
            </table>
          </div>
          <div className="Pagination">
            <div className="Left">
              <p>Show Records:</p>
              <select className="form-control" value={itemsPerPage} onChange={(e)=>setItemsPerPage(e.target.value)}>
                <option selected="" value={10}>10</option>
                <option value={20}>20</option>
                <option value={30}>30</option>
                <option value={40}>40</option>
              </select>
            </div>
            <ul>

            <li>
                <a
                  href="javascript:void(0);"
                  onClick={() => {
                    if (currentPage > 1) {
                      handlePageChange(currentPage - 1);
                    }
                  }}
                  className={currentPage === 1 ? "disabled" : ""}
                >
                  <i className="fa-solid fa-chevron-left" />
                </a>
          </li>

          
             {[...Array(totalPages)].map((_, i) => {
              const pageNumber = (i + 1).toString().padStart(2, "0"); // Pad single-digit numbers
              return (
                <li key={i} onClick={() => handlePageChange(i + 1)}>
                  <a
                    href="javascript:void(0);"
                    className={currentPage === i + 1 ? "active" : ""}
                  >
                    {pageNumber}
                  </a>
                </li>
              );
            })}

              {/* <li>
                <a href="javascript:void(0);">
                  <i className="fa-solid fa-chevron-left" />
                </a>
              </li> */}
              {/* <li>
                <a href="javascript:void(0);" className="active">
                  01
                </a>
              </li> */}
              {/* <li>
                <a href="javascript:void(0);">02</a>
              </li> */}
              {/* <li>
                <a href="javascript:void(0);">03</a>
              </li> */}


<li>
              <a
                href="javascript:void(0);"
                onClick={() => {
                  if (currentPage < totalPages) {
                    handlePageChange(currentPage + 1);
                  }
                }}
                className={currentPage === totalPages ? "disabled" : ""}
              >
                <i className="fa-solid fa-chevron-right" />
              </a>
            </li>
              {/* <li>
                <a href="javascript:void(0);">
                  <i className="fa-solid fa-chevron-right" />
                </a>
              </li> */}
            </ul>
          </div>
        </div>
      </div>

      
      <div className="ModalBox">
  <div id="AddEducationModal" className="modal fade MediumModal" role="dialog">
    <div className="modal-dialog">
      <div className="modal-content">
        <AreaOfExpertiesPop
      
             show={showModal} 
             handleClose={handleClose} 
             showModal={showModal}
             editEducation={editEducation}
             setEducationReport={setEducationReport}
              editFormHandler={editFormHandler}
             
       />
      </div>
    </div>
  </div>
  <div id="EditEducationModal" className="modal fade" role="dialog">
    <div className="modal-dialog">
      <div className="modal-content">
        <div className="modal-body">
          <div className="Category">
            <a
              href="javascript:void(0);"
              className="CloseModal"
              data-dismiss="modal"
            >
              ×
            </a>
            <h4>Configuration - Education</h4>
            <div className="form-group">
              <label>Education</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter education"
              />
            </div>
            <div className="d-flex justify-content-center">
              <button className="Button Cancel mr-3" data-dismiss="modal">
                Cancel
              </button>
              <button className="Button ml-3" data-dismiss="modal">
                Update
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div id="DeleteEducation" className="modal fade" role="dialog">
    <div className="modal-dialog">
      <div className="modal-content">
        <div className="modal-body">
          <div className="Decline">
            <a
              href="javascript:void(0);"
              className="CloseModal"
              data-dismiss="modal"
            >
              ×
            </a>
            <h3>Delete</h3>
            <p>Are you sure you want to delete this Education ?</p>
            <h4>
              <a href="javascript:void(0);" data-dismiss="modal">
                no
              </a>
              <a href="javascript:void(0);" data-dismiss="modal">
                Yes
              </a>
            </h4>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div id="LogOutModal" className="modal fade SmallModal" role="dialog">
    <div className="modal-dialog">
      <div className="modal-content">
        <div className="modal-body">
          <div className="Category">
            <a
              href="javascript:void(0);"
              className="CloseModal"
              data-dismiss="modal"
            >
              ×
            </a>
            <div className="LogOutModalArea">
              <span>
                <img src="images/logout-icon.png" />
              </span>
              <h5>Log Out</h5>
              <p>Are you sure you want to log out?</p>
              <div className="Buttons TwoButtons">
                <button
                  type="button"
                  className="Button Cancel"
                  data-dismiss="modal"
                >
                  No
                </button>
                <a href="login.html" className="Button Red">
                  Yes
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

 <AddAreaOfExpertise  
      showModal={showAddReportModal} 
      handleClose={handleCloseAddReport}
      setReLoadPage={setReLoadPage}
     
      />

    </>
  );
};

export default AreaofExpertise;
