import React, { useState } from "react";
import { setLoader } from "../../store/LoaderSlice";
import { useDispatch } from "react-redux";
import { loginService } from "../../services/LoginService";
import { toast } from "react-toastify";
import { showNotification } from "../../helper/helper";
import { useNavigate } from "react-router-dom";

const ChangePass = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [currentPassword, setCurrentPassword] = useState(""); // State for current password
  const [newPassword, setNewPassword] = useState(""); // State for new password
  const [confirmPassword, setConfirmPassword] = useState(""); // State for confirm password
  const [showCurrentPassword, setShowCurrentPassword] = useState(false); // Toggle for current password visibility
  const [showNewPassword, setShowNewPassword] = useState(false); // Toggle for new password visibility
  const [showConfirmPassword, setShowConfirmPassword] = useState(false); // Toggle for confirm password visibility

  // Function to toggle visibility
  const toggleVisibility = (setter) => {
    setter((prevState) => !prevState);
  };

  async function handlePassChange(e) {
    e.preventDefault();
    if (newPassword === confirmPassword) {
      dispatch(setLoader(true));

      const res = await loginService.changePassword(
        currentPassword,
        newPassword
      );

      if (res) {
        showNotification(
          "success",
          "Password Changed",
          "Password Changed Successfuly"
        );
        setCurrentPassword("");
        setNewPassword("");
        setConfirmPassword("");
      } else {
        toast("Something went wrong. Try again later.");
      }

      dispatch(setLoader(false));
    } else {
      toast("Passwords do not match");
    }
  }

  return (
    <>
      <div className="WrapperArea">
        <div className="WrapperBox">
          <div className="PasswordBox">
            <article>
              <form className="CommonForm" onSubmit={handlePassChange}>
                {/* Current Password */}
                <div className="form-group">
                  <label>Current Password</label>
                  <input
                    type={showCurrentPassword ? "text" : "password"}
                    className="form-control"
                    placeholder="Enter Current Password"
                    value={currentPassword}
                    minLength="6"
                    pattern=".*[\W_].*"
                    required
                    onChange={(e) => setCurrentPassword(e.target.value)} // Set current password state
                  />
                  <span
                    className="Icon"
                    onClick={() => toggleVisibility(setShowCurrentPassword)} // Toggle current password visibility
                  >
                    <i
                      className={`fa ${
                        showCurrentPassword ? "fa-eye-slash" : "fa-eye"
                      }`}
                    />
                  </span>
                </div>

                {/* New Password */}
                <div className="form-group">
                  <label>New Password</label>
                  <input
                    type={showNewPassword ? "text" : "password"}
                    className="form-control"
                    placeholder="Enter New Password"
                    value={newPassword}
                    minLength="6"
                    pattern=".*[\W_].*"
                    required
                    onChange={(e) => setNewPassword(e.target.value)} // Set new password state
                  />
                  <span
                    className="Icon"
                    onClick={() => toggleVisibility(setShowNewPassword)} // Toggle new password visibility
                  >
                    <i
                      className={`fa ${
                        showNewPassword ? "fa-eye-slash" : "fa-eye"
                      }`}
                    />
                  </span>
                </div>

                {/* Confirm Password */}
                <div className="form-group">
                  <label>Confirm Password</label>
                  <input
                    type={showConfirmPassword ? "text" : "password"}
                    className="form-control"
                    placeholder="Re-enter Password"
                    value={confirmPassword}
                    minLength="6"
                    pattern=".*[\W_].*"
                    required
                    onChange={(e) => setConfirmPassword(e.target.value)} // Set confirm password state
                  />
                  <span
                    className="Icon"
                    onClick={() => toggleVisibility(setShowConfirmPassword)} // Toggle confirm password visibility
                  >
                    <i
                      className={`fa ${
                        showConfirmPassword ? "fa-eye-slash" : "fa-eye"
                      }`}
                    />
                  </span>
                </div>

                {/* Cancel and Submit Buttons */}
                <div className="d-flex gap-1">
                  <a
                    href="javascript:void(0)"
                    className="Button Cancel "
                    onClick={() => navigate(-1)}
                  >
                    Cancel
                  </a>
                  <button className="Button">Submit</button>
                </div>
              </form>
            </article>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChangePass;
