import axiosInstance from "../axios/axios";
class BucketService {
  async getCreds() {
    try {
      const res = await axiosInstance.get("/getEncryptedBucketData");
      console.log("bucket", res);
      if (res.data.success) {
        return {
          success: true,
          access: res.data.data.REACT_APP_BUCKET_ACCESS_KEY,
          secret: res.data.data.REACT_APP_BUCKET_SECRET_KEY,
        };
      } else {
        return {
          success: false,
        };
      }
      //   if (res.data.success) {
      //     const data = res.data.data;

      //     const access = this.decrypt(
      //       data.encryptAccessKey,
      //       data.decryptionAlgoConfig.algorithm,
      //       data.decryptionAlgoConfig.key,
      //       data.decryptionAlgoConfig.iv
      //     );
      //     const secret = this.decrypt(
      //       data.encryptSecretKey,
      //       data.decryptionAlgoConfig.algorithm,
      //       data.decryptionAlgoConfig.key,
      //       data.decryptionAlgoConfig.iv
      //     );

      //     console.log("Decrypted keys= ", access, secret);
    } catch (err) {
      console.log(err.message);
    }
  }

  //   decrypt(encryptedText, algo, key, v) {
  //     let decipher = crypto.createDecipheriv(algo, key, v);
  //     let decrypted = decipher.update(encryptedText, "hex", "utf8");
  //     decrypted += decipher.final("utf8");
  //     return decrypted;
  //   }
}

export const bucketService = new BucketService();
