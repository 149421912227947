import tune from "../../assets/images/tune.svg";
import filter from "../../assets/images/filter-img.svg";
import { Dropdown, Button, Form, Col } from "react-bootstrap";
import UserTrend from "../graphs/UserTrend";
import UserActivityChart from "../graphs/UserActivity";
import UserRegister from "../graphs/UserRegister";
import PostDistribution from "../graphs/PostDistribution";
import CommunityPopularity from "../graphs/CommunityPopularity";
import CountUp from 'react-countup';
const Dashboard = () => {
  return (
    <>
      <div className="WrapperArea">
        <div className="WrapperBox">
          <h2 className="heading">
            Overview <span>(As of today)</span>
          </h2>
          <div className="row">
            <div className="col-sm-4">
              <div className="Dashboard Dashboard4">
                <figcaption>
                  <h4>Active Users</h4>
                  <h2>32.90M</h2>
                </figcaption>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="Dashboard Dashboard5">
                <figcaption>
                  <h4>Total Post Created</h4>
                  <h2>32.90M</h2>
                </figcaption>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="Dashboard Dashboard6">
                <figcaption>
                  <h4>Post Engagement</h4>
                  <h2>
                    {" "}
                    <span style={{ fontWeight: 400 }}>Likes -</span> 45.90M
                  </h2>
                  <h2>
                    {" "}
                    <span style={{ fontWeight: 400 }}>Comments -</span> 25.09K
                  </h2>
                </figcaption>
              </div>
            </div>
          </div>
          <h2 className="heading">Active Users</h2>
          <div className="row">
            <div className="col-sm-4">
              <div className="Dashboard Dashboard4">
                <figcaption>
                  <h4>Individal Users</h4>
                  <h2>32</h2>
                </figcaption>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="Dashboard Dashboard5">
                <figcaption>
                  <h4>Business Users</h4>
                  <h2>12</h2>
                </figcaption>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="Dashboard Dashboard6">
                <figcaption>
                  <h4>Expert Users</h4>
                  <h2>15</h2>
                </figcaption>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <div className="ChartArea">
                <div className="ChartHeading">
                  <h4>User Trend</h4>
                  {/* <div className="DashboardDropdown">
                    <div className="dropdown">
                      <a
                        id="dropdownMenuButton"
                        href="javascript:void(0);"
                        className="ImgIcon"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <img src={tune} />
                      </a>
                      <div
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuButton"
                      >
                        <div className="DateRangeBox">
                          <h3>Date Range</h3>
                          <ul>
                            <li>
                              <a href="javascript:void(0);">Last Month</a>
                            </li>
                            <li>
                              <a href="javascript:void(0);">Last 3 Months</a>
                            </li>
                            <li>
                              <a href="javascript:void(0);">This Quarter</a>
                            </li>
                            <li>
                              <a href="javascript:void(0);">Last Quarter</a>
                            </li>
                            <li>
                              <a href="javascript:void(0);">This Year</a>
                            </li>
                            <li>
                              <a href="javascript:void(0);">Last Year</a>
                            </li>
                          </ul>
                          <div className="form-group">
                            <input type="date" className="form-control" />
                          </div>
                          <div className="form-group">
                            <button className="ApplyBtn">Apply</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}

                  <Dropdown className="DashboardDropdown" drop="down">
                    <Dropdown.Toggle
                      as="a"
                      id="dropdownMenuButton"
                      className="ImgIcon"
                    >
                      <img src={tune} alt="Tune icon" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="custom-dropdown-menu">
                      <div className="DateRangeBox">
                        <h3>Date Range</h3>
                        <ul>
                          <li>
                            <Dropdown.Item href="#">Last Month</Dropdown.Item>
                          </li>
                          <li>
                            <Dropdown.Item href="#">
                              Last 3 Months
                            </Dropdown.Item>
                          </li>
                          <li>
                            <Dropdown.Item href="#">This Quarter</Dropdown.Item>
                          </li>
                          <li>
                            <Dropdown.Item href="#">Last Quarter</Dropdown.Item>
                          </li>
                          <li>
                            <Dropdown.Item href="#">This Year</Dropdown.Item>
                          </li>
                          <li>
                            <Dropdown.Item href="#">Last Year</Dropdown.Item>
                          </li>
                        </ul>
                        <Form.Group>
                          <Form.Control type="date" />
                        </Form.Group>
                        <Form.Group>
                          <Button className="ApplyBtn">Apply</Button>
                        </Form.Group>
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <div id="SingleLineChart " />
                <UserTrend />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="ChartArea">
                <div className="ChartHeading">
                  <h4>User Activity Over Time</h4>
                  <div className="d-flex align-items-center">
                    <div className="FilterBody mb-0">
                      <div className="FilterRight">
                        <div className="form-group">
                          {/* <div className="dropdown">
                            <a
                              className="Filter border-0"
                              href="javascript:void(0);"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <img src={filter} />
                            </a>
                            <div
                              className="dropdown-menu Width190"
                              aria-labelledby="dropdownMenuLink"
                            >
                              <ul>
                                <li className="FilterOprions">
                                  <h5>Filter Options</h5>
                                </li>
                                <li
                                  className="FilterOprions"
                                  style={{
                                    borderBottom:
                                      "1px solid rgba(153, 153, 153, 1)",
                                  }}
                                >
                                  <h5>All</h5>
                                </li>
                                <li>
                                  <a
                                    href="javascript:void(0);"
                                    className="dropdown-item"
                                  >
                                    Post
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="javascript:void(0);"
                                    className="dropdown-item"
                                  >
                                    Comments
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="javascript:void(0);"
                                    className="dropdown-item"
                                  >
                                    Likes
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="javascript:void(0);"
                                    className="dropdown-item"
                                  >
                                    Shares
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div> */}
                          <Dropdown className="dropdown">
                            <Dropdown.Toggle
                              as="a"
                              className="Filter border-0"
                              id="dropdownMenuLink"
                            >
                              <img src={filter} />
                            </Dropdown.Toggle>
                            <Dropdown.Menu
                              className="Width190 custom-dropdown-menu"
                              aria-labelledby="dropdownMenuLink"
                            >
                              <ul>
                                <li className="FilterOprions">
                                  <h5>Filter Options</h5>
                                </li>
                                <li
                                  className="FilterOprions"
                                  style={{
                                    borderBottom:
                                      "1px solid rgba(153, 153, 153, 1)",
                                  }}
                                >
                                  <h5>All</h5>
                                </li>
                                <li>
                                  <Dropdown.Item href="#">Post</Dropdown.Item>
                                </li>
                                <li>
                                  <Dropdown.Item href="#">
                                    Comments
                                  </Dropdown.Item>
                                </li>
                                <li>
                                  <Dropdown.Item href="#">Likes</Dropdown.Item>
                                </li>
                                <li>
                                  <Dropdown.Item href="#">Shares</Dropdown.Item>
                                </li>
                              </ul>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </div>
                    </div>
                    {/* <div className="DashboardDropdown">
                      <div className="dropdown">
                        <a
                          id="dropdownMenuButton"
                          href="javascript:void(0);"
                          className="ImgIcon"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <img src={tune} />
                        </a>
                        <div
                          className="dropdown-menu"
                          aria-labelledby="dropdownMenuButton"
                        >
                          <div className="DateRangeBox">
                            <h3>Date Range</h3>
                            <ul>
                              <li>
                                <a href="javascript:void(0);">Last Month</a>
                              </li>
                              <li>
                                <a href="javascript:void(0);">Last 3 Months</a>
                              </li>
                              <li>
                                <a href="javascript:void(0);">This Quarter</a>
                              </li>
                              <li>
                                <a href="javascript:void(0);">Last Quarter</a>
                              </li>
                              <li>
                                <a href="javascript:void(0);">This Year</a>
                              </li>
                              <li>
                                <a href="javascript:void(0);">Last Year</a>
                              </li>
                            </ul>
                            <div className="form-group">
                              <input type="date" className="form-control" />
                            </div>
                            <div className="form-group">
                              <button className="ApplyBtn">Apply</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> */}

                    <Dropdown className="DashboardDropdown" drop="down">
                      <Dropdown.Toggle
                        as="a"
                        id="dropdownMenuButton"
                        className="ImgIcon"
                      >
                        <img src={tune} alt="Tune icon" />
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="custom-dropdown-menu">
                        <div className="DateRangeBox">
                          <h3>Date Range</h3>
                          <ul>
                            <li>
                              <Dropdown.Item href="#">Last Month</Dropdown.Item>
                            </li>
                            <li>
                              <Dropdown.Item href="#">
                                Last 3 Months
                              </Dropdown.Item>
                            </li>
                            <li>
                              <Dropdown.Item href="#">
                                This Quarter
                              </Dropdown.Item>
                            </li>
                            <li>
                              <Dropdown.Item href="#">
                                Last Quarter
                              </Dropdown.Item>
                            </li>
                            <li>
                              <Dropdown.Item href="#">This Year</Dropdown.Item>
                            </li>
                            <li>
                              <Dropdown.Item href="#">Last Year</Dropdown.Item>
                            </li>
                          </ul>
                          <Form.Group>
                            <Form.Control type="date" />
                          </Form.Group>
                          <Form.Group>
                            <Button className="ApplyBtn">Apply</Button>
                          </Form.Group>
                        </div>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
                <div id="SingleLineChart2 " />
                <UserActivityChart />
              </div>
            </div>
          </div>
          <div className="ChartArea">
            <div className="ChartHeading">
              <h4>New User Registration</h4>
              {/* <div className="DashboardDropdown">
                <div className="dropdown">
                  <a
                    id="dropdownMenuButton"
                    href="javascript:void(0);"
                    className="ImgIcon"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <img src={tune} />
                  </a>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <div className="DateRangeBox">
                      <h3>Date Range</h3>
                      <ul>
                        <li>
                          <a href="javascript:void(0);">Last Month</a>
                        </li>
                        <li>
                          <a href="javascript:void(0);">Last 3 Months</a>
                        </li>
                        <li>
                          <a href="javascript:void(0);">This Quarter</a>
                        </li>
                        <li>
                          <a href="javascript:void(0);">Last Quarter</a>
                        </li>
                        <li>
                          <a href="javascript:void(0);">This Year</a>
                        </li>
                        <li>
                          <a href="javascript:void(0);">Last Year</a>
                        </li>
                      </ul>
                      <div className="form-group">
                        <input type="date" className="form-control" />
                      </div>
                      <div className="form-group">
                        <button className="ApplyBtn">Apply</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}

              <Dropdown className="DashboardDropdown" drop="down">
                <Dropdown.Toggle
                  as="a"
                  id="dropdownMenuButton"
                  className="ImgIcon"
                >
                  <img src={tune} alt="Tune icon" />
                </Dropdown.Toggle>
                <Dropdown.Menu
                  aria-labelledby="dropdownMenuButton"
                  className="custom-dropdown-menu"
                >
                  <div className="DateRangeBox">
                    <h3>Date Range</h3>
                    <ul>
                      <li>
                        <Dropdown.Item href="#">Last Month</Dropdown.Item>
                      </li>
                      <li>
                        <Dropdown.Item href="#">Last 3 Months</Dropdown.Item>
                      </li>
                      <li>
                        <Dropdown.Item href="#">This Quarter</Dropdown.Item>
                      </li>
                      <li>
                        <Dropdown.Item href="#">Last Quarter</Dropdown.Item>
                      </li>
                      <li>
                        <Dropdown.Item href="#">This Year</Dropdown.Item>
                      </li>
                      <li>
                        <Dropdown.Item href="#">Last Year</Dropdown.Item>
                      </li>
                    </ul>
                    <Form.Group>
                      <Form.Control type="date" className="form-control" />
                    </Form.Group>
                    <Form.Group>
                      <Button className="ApplyBtn">Apply</Button>
                    </Form.Group>
                  </div>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div id="chartContainer">
              <UserRegister />
            </div>
          </div>
          <div className="ChartArea">
            <div className="ChartHeading">
              <h4>Post Distribution by Category</h4>
              {/* <div className="DashboardDropdown">
                <div className="dropdown">
                  <a
                    id="dropdownMenuButton"
                    href="javascript:void(0);"
                    className="ImgIcon"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <img src={tune} />
                  </a>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <div className="DateRangeBox">
                      <h3>Date Range</h3>
                      <ul>
                        <li>
                          <a href="javascript:void(0);">Last Month</a>
                        </li>
                        <li>
                          <a href="javascript:void(0);">Last 3 Months</a>
                        </li>
                        <li>
                          <a href="javascript:void(0);">This Quarter</a>
                        </li>
                        <li>
                          <a href="javascript:void(0);">Last Quarter</a>
                        </li>
                        <li>
                          <a href="javascript:void(0);">This Year</a>
                        </li>
                        <li>
                          <a href="javascript:void(0);">Last Year</a>
                        </li>
                      </ul>
                      <div className="form-group">
                        <input type="date" className="form-control" />
                      </div>
                      <div className="form-group">
                        <button className="ApplyBtn">Apply</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}

              <Dropdown className="DashboardDropdown" drop="down">
                <Dropdown.Toggle
                  as="a"
                  id="dropdownMenuButton"
                  className="ImgIcon"
                >
                  <img src={tune} alt="Tune icon" />
                </Dropdown.Toggle>
                <Dropdown.Menu
                  aria-labelledby="dropdownMenuButton"
                  className="custom-dropdown-menu"
                >
                  <div className="DateRangeBox">
                    <h3>Date Range</h3>
                    <ul>
                      <li>
                        <Dropdown.Item href="#">Last Month</Dropdown.Item>
                      </li>
                      <li>
                        <Dropdown.Item href="#">Last 3 Months</Dropdown.Item>
                      </li>
                      <li>
                        <Dropdown.Item href="#">This Quarter</Dropdown.Item>
                      </li>
                      <li>
                        <Dropdown.Item href="#">Last Quarter</Dropdown.Item>
                      </li>
                      <li>
                        <Dropdown.Item href="#">This Year</Dropdown.Item>
                      </li>
                      <li>
                        <Dropdown.Item href="#">Last Year</Dropdown.Item>
                      </li>
                    </ul>
                    <Form.Group>
                      <Form.Control type="date" className="form-control" />
                    </Form.Group>
                    <Form.Group>
                      <Button className="ApplyBtn">Apply</Button>
                    </Form.Group>
                  </div>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div id="postDistribution">
              <PostDistribution />
            </div>
          </div>
          <div className="ChartArea">
            <div className="ChartHeading">
              <h4>Community Popularity</h4>
              {/* <div className="DashboardDropdown">
                <div className="dropdown">
                  <a
                    id="dropdownMenuButton"
                    href="javascript:void(0);"
                    className="ImgIcon"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <img src={tune} />
                  </a>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <div className="DateRangeBox">
                      <h3>Date Range</h3>
                      <ul>
                        <li>
                          <a href="javascript:void(0);">Last Month</a>
                        </li>
                        <li>
                          <a href="javascript:void(0);">Last 3 Months</a>
                        </li>
                        <li>
                          <a href="javascript:void(0);">This Quarter</a>
                        </li>
                        <li>
                          <a href="javascript:void(0);">Last Quarter</a>
                        </li>
                        <li>
                          <a href="javascript:void(0);">This Year</a>
                        </li>
                        <li>
                          <a href="javascript:void(0);">Last Year</a>
                        </li>
                      </ul>
                      <div className="form-group">
                        <input type="date" className="form-control" />
                      </div>
                      <div className="form-group">
                        <button className="ApplyBtn">Apply</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}

              <Dropdown className="DashboardDropdown" drop="down">
                <Dropdown.Toggle
                  as="a"
                  id="dropdownMenuButton"
                  className="ImgIcon"
                >
                  <img src={tune} alt="Tune icon" />
                </Dropdown.Toggle>
                <Dropdown.Menu
                  aria-labelledby="dropdownMenuButton"
                  className="custom-dropdown-menu"
                >
                  <div className="DateRangeBox">
                    <h3>Date Range</h3>
                    <ul>
                      <li>
                        <Dropdown.Item href="#">Last Month</Dropdown.Item>
                      </li>
                      <li>
                        <Dropdown.Item href="#">Last 3 Months</Dropdown.Item>
                      </li>
                      <li>
                        <Dropdown.Item href="#">This Quarter</Dropdown.Item>
                      </li>
                      <li>
                        <Dropdown.Item href="#">Last Quarter</Dropdown.Item>
                      </li>
                      <li>
                        <Dropdown.Item href="#">This Year</Dropdown.Item>
                      </li>
                      <li>
                        <Dropdown.Item href="#">Last Year</Dropdown.Item>
                      </li>
                    </ul>
                    <Form.Group>
                      <Form.Control type="date" className="form-control" />
                    </Form.Group>
                    <Form.Group>
                      <Button className="ApplyBtn">Apply</Button>
                    </Form.Group>
                  </div>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div id="communityChart">
              <CommunityPopularity />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
