
import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { configurationService } from '../../../services/ConfigurationService';
import { useDispatch } from 'react-redux';
import { setLoader } from '../../../store/LoaderSlice';
import { showNotification } from '../../../helper/helper';
import { toast } from 'react-toastify';

function AddBussinessPop({ showModal, handleClose,setReLoadPage  }) {
    const dispatch = useDispatch();

    const [addData,setAddData] =useState("")
  
   
  
    const AddFormHandler=async(e)=>{
      e.preventDefault();
      if(addData.trim() !== "" ){

        const  data={
        configType:"businessCategory",
        businessCategory:{
          businessCategoryName:addData,
            status:"active"
        }
        }
        try {
       
          const res=await configurationService.addInterest(data)
          if(res.success){
           
            showNotification("success","Bussiness ","bussiness added successfully!")
            handleClose()
            setReLoadPage(prevStatus => !prevStatus);
            setAddData("")
           
          }
          dispatch(setLoader(false));
        } catch (error) {
          console.log(error)
        }
      }else{
        toast("Please provide an input.")
      }
    }

  return (
    <>
    <Modal show={showModal} onHide={handleClose}>
      <Modal.Body >
      <div className="modal-body">
            <div className="Category">
              <div style={{textAlign: 'end'}}>
  
              <a 
                href="javascript:void(0);"
                className="CloseModal"
                data-dismiss="modal"
  
               onClick={()=>handleClose()} 
              >
                ×
              </a>
              </div>
              <h3>Add Business Category</h3>
              <form onSubmit={AddFormHandler}>
  
              <div className="form-group">
                <label>Business Category</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Business Category"
                  // value={editReport.Report_Name}
                  name='Report_Name'
                  onChange={(e)=>setAddData(e.target.value)}
                />
              </div>
             
              <div className="d-flex justify-content-evenly mt-4">
                <p className="Button Cancel mr-3 mx-3"  data-dismiss="modal"   onClick={()=>handleClose()} style={{cursor:"pointer"}}>
                  Cancel
                </p>
                <button className="Button" data-dismiss="modal">
                  Submit
                </button>
              </div>
              </form>
  
            </div>
          </div>
  
          
  
    </Modal.Body>
    </Modal>
    </>
  )
}

export default AddBussinessPop