import "./App.css";
import Home from "./components/Pages/Home";
import Dashboard from "./components/Pages/Dashboard";
import NotFound from "./components/Pages/NotFound";
import { HashRouter as Router, Routes, Route } from "react-router-dom";
import Layout from "./components/Layout/Layout";
import "bootstrap/dist/css/bootstrap.min.css";
import Community from "./components/Pages/Community";
import Message from "./components/Pages/Message";
import SubAdmin from "./components/Pages/SubAdmin";
import Notification from "./components/Pages/Notification";
import Cms from "./components/Pages/Cms";
import ReportedPost from "./components/Pages/ReportedPost";
import UserList from "./components/Pages/UserList";
import VerifyAccount from "./components/Pages/VerifyAccount";
import ExpertRequest from "./components/Pages/ExpertRequest";
import Interest from "./components/Pages/Interest";
import Report from "./components/Pages/Report";
import Education from "./components/Pages/Education";
import BusinessCategory from "./components/Pages/BusinessCategory";
import AreaofExpertise from "./components/Pages/AreaOfExpertise";
import ForgotPassword from "./components/Pages/ForgotPassword";
import LoginOtp from "./components/Pages/LoginOtp";
import LoginReset from "./components/Pages/LoginReset";
import ChangePass from "./components/Pages/ChangePass";
import UserDetails from "./components/InternalPages/UserDetails";
import ViewUserDetails from "./components/InternalPages/ViewUserDetails";
import AddCategory from "./components/InternalPages/AddCategory";
import IntrestEdit from "./components/Pages/configuration/IntrestEdit";
import ReportView from "./components/Pages/configuration/ReportView";
import EducationView from "./components/Pages/configuration/EducationView";
import BusinessView from "./components/Pages/configuration/BusinessView";
import AreaOfExperties from "./components/Pages/configuration/AreaOfExperties";
import CmsEdit from "./components/Pages/CmsEdit";
import EditSubAdmin from "./components/Pages/EditSubAdmin";
import ViewSubAdmin from "./components/Pages/viewSubAdmin";
import AddSubAdmin from "./components/Pages/AddSubAdmin";
import Upgradation_request from "./components/Pages/Upgradation_request";
import UnauthorzePage from "./components/Pages/UnauthorzePage";
import ProtectedRoute from "./components/ProtectedRoute/ProtectedRoute";
import ViewProtechtedRoute from "./components/ProtectedRoute/ViewProtechtedRoute";
import CommunityDetails from "./components/Pages/CommunityDetails";





function App() {
  return (
    <Router>
      <Layout>
        <Routes>
          <Route path="/" element={<Home />} />
          {/* <Route path="/unauthorize" element={<UnauthorzePage />} /> */}
       
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/send-otp" element={<LoginOtp />} />
          <Route path="/reset-password" element={<LoginReset />} />
          <Route path="/change-password" element={<ChangePass />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/user-list" element={<UserList />} />


          <Route path="/user-details/:pagename/:id" element={
            <ViewProtechtedRoute >
              <UserDetails />
            </ViewProtechtedRoute>
          } 
            />

          <Route path="/verify-account" element={<VerifyAccount />} />
          <Route path="/expert-request" element={<ExpertRequest />} />
          <Route path="/upgradation-request" element={<Upgradation_request/>} />

          <Route path="/view-details" element={<ViewUserDetails />} />

          {/* ===========Community Routes======== */}
          <Route path="/interest" element={<Interest />} />

          {/* <Route path="/add-category" element={<AddCategory />} /> */}
          <Route path="/add-category" element={ 
            <ProtectedRoute module="Configuration / Interest">
            <AddCategory />
            </ProtectedRoute>
        } />

         {/* <Route path="/interest-edit" element={<IntrestEdit />} /> */}
            <Route path="/interest-edit" element={
                <ProtectedRoute module="Configuration / Interest">
                  <IntrestEdit />
              </ProtectedRoute>} 
        />


          <Route path="/report" element={<Report />} />
          <Route path="/education" element={<Education />} />
          <Route path="/business-category" element={<BusinessCategory />} />
          <Route path="/area-of-expertise" element={<AreaofExpertise />} />


  

     {/* ========================view more Community============== */} 
          <Route path="/report/view" element={<ReportView />} />


          <Route path="/education/view" element={
            <ViewProtechtedRoute module="Configuration / Education">
              <EducationView/>
            </ViewProtechtedRoute>
            } />
           
          {/* <Route path="/business-category/view" element={<BusinessView/>} /> */}
          <Route path="/business-category/view" element={
            <ViewProtechtedRoute module="Configuration / Business Cateogry">
              <BusinessView/>
            </ViewProtechtedRoute>
          } />

          <Route path="/area-of-expertise/view" element={
          <ViewProtechtedRoute module="Configuration / Area Of Expertise">
           <AreaOfExperties  />
          </ViewProtechtedRoute>
            }
            
             />

          <Route path="/community" element={<Community />} />
          <Route path="/community/community-details/:id" element={<CommunityDetails />} />


          <Route path="/message" element={<Message />} />
          <Route path="/reported-post" element={<ReportedPost />} />

          <Route path="/sub-admin" element={<SubAdmin />} />
          <Route path="/sub-admin/edit-subDomin" element={<EditSubAdmin />} />
          <Route path="/sub-admin/view-subDomin" element={<ViewSubAdmin />} />
          <Route path="/sub-admin/add-subAdmin" element={<AddSubAdmin />} />


          <Route path="/notification" element={<Notification />} />
          <Route path="/cms" element={<Cms />} />
        
       
          <Route path="/cms/edit-containt"  element={
            <ProtectedRoute module="CMS">
            <CmsEdit />
            </ProtectedRoute>
          }/>
          {/* <Route path="/cms/edit-containt" element={<CmsEdit />} /> */}
          
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Layout>
    </Router>
  );
}

export default App;
