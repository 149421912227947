import thumbnail from "../../assets/images/thumbnail.svg";
import graph from "../../assets/images/graph.svg";
import { configurationService } from "../../services/ConfigurationService";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setLoader } from "../../store/LoaderSlice";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import BussinessPop from "./Popups/BussinessPop";
import { showNotification } from "../../helper/helper";
import Swal from "sweetalert2";
import AddBussinessPop from "./Popups/AddBussinessPop";
import UnauthorzePage from "./UnauthorzePage";

const BusinessCategory = () => {
  const dispatch = useDispatch();
  const [hasAccess, setHasAccess] = useState(true); // Default: true
  const permissions = useSelector((state) => state.admin?.value?.permissions);
  const [businessData, setBusinessData] = useState([]);
  const [interestsBackup, setinterestsBackup] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showAddReportModal, setShowAddReportModal] = useState(false);

  // ==============pagination code===================

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = businessData.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(businessData.length / itemsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // =================pagination code ends =================

  //states for search limit and pagination.

  const [currentClick, setCurrentClick] = useState("");
  const [reLoadPage, setReLoadPage] = useState(true);
  const [reportStatus, setReportStatus] = useState("");

  const [search, setsearch] = useState("");
  const [searchedString, setsearchedString] = useState("");
  const [limit, setlimit] = useState(10);

  const [editEducation, setEducationReport] = useState("");

  useEffect(() => {

    const access = permissions?.some(
      (perm) =>
        perm.module === "Configuration / Business Cateogry" && perm.readAccess === true
    );

    if (!access) {
      setHasAccess(false); 
      return; 
    }

    getInterests();
  }, [reLoadPage]);
  //.....................................

  const handleClose = () => setShowModal(false);
  const handleCloseAddReport = () => setShowAddReportModal(false);
  const handleShow = (id, status, item) => {

    if(permissions?.some((perm) => perm.module == "Configuration / Business Cateogry" && perm.fullAccess != true)){
      return showNotification("error","Access denied","you are not allowed to access")
    };
    
    setEducationReport(item?.businessCategoryName);
    setReportStatus(status);
    setCurrentClick(id);
    setShowModal(true);
  };

  const handleShowModel = () => {
    if(permissions?.some((perm) => perm.module == "Configuration / Business Cateogry" && perm.fullAccess != true)){
      return showNotification("error","Access denied","you are not allowed to access")
    };
    setShowAddReportModal(true);
  };

  useEffect(() => {
    if (search !== "") {
      const lowerCaseSearch = search.toLowerCase();
      const filtered = businessData.filter(
        (record) =>
          record?.businessCategoryName.toLowerCase().includes(lowerCaseSearch) ||
          record.status.toLowerCase().includes(lowerCaseSearch)
      );
      if (filtered.length) {
        setBusinessData(filtered);
      } else {
        toast("No match found.");
      }
    }
  }, [search]);

  useEffect(() => {
    const limitedData = interestsBackup.slice(0, limit);
    if (limitedData.length) {
    }

    console.log("Limit =", limitedData);
  }, [limit]);

  async function getInterests() {
    dispatch(setLoader(true));
    const res = await configurationService.getInterest("businessCategory");
    if (res?.success) {
      console.log("businessCategory", res.data);
      setBusinessData(res.data);
      setinterestsBackup(res.data);
    }

    dispatch(setLoader(false));
  }

  async function deleteConfigHandler(id) {
    if(permissions?.some((perm) => perm.module == "Configuration / Business Cateogry" && perm.fullAccess != true)){
      return showNotification("error","Access denied","you are not allowed to access")
    };
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "OK",
      cancelButtonText: "Cancel",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        const data = {
          configType: "businessCategory",
          reportId: id,
        };
        const res = await configurationService.deleteConfigData(data);
        if (res?.success) {
          console.log("delete", res);
          showNotification("success", "deleted", "deleted successfully");
          setReLoadPage((prevStatus) => !prevStatus);
          setinterestsBackup(res.data);
        }
      }
      dispatch(setLoader(false));
    });
  }

  const editFormHandler = async (e) => {
    e.preventDefault();
    if(editEducation.trim() !== ""){
    const data = {
      configType: "businessCategory",
      reportId: currentClick,
      businessCategoryName: editEducation,
    };

    const res = await configurationService.updateConfigData(data);
    console.log("response", res);
    if (res?.success) {
      showNotification("success", "update", "updated successfully");
      setReLoadPage((prevStatus) => !prevStatus);
      setEducationReport("");
      setinterestsBackup(res.data);
      handleClose();
    }

    dispatch(setLoader(false));

  }else{
    toast("Please provide an input.")
  }


  };

   // ================================Switch Handler Code===============================
   const statusHandler=async(item)=>{

    if(permissions?.some((perm) => perm.module == "Configuration / Business Cateogry" && perm.fullAccess != true)){
      return showNotification("error","Access denied","you are not allowed to access")
    };

    const newStatus = item.status === "active" ? "inactive" : "active";
    const data=  {
      configType:"businessCategory",
      reportId: item._id,
      status:newStatus
    }      
    const res = await configurationService.updateConfigData(data);
 
    if (res?.success) {

 // ==============getintrest data-==============================
      dispatch(setLoader(true));
      const res = await configurationService.getInterest("businessCategory");
      if (res?.success) {
        console.log("businessCategory", res.data);
        setBusinessData(res.data);
        setinterestsBackup(res.data);
      }
  
    dispatch(setLoader(false));
  
    // ==============genintrest data end-==============================

  
      setinterestsBackup(res.data);
    }
    dispatch(setLoader(false));
  }
  

  // ====================== Render UnauthorzePage if no access===================
   if (!hasAccess) {
    return <UnauthorzePage />; 
  }

  return (
    <>
      <div className="WrapperArea">
        <div className="WrapperBox">
          {/* ===============filter body================= */}
          <div className="FilterBody">
            <div className="FilterLeft d-flex gap-1">
              <div className="form-group">
                <input
                  type="text"
                  value={searchedString}
                  className="form-control"
                  placeholder="Search For Business Category"
                  onChange={(e) => setsearchedString(e.target.value)}
                />
                <span className="Icon">
                  <i className="fa-solid fa-magnifying-glass" />
                </span>
              </div>
              <div className="d-flex gap-1">
                {searchedString.length ? (
                  <button
                    className="btn btn-outline-dark mx-2"
                    onClick={() => {
                      searchedString !== ""
                        ? setsearch(searchedString)
                        : toast("Please provide an input.");
                    }}
                  >
                    Search
                  </button>
                ) : null}
                {search.length ? (
                  <button
                    className="Button"
                    onClick={() => {
                      // setinterests(interestsBackup);
                      setBusinessData(interestsBackup);
                      setsearch("");
                      setsearchedString("");
                    }}
                  >
                    Clear
                  </button>
                ) : null}
              </div>
            </div>
            <div className="FilterRight">
              <Link className="Button" onClick={() => handleShowModel()}>
                Add New &nbsp; <i className="fa-solid fa-circle-plus" />
              </Link>
            </div>
          </div>
          {/* =================filter body end=================== */}

          <div className="TableHeader">
            <h5>
              Total Business Category: {currentItems?.length}/
              {businessData.length}
            </h5>
          </div>
          <div className="TableList">
            <table>
              <thead>
                <tr>
                  <th>Sr No.</th>
                  <th>Business Category</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>

                {
                  currentItems.length?
                currentItems?.map((item, index) => (
                  <tr>
                    <td>{index + 1}</td>
                    <td>{item?.businessCategoryName}</td>
                    <td>
                      <div className="Actions">
                        <label className="switch">
                          <input
                            type="checkbox"
                            checked={item.status== "active"} onClick={()=>statusHandler(item)}
                          />
                          <span className="slider" />
                        </label>
                      </div>
                    </td>
                    <td>
                      <div className="Actions">
                        <Link
                          className="Blue"
                          to={"/business-category/view"}
                          state={{ item: item }}
                        >
                          <i className="fa fa-eye" />
                        </Link>
                        <a
                          className="Green"
                          data-toggle="modal"
                          data-target="#EditBusinessCategory"
                          onClick={() =>
                            handleShow(item._id, item.status, item)
                          }
                        >
                          <i className="fa fa-pencil" />
                        </a>
                        <a
                          className="Red"
                          data-toggle="modal"
                          data-target="#DeleteBusinessCategory"
                        >
                          <i
                            className="fa fa-trash"
                            onClick={() => deleteConfigHandler(item?._id)}
                          />
                        </a>
                      </div>
                    </td>
                  </tr>
                ))
                :<div>No data found</div>
              }
              </tbody>
            </table>
          </div>
          <div className="Pagination">
            <div className="Left">
              <p>Show Records:</p>
              <select
                className="form-control"
                value={itemsPerPage}
                onChange={(e) => setItemsPerPage(e.target.value)}
              >
                <option selected="" value={10}>
                  10
                </option>
                <option value={20}>20</option>
                <option value={30}>30</option>
                <option value={40}>40</option>
              </select>
            </div>
            <ul>
              <li>
                <a
                  href="javascript:void(0);"
                  onClick={() => {
                    if (currentPage > 1) {
                      handlePageChange(currentPage - 1);
                    }
                  }}
                  className={currentPage === 1 ? "disabled" : ""}
                >
                  <i className="fa-solid fa-chevron-left" />
                </a>
              </li>
              {[...Array(totalPages)].map((_, i) => {
                const pageNumber = (i + 1).toString().padStart(2, "0"); // Pad single-digit numbers
                return (
                  <li key={i} onClick={() => handlePageChange(i + 1)}>
                    <a
                      href="javascript:void(0);"
                      className={currentPage === i + 1 ? "active" : ""}
                    >
                      {pageNumber}
                    </a>
                  </li>
                );
              })}

              {/* <li>
                <a href="javascript:void(0);">
                  <i className="fa-solid fa-chevron-left" />
                </a>
              </li> */}

              {/* <li>
                <a href="javascript:void(0);">02</a>
              </li>
             */}

              <li>
                <a
                  href="javascript:void(0);"
                  onClick={() => {
                    if (currentPage < totalPages) {
                      handlePageChange(currentPage + 1);
                    }
                  }}
                  className={currentPage === totalPages ? "disabled" : ""}
                >
                  <i className="fa-solid fa-chevron-right" />
                </a>
              </li>

              {/* <li>
                <a href="javascript:void(0);">
                  <i className="fa-solid fa-chevron-right" />
                </a>
              </li> */}
            </ul>
          </div>
        </div>
      </div>

      <div className="ModalBox">
        <div
          id="AddEducationModal"
          className="modal fade MediumModal"
          role="dialog"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <BussinessPop
                show={showModal}
                handleClose={handleClose}
                showModal={showModal}
                editEducation={editEducation}
                setEducationReport={setEducationReport}
                editFormHandler={editFormHandler}
              />
            </div>
          </div>
        </div>
        <div id="EditEducationModal" className="modal fade" role="dialog">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-body">
                <div className="Category">
                  <a
                    href="javascript:void(0);"
                    className="CloseModal"
                    data-dismiss="modal"
                  >
                    ×
                  </a>
                  <h3>Configuration - Education</h3>
                  <div className="form-group">
                    <label>Education</label>
                    <input
                      type="password"
                      className="form-control"
                      placeholder="Enter education"
                    />
                  </div>
                  <div className="d-flex justify-content-center">
                    <button className="Button Cancel mr-3" data-dismiss="modal">
                      Cancel
                    </button>
                    <button className="Button ml-3" data-dismiss="modal">
                      Update
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="DeleteEducation" className="modal fade" role="dialog">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-body">
                <div className="Decline">
                  <a
                    href="javascript:void(0);"
                    className="CloseModal"
                    data-dismiss="modal"
                  >
                    ×
                  </a>
                  <h3>Delete</h3>
                  <p>Are you sure you want to delete this Education ?</p>
                  <h4>
                    <a href="javascript:void(0);" data-dismiss="modal">
                      no
                    </a>
                    <a href="javascript:void(0);" data-dismiss="modal">
                      Yes
                    </a>
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="LogOutModal" className="modal fade SmallModal" role="dialog">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-body">
                <div className="Category">
                  <a
                    href="javascript:void(0);"
                    className="CloseModal"
                    data-dismiss="modal"
                  >
                    ×
                  </a>
                  <div className="LogOutModalArea">
                    <span>
                      <img src="images/logout-icon.png" />
                    </span>
                    <h5>Log Out</h5>
                    <p>Are you sure you want to log out?</p>
                    <div className="Buttons TwoButtons">
                      <button
                        type="button"
                        className="Button Cancel"
                        data-dismiss="modal"
                      >
                        No
                      </button>
                      <a href="login.html" className="Button Red">
                        Yes
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <AddBussinessPop
        showModal={showAddReportModal}
        handleClose={handleCloseAddReport}
        setReLoadPage={setReLoadPage}
      />
    </>
  );
};

export default BusinessCategory;
