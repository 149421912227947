import React from 'react'
import { useLocation } from 'react-router-dom'


function BusinessView() {
    const location = useLocation()
  const { item } = location.state || ""

  return (
    <>
 <div className="WrapperArea">
  <div className="WrapperBox">
    <div className="Small-Wrapper">
      <div className="SubAdminBox mb-0">
        <figcaption>
          <p>
            <strong>Business Category</strong>
            <span>{item?.businessCategoryName}</span>
          </p>
          <p>
            <strong>Status</strong>
            <span>{item?.status}</span>
          </p>
        </figcaption>
      </div>
    </div>
  </div>
</div>



    </>
  )
}

export default BusinessView