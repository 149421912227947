import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { setLoader } from '../../../store/LoaderSlice';
import { showNotification } from '../../../helper/helper';
import { configurationService } from '../../../services/ConfigurationService';
import { toast } from 'react-toastify';


function AddEducationPop({ showModal, handleClose, setReLoadPage,education }) {
 const [addField,setAddField]=useState([1]);

   
  const dispatch = useDispatch();


  const [addData, setAddData] = useState("");
  // const [addData, setAddData] = useState({
  //   SelectedUniversity: "",
  //   universityName: ""
  // });

  // const inputHandler = (e) => {
  //   setAddData({ ...addData, [e.target.name]: e.target.value });
  // };

  const AddFormHandler = async(e) => {
    e.preventDefault();

    // if(addData.SelectedUniversity?.trim() !== "" && addData?.universityName?.trim() !== ""){
    if(addData.trim() !== ""){
      const isDuplicate = education.some(
        (edu) => edu.educationName.toLowerCase() === addData.toLowerCase()
      );
  
      if (isDuplicate) {
        toast("This name already exists in education.");
      } else {
        
        const  data={
          configType:"education",
          education:{
            educationName:addData,
             status:"active"
          }
          }
        // const  data={
        //   configType:"education",
        //   education:{
        //     educationName:addData.SelectedUniversity,
        //     universityName:addData.universityName,
        //      status:"active"
        //   }
        //   }
          try {
         
            const res=await configurationService.addInterest(data)
    
            if(res?.success){
             
              showNotification("success","Education "," Education name added successfully!")
              handleClose()
              setReLoadPage(prevStatus => !prevStatus);
              setAddData("")
            }
            dispatch(setLoader(false));
          } catch (error) {
            console.log(error)
          }
      }
    }else{
      toast("Please provide an input.")
    }


  };

  // const removeInput=(index)=>{
     
  //  // Create a new array by removing the item at the specified index
  // const updatedField = addField.filter((_, i) => i !== index);

  // // Set the updated array
  // setAddField(updatedField);
        
  // }

  return (
    <>
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Body>
       

        <div className="modal-body">
            <div className="Category">
              <div className="text-end">
                <a href="javascript:void(0);" className="CloseModal" data-dismiss="modal" onClick={() => handleClose()}>
                  ×
                </a>
              </div>
              <h3>Add Education</h3>
                <form onSubmit={AddFormHandler}>

                 
                      <div className="EducationBox">
                      <div className="row py-2">
                        <div className="col-lg-12">
                          <div className="form-group">
                            <label>Education Name</label>
                            <input type="text" 
                            className="form-control mt-3" 
                             placeholder='Enter the education name'
                             onChange={(e)=>setAddData(e.target.value)}
                             />

                            {/* ================Drop Down list+======================= */}
                            {/* <select
                              className="form-control"
                              name="SelectedUniversity"
                              value={addData.SelectedUniversity}
                              onChange={inputHandler}
                            >
                              <option value="">--select--</option>
                              <option value="graduate">Graduate</option>
                              <option value="post graduate">Post Graduate</option>
                            </select> */}



                          </div>
                        </div>

                        {/* ======================universty box============ */}
                        {/* <div className="col-lg-6">
                          <div className="form-group">
                            <label>University/College Name</label>
                            <input
                              type="text"
                              value={addData.universityName}
                              name="universityName"
                              className="form-control"
                              placeholder="Enter here.."
                              onChange={inputHandler}
                            />
                             
                          </div>
                        </div> */}
                        {/* ===========================University box end=============== */}
                      </div>
                  </div>

                   
      
              {/* ===============add more btn=============== */}

                 {/* <div className="row">
                    <div className="col-lg-12">
                      <div className="form-group text-end">
                        <button type="button" className="Button"
                        onClick={() => {
                          const lastValue =
                            addField.length > 0 ? Math.max(...addField) : 0;
                          setAddField([...addField, lastValue + 1]);
                        }}
                        >
                          Add More <i className="fa-solid fa-circle-plus" />
                        </button>
                      </div>
                    </div>
                   </div> */}

              {/* =================add more btn end================== */}
            
              <a className="Button Cancel mx-2" data-dismiss="modal" onClick={() => handleClose()} style={{cursor:"pointer"}}>
                    Cancel
                  </a>
                  <button type="submit" className="Button ml-3" data-dismiss="modal">
                    Submit
                  </button>
                  </form>
            </div>
          </div>
        </Modal.Body>



{/* ==========================modal body-====================== */}


        {/* <Modal.Body>
       

        <div className="modal-body">
            <div className="Category">
              <div className="text-end">
                <a href="javascript:void(0);" className="CloseModal" data-dismiss="modal" onClick={() => handleClose()}>
                  ×
                </a>
              </div>
              <h3>Configuration - Education</h3>
                <form onSubmit={AddFormHandler}>

                  {
                    addField.map((_,index)=>(
                      <div className="EducationBox">
                      <div className="row py-2">
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label>Education</label>
                            <select
                              className="form-control"
                              name="SelectedUniversity"
                              value={addData.SelectedUniversity}
                              onChange={inputHandler}
                            >
                              <option value="">--select--</option>
                              <option value="graduate">Graduate</option>
                              <option value="post graduate">Post Graduate</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label>University/College Name</label>
                            <input
                              type="text"
                              value={addData.universityName}
                              name="universityName"
                              className="form-control"
                              placeholder="Enter here.."
                              onChange={inputHandler}
                            />
                              {index == 0 ? null : (
                                  <button
                                    type="button"
                                    className="btn btn-sm btn-outline-dark"
                                    onClick={() => removeInput(index )}
                                  >
                                    Remove
                                  </button>
                                )}
                          </div>
                        </div>
                      </div>
                  </div>

                    ))
                  }
          <div className="EducationBox">
                  <div className="row py-2">
                    <div className="col-lg-6">
                      <div className="form-group">
                        <label>Education</label>
                        <select
                          className="form-control"
                          name="SelectedUniversity"
                          value={addData.SelectedUniversity}
                          onChange={inputHandler}
                        >
                          <option value="">--select--</option>
                          <option value="graduate">Graduate</option>
                          <option value="post graduate">Post Graduate</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <label>University/College Name</label>
                        <input
                          type="text"
                          value={addData.universityName}
                          name="universityName"
                          className="form-control"
                          placeholder="Enter here.."
                          onChange={inputHandler}
                        />
                      </div>
                    </div>
                  </div>
              </div> 

              {/* ===============add more btn=============== */}

                 {/* <div className="row">
                    <div className="col-lg-12">
                      <div className="form-group text-end">
                        <button type="button" className="Button"
                        onClick={() => {
                          const lastValue =
                            addField.length > 0 ? Math.max(...addField) : 0;
                          setAddField([...addField, lastValue + 1]);
                        }}
                        >
                          Add More <i className="fa-solid fa-circle-plus" />
                        </button>
                      </div>
                    </div>
                   </div> */}

              {/* =================add more btn end================== */}
            
              {/* <a className="Button Cancel mx-2" data-dismiss="modal" onClick={() => handleClose()}>
                    Cancel
                  </a>
                  <button type="submit" className="Button ml-3" data-dismiss="modal">
                    Submit
                  </button>
                  </form>
            </div>
          </div> */}
        {/* </Modal.Body> */} 
      </Modal>
    </>
  );
}

export default AddEducationPop;
