import filterImg from "../../assets/images/filter-img.svg";
import actress from "../../assets/images/actress-1.svg";
import report from "../../assets/images/report-1.png";
import round from "../../assets/images/round-1.svg";
import { useEffect, useRef, useState } from "react";
import { reportServices } from "../../services/ReportServices";
import { toast } from "react-toastify";
import roundedImg from "../../assets/images/round-1.svg"
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import { showNotification } from "../../helper/helper";
import {setLoader} from "../../store/LoaderSlice"

import Carousel from 'react-bootstrap/Carousel';



// =============react player==================
import ReactPlayer from 'react-player'
// import Swiper JS
import { Swiper, SwiperSlide } from "swiper/react";
// import required modules
import { Pagination } from 'swiper/modules';


// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import UnauthorzePage from "./UnauthorzePage";

// =============swiper .js============================


const ReportedPost = () => {
  const dispatch = useDispatch();
  const [hasAccess, setHasAccess] = useState(true); 
  const permissions = useSelector((state) => state.admin.value.permissions);
  const [isExpanded, setExpanded] = useState(false)

  const [expandedCardId, setExpandedCardId] = useState(null);




 
  // ==============Slider vairiable===============================
  const [index, setIndex] = useState(0);

  const [hideBtn,setHideBtn] = useState(true);


  // const descriptionToShow = isExpanded ? description : description.slice(0, 200);


  // const handleSelect = (selectedIndex) => {
  //   setIndex(selectedIndex);
  // };



  const [reportData,setReportedData]=useState([]);
  // const [showMoreDescription,setShowMoreDescription]=useState([]);
  const [showDes,setShowDes]=useState([]);
  const [userReportrdata,setUserReportrdata]=useState([]);
 

  useEffect(()=>{

    const access = permissions.some(
      (perm) =>
        perm.module === "Reported Post" && perm.readAccess === true
    );

    if (!access) {
      setHasAccess(false); 
      return; 
    }
    getReportedPosts();
  
  },[]);

// ===================================time to day convertions================================
function getRelativeTime(createdDate) {
  const createdTime = new Date(createdDate);
  const currentTime = new Date();

  const diffInMs = currentTime - createdTime; // Difference in milliseconds
  const diffInHours = Math.floor(diffInMs / (1000 * 60 * 60)); // Convert to hours
  const diffInDays = Math.floor(diffInHours / 24); // Convert to days

  // Logic for displaying time
  if (diffInHours < 24) {
    return `${diffInHours} ${diffInHours === 1 ? "hour" : "hours"} ago`;
  } else {
    return `${diffInDays} ${diffInDays === 1 ? "day" : "days"} ago`;
  }
}



  async function getReportedPosts(){
   try {
     const res=await reportServices.getReportedData();
     if(res.success){
      // console.log("Reported Data:",res.data)
       setReportedData(res.data.reportedPosts
       );
       const descriptions = res.data.reportedPosts.map((item) => ({
        description: item.reportedToPost.description,
      }));

      // Update showMoreDescription with the prepared array
      // setShowMoreDescription(descriptions);
     }else{
       toast.error(res.message);
     }
    
   } catch (error) {
    console.log(error)
   }

  }



  const getAllReportUser=async(item)=>{
    console.log("post user",item.postId)
    try {
      const res=await reportServices.getReportedUserData(item.postId);
      if(res.success){
        setUserReportrdata(res.data.reportedPosts)
       console.log("All Reported Users:",res.data.reportedPosts)
       
      }else{
        toast.error(res.message);
      }
     
    } catch (error) {
      
    }
  }

const handleShowMore=(index)=>{
  setExpandedCardId(index=== expandedCardId ? null : index);
 

}


const deletedPost=async(id)=>{
  Swal.fire({
    title: "Are you sure?",
    text: "You won't be able to revert this!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonText: "OK",
    cancelButtonText: "Cancel",
    reverseButtons: true,
  }).then(async (result) => {
    if (result.isConfirmed) {
     

      try {
        const res = await reportServices.deletedRepost(id);
        if (res?.success) {
          showNotification("success", "deleted", "deleted successfully");
        
          // setinterestsBackup(res.data);
        }
      } catch (error) {
        console.error("Error deleting config:", error);
      } finally {
        dispatch(setLoader(false));
      }
    }
  });
}

  // ====================== Render UnauthorzePage if no access===================
  if (!hasAccess) {
    return <UnauthorzePage />; 
  }

  return (


    <>

      <div className="WrapperArea">
        <div
          className="WrapperBox"
          style={{ padding: 0, minHeight: "calc(100vh - 60px)" }}
        >
          <div className="ReportedPostsBodyMain">
            <div className="ReportedPostsBody">
              <article>
                <div className="FilterBody">
                  <div className="FilterLeft">
                    <h3>All Reported Posts</h3>
                  </div>
                  <div className="FilterRight">
                    <div className="form-group">
                      <div className="dropdown">
                        <a
                          className="Filter"
                          href="javascript:void(0);"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <img src={filterImg} />
                        </a>
                        <div
                          className="dropdown-menu"
                          aria-labelledby="dropdownMenuLink"
                        >
                          <ul>
                            <li>
                              <a
                                href="javascript:void(0);"
                                className="dropdown-item"
                              >
                                All
                              </a>
                            </li>
                            <li>
                              <a
                                href="javascript:void(0);"
                                className="dropdown-item"
                              >
                                Individuals
                              </a>
                            </li>
                            <li>
                              <a
                                href="javascript:void(0);"
                                className="dropdown-item border-0"
                              >
                                Community
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {  
                reportData.length?
                  reportData.map((item,idx)=>(
                  
                    <div key={idx} className="mb-5">
                <div className="ProfileBody ">
                  <figure>
                    <img src={item.postCreator.profileImage} />
                  </figure>
                  <h3>
                    {item.postCreator.name} <span>{getRelativeTime(item.reportedToPost.createdAt)}
                    </span>
                  </h3>
                  <h4>
                    {item.postCreator.username}<span>{item.postCreator.numberOfFollowers}</span>Followers
                  </h4>
                  <h6>Days of Memories</h6>
              </div>


                <div className="row">
                  <div className="col-lg-7">
                    <div className="ReportLeft">
                      <h6>{item.reportedToPost.title}</h6>
                    
                    
                    <p>

                    {expandedCardId === idx
              ? item.reportedToPost.description
              : `${ item.reportedToPost.description.slice(0, 100)}...`
              }
                    {index==idx && isExpanded }
                    </p>
                  
                      <p>
                        {item.reportedToPost.tags?.map(tag => <span className="" >{tag}</span>)}
                        {/* #couple#happiness#infinite#together#life#bollywood#celebs#crickter#photos#photoshop#bollywoodstyle */}
                      </p>

                      {/* {item.reportedToPost.description.length > 100 && ( */}
               <button
            
         
               onClick={() => handleShowMore(idx)}
            // onClick={()=>setExpanded(true)}
                style={{
                  color: "blue",
                  background: "none",
                  border: "none",
                  cursor: "pointer",
                }}
              >
                 {expandedCardId === idx ? 'Show Less' : 'Show More'}
              </button>
            {/* )} */}
                    
                    </div>
                  </div>
                  <div className="col-lg-5">
                    <div className="ReportRight">
                      <a className="Red  cursor-pointer " onClick={()=>deletedPost(item.postId)} style={{cursor:"pointer"}} >
                        <i className="fa fa-trash"  />
                      </a>

                      <div className="owl-carousel owl-theme">

                        {/* ==========carousel start========= */}
                        {/* <Swiper 
                        pagination={true}
                         modules={[Pagination]} 
                         className="mySwiper"
                         > */}
                       <Swiper 
                       pagination={{ clickable: true }}
                       modules={[Pagination]}
                       className="mySwiper"
                       preventClicks={false}
                       preventClicksPropagation={false}
                         >
                        {/* <SwiperSlide>Slide 1</SwiperSlide>
                        <SwiperSlide>Slide 2</SwiperSlide> */}
                  
                  

      {/* <Carousel activeIndex={index} onSelect={handleSelect}> */}
  {item.reportedToPost.imagesOrVideos.map((data, idx) => (
    <>
    
    {data.fileType === "image" ? (

     <SwiperSlide className="mb-5">
 
        <img
          className="d-block w-100"
          src={data.uri} 
         
          alt="Carousel slide"
         style={{ height: "150px" }}
    />
    </SwiperSlide>
     ) : (
      <SwiperSlide>
         <video
          controls
          style={{ height: "150px" }}
        >
         <source src={data.uri} type={`video/mp4`} />
     
           {/* Your browser does not support the video tag. */}
         </video>
    </SwiperSlide>

  )}
  

    </>

    // <Carousel.Item key={idx}> {/* Add a unique key */}

    //   {data.fileType === "image" ? (
    //     <img
    //       className="d-block w-100"
    //       src={data.uri} // Dynamic image URL
    //       alt="Carousel slide"
    //       style={{ height: "150px" }}
    //     />
    //   ) : (
    //     <video
    //       controls
    //       style={{ height: "150px" }}
    //     >
    //       <source src={data.uri} type={`video/${data.fileType}`} />
    //       {/* Fallback text */}
    //       Your browser does not support the video tag.
    //     </video>
    //   )}
    // </Carousel.Item>
  ))}
{/* </Carousel> */}
</Swiper>

{/* ========================carousel end=================== */}

                        {/* <div className="item">
                          <figure>
                            <img src={report} />
                          </figure>
                        </div>
                        <div className="item">
                          <figure>
                            <img src={report} />
                          </figure>
                        </div>
                        <div className="item">
                          <figure>
                            <img src={report} />
                          </figure>
                        </div> */}
                      </div>
                    </div>
                  </div>


                  <div className="col-lg-12">
                    <div className="Card">
                      <h5>Reported By</h5>
                      <a onClick={()=>getAllReportUser(item)}>
                        <img src={roundedImg}  style={{cursor:"pointer"}}/>
                      </a>
                    </div>
                  </div>
                           
                </div>
                    </div>
                
                  ))
                  :<div>No data found</div>
                
                }

          </article>


{/*               
              <article>
                <div className="ProfileBody">
                  <figure>
                    <img src={actress} />
                  </figure>
                  <h3>
                    Anushka Sharma <span>9h</span>
                  </h3>
                  <h4>
                    @viratanushka <span>300</span>Followers
                  </h4>
                  <h6>Days of Memories</h6>
                </div>
                <div className="row">
                  <div className="col-lg-7">
                    <div className="ReportLeft">
                      <h6>This is the Bollywood Gossip</h6>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipisicing
                        elit. Consequatur cupiditate amet provident odio hic
                        necessitatibus voluptate.
                      </p>
                      <p>
                        #couple#happiness#infinite#together#life#bollywood#celebs#crickter#photos#photoshop#bollywoodstyle
                      </p>
                      <a href="javascript:void(0);">Show More</a>
                    </div>
                  </div>
                  <div className="col-lg-5">
                    <div className="ReportRight">
                      <a className="Red">
                        <i className="fa fa-trash" />
                      </a>
                      <div className="owl-carousel owl-theme">
                        <div className="item">
                          <figure> <img src={report} /></figure>
                        </div>
                        <div className="item">
                          <figure><img src={report} /> </figure>
                        </div>
                        <div className="item">
                          <figure><img src={report} /></figure>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="Card">
                      <h5>Reported By</h5>
                      <a href="javasctipt:void(0);">
                        <img src={report} />
                      </a>
                    </div>
                  </div>
                </div>
              </article>
              <article>
                <div className="ProfileBody">
                  <figure>
                    <img src={actress} />
                  </figure>
                  <h3>
                    Anushka Sharma <span>9h</span>
                  </h3>
                  <h4>
                    @viratanushka <span>300</span>Followers
                  </h4>
                  <h6>Days of Memories</h6>
                </div>
                <div className="row">
                  <div className="col-lg-7">
                    <div className="ReportLeft">
                      <h6>This is the Bollywood Gossip</h6>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipisicing
                        elit. Consequatur cupiditate amet provident odio hic
                        necessitatibus voluptate.
                      </p>
                      <p>
                        #couple#happiness#infinite#together#life#bollywood#celebs#crickter#photos#photoshop#bollywoodstyle
                      </p>
                      <a href="javascript:void(0);">Show More</a>
                    </div>
                  </div>
                  <div className="col-lg-5">
                    <div className="ReportRight">
                      <a className="Red">
                        <i className="fa fa-trash" />
                      </a>
                      <div className="owl-carousel owl-theme">
                        <div className="item">
                          <figure>
                            <img src={report} />
                          </figure>
                        </div>
                        <div className="item">
                          <figure>
                            <img src={report} />
                          </figure>
                        </div>
                        <div className="item">
                          <figure>
                            <img src={report} />
                          </figure>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="Card">
                      <h5>Reported By</h5>
                      <a href="javasctipt:void(0);">
                        <img src={round} />
                      </a>
                    </div>
                  </div>
                </div>
              </article> */}
            </div>

            <div className="ReportedByBody">
              <h1>
                {" "}
                <span>
                  {/* <img src="images/reported-by.svg" /> */}
                  <img  src={roundedImg}  style={{cursor:"pointer"}} />
                </span>{" "}
                Reported By
              </h1>

              {
                userReportrdata?.map((item,key)=>(

              <aside>
                {
                  item.reportedBy.map((data,index)=>(

                <div className="ProfileBody ">
                  <figure>
                    <img src={data.profileImage} />
                  </figure>
                  <h4>
                    {data.name}<span>{getRelativeTime(item.createdAt)}</span>
                  </h4>
                  <h5>{data.username}</h5>
                </div>
                  ))
                }
                {item.reportNameDetails.map((reason,i)=>(

                  <p className="mt-5">
                    Reason - <span>{reason.reportName}</span>
                  </p>
                ))}
              </aside>
                ))
              }
            


              {/* <aside>
                <div className="ProfileBody">
                  <figure>
                    <img src={actress} />
                  </figure>
                  <h4>
                    Rhea Sharma <span>1h ago</span>
                  </h4>
                  <h5>@rheasharma</h5>
                </div>
                <p>
                  Reason - <span>MisInformation</span>
                </p>
              </aside> */}
             
        
             
           
            
             
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReportedPost;
