import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { subAdminServices } from '../../services/subAdminServices';


function ViewSubAdmin() {
const location=useLocation();
  const {id} = location.state || ""; 
  const [subAdminDetails,setSubAdminDetails] = useState({})
  useEffect(()=>{
    getsubAdminDetails();
  },[id])

  const getsubAdminDetails=async()=>{

    try {
      const res=await subAdminServices.getSubAdminById(id);
      console.log(res.data.data)
      if(res.success){
        setSubAdminDetails(res.data.data)
      }
      
    } catch (error) {
      console.log(error)
    }

  }

  const  convertDate=(dateString)=>{
    const date=new Date(dateString);
    const year=date.getFullYear();
    const month=date.getMonth()+1;
    const day=date.getDate();
    return `${day}/${month}/${year}`;
   
  }


  return (
<>
<div className="WrapperArea">
  <div className="WrapperBox">
    <div className="Small-Wrapper">

      {/* { subAdminDetails?.map((item,idx)=>( */}
        <div div key={subAdminDetails?._id}>
      <div className="SubAdminBox">
        <figure>
          <img src={subAdminDetails?.profileImage} />
        </figure>
        <figcaption>
          <p>
            <strong>Sub Admin Name</strong>
            <span>{subAdminDetails?.name}</span>
          </p>
          <p>
            <strong>Sub Admin ID</strong>
            <span>{subAdminDetails?.adminId}</span>
          </p>
          <p>
            <strong>Registered Date</strong>
            <span>{convertDate(subAdminDetails?.createdAt)}</span>
          </p>
          <p>
            <strong>Role</strong>
            <span>{subAdminDetails?.roleName}</span>
          </p>
          <p>
            <strong> Email ID</strong>
            <span>{subAdminDetails?.email}</span>
          </p>
          <p>
            <strong> Contact No.</strong>
            <span>{subAdminDetails?.phoneNumber}</span>
          </p>
          <p>
            <strong>Status</strong>
            <span>{subAdminDetails?.status}</span>
          </p>
        </figcaption>
      </div>

      
    
      <div className="AdminAccess">
        <table className="table">
          <thead>
            <tr>
              <th>Module</th>
              <th width="150px">Read</th>
              <th width="150px">Full Access</th>
            </tr>
          </thead>
          <tbody>

{
          subAdminDetails?.permissions?.map((item,idx)=>(

            <tr>
              <td>
                <strong>{item.module}</strong>
              </td>
              <td>
                <div className="Read">
                  <input type="checkbox" defaultChecked=""  checked={item.readAccess == true ? true : false}/>
                  <span>
                    <i className="fa fa-eye" />
                  </span>
                </div>
              </td>
              <td>
                <div className="Access">
              
                <input type="checkbox" checked={item.fullAccess == true ? true : false} />

                  <span >
                    <i className="fa fa-lock "  />
                  </span>
                </div>
              </td>
            </tr>
          ))

        }
       



            {/* <tr>
              <td>
                <strong>User / User List</strong>
              </td>
              <td>
                <div className="Read">
                  <input type="checkbox" defaultChecked="" />
                  <span>
                    <i className="fa fa-eye" />
                  </span>
                </div>
              </td>
              <td>
                <div className="Access">
                  <input type="checkbox" />
                  <span>
                    <i className="fa fa-lock" />
                  </span>
                </div>
              </td>
            </tr>

            <tr>
              <td>
                <strong>User / Verify Account</strong>
              </td>
              <td>
                <div className="Read">
                  <input type="checkbox" defaultChecked="" />
                  <span>
                    <i className="fa fa-eye" />
                  </span>
                </div>
              </td>
              <td>
                <div className="Access">
                  <input type="checkbox" defaultChecked="" />
                  <span>
                    <i className="fa fa-lock" />
                  </span>
                </div>
              </td>
            </tr>

            <tr>
              <td>
                <strong>User / Expert Request</strong>
              </td>
              <td>
                <div className="Read">
                  <input type="checkbox" />
                  <span>
                    <i className="fa fa-eye" />
                  </span>
                </div>
              </td>
              <td>
                <div className="Access">
                  <input type="checkbox" defaultChecked="" />
                  <span>
                    <i className="fa fa-lock" />
                  </span>
                </div>
              </td>
            </tr>

            <tr>
              <td>
                <strong> Community </strong>
              </td>
              <td>
                <div className="Read">
                  <input type="checkbox" />
                  <span>
                    <i className="fa fa-eye" />
                  </span>
                </div>
              </td>
              <td>
                <div className="Access">
                  <input type="checkbox" defaultChecked="" />
                  <span>
                    <i className="fa fa-lock" />
                  </span>
                </div>
              </td>
            </tr>

            <tr>
              <td>
                <strong>Message</strong>
              </td>
              <td>
                <div className="Read">
                  <input type="checkbox" />
                  <span>
                    <i className="fa fa-eye" />
                  </span>
                </div>
              </td>
              <td>
                <div className="Access">
                  <input type="checkbox" defaultChecked="" />
                  <span>
                    <i className="fa fa-lock" />
                  </span>
                </div>
              </td>
            </tr>

            <tr>
              <td>
                <strong>Reported Post</strong>
              </td>
              <td>
                <div className="Read">
                  <input type="checkbox" defaultChecked="" />
                  <span>
                    <i className="fa fa-eye" />
                  </span>
                </div>
              </td>
              <td>
                <div className="Access">
                  <input type="checkbox" />
                  <span>
                    <i className="fa fa-lock" />
                  </span>
                </div>
              </td>
            </tr>

            <tr>
              <td>
                <strong>Configuration / Interest</strong>
              </td>
              <td>
                <div className="Read">
                  <input type="checkbox" defaultChecked="" />
                  <span>
                    <i className="fa fa-eye" />
                  </span>
                </div>
              </td>
              <td>
                <div className="Access">
                  <input type="checkbox" />
                  <span>
                    <i className="fa fa-lock" />
                  </span>
                </div>
              </td>
            </tr>

            <tr>
              <td>
                <strong>Configuration / Report</strong>
              </td>
              <td>
                <div className="Read">
                  <input type="checkbox" />
                  <span>
                    <i className="fa fa-eye" />
                  </span>
                </div>
              </td>
              <td>
                <div className="Access">
                  <input type="checkbox" />
                  <span>
                    <i className="fa fa-lock" />
                  </span>
                </div>
              </td>
            </tr>

            <tr>
              <td>
                <strong>Configuration / Education</strong>
              </td>
              <td>
                <div className="Read">
                  <input type="checkbox" defaultChecked="" />
                  <span>
                    <i className="fa fa-eye" />
                  </span>
                </div>
              </td>
              <td>
                <div className="Access">
                  <input type="checkbox" />
                  <span>
                    <i className="fa fa-lock" />
                  </span>
                </div>
              </td>
            </tr>

            <tr>
              <td>
                <strong>Configuration / Business Cateogry</strong>
              </td>
              <td>
                <div className="Read">
                  <input type="checkbox" />
                  <span>
                    <i className="fa fa-eye" />
                  </span>
                </div>
              </td>
              <td>
                <div className="Access">
                  <input type="checkbox" defaultChecked="" />
                  <span>
                    <i className="fa fa-lock" />
                  </span>
                </div>
              </td>
            </tr>

            <tr>
              <td>
                <strong>Configuration / Area Of Expertise</strong>
              </td>
              <td>
                <div className="Read">
                  <input type="checkbox" />
                  <span>
                    <i className="fa fa-eye" />
                  </span>
                </div>
              </td>
              <td>
                <div className="Access">
                  <input type="checkbox" defaultChecked="" />
                  <span>
                    <i className="fa fa-lock" />
                  </span>
                </div>
              </td>
            </tr>

            <tr>
              <td>
                <strong>Sub-Admin</strong>
              </td>
              <td>
                <div className="Read">
                  <input type="checkbox" defaultChecked="" />
                  <span>
                    <i className="fa fa-eye" />
                  </span>
                </div>
              </td>
              <td>
                <div className="Access">
                  <input type="checkbox" />
                  <span>
                    <i className="fa fa-lock" />
                  </span>
                </div>
              </td>
            </tr>


            <tr>
              <td>
                <strong>Notification</strong>
              </td>
              <td>
                <div className="Read">
                  <input type="checkbox" defaultChecked="" />
                  <span>
                    <i className="fa fa-eye" />
                  </span>
                </div>
              </td>
              <td>
                <div className="Access">
                  <input type="checkbox" />
                  <span>
                    <i className="fa fa-lock" />
                  </span>
                </div>
              </td>
            </tr>

            <tr>
              <td>
                <strong>CMS</strong>
              </td>
              <td>
                <div className="Read">
                  <input type="checkbox" defaultChecked="" />
                  <span>
                    <i className="fa fa-eye" />
                  </span>
                </div>
              </td>
              <td>
                <div className="Access">
                  <input type="checkbox" />
                  <span>
                    <i className="fa fa-lock" />
                  </span>
                </div>
              </td>
            </tr> */}

          </tbody>
        </table>
      </div>
    
      
        </div>
      {/* ))} */}
      


    </div>
  </div>
</div>

</>

  )
}

export default ViewSubAdmin