import { useNavigate, Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Accordion from "react-bootstrap/Accordion";
import { useState } from "react";


import { useDispatch, useSelector } from "react-redux";




const Sidebar = () => {

  const adminProfile = useSelector((state) => state.admin.value);

  const navigate = useNavigate();
  const location = useLocation();
  const [activeKey, setActiveKey] = useState("-1");


  return (
    <>



    {/* =====================side bar============ */}

    <div className="SidenavBar">
        <ul>
        
          <li
            className={
              location.pathname.includes("dashboard") ? "active" : null
            }
          >
            <Link to="/dashboard">
              <span>
                <i className="fa-solid fa-gauge" />
              </span>{" "}
              Dashboard
            </Link>
          </li>

          {/* Accordion for User */}
          <li className={activeKey == 0 ? "" : null}>
            <Accordion
              activeKey={activeKey}
              onSelect={(key) => {
                console.log("KEY=>", key);
                setActiveKey(key);
              }}
            >
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  <span className="">
                    <i className="fa-solid fa-user mx-1" />
                    User
                  </span>{" "}
                </Accordion.Header>
                <Accordion.Body>
                  <ul>

                    
                    <li
                      className={
                        location.pathname.includes("user-list")
                          ? "active"
                          : null
                      }
                    >
                      <Link to="/user-list" >User List</Link>
                    </li>
                    <li
                      className={
                        location.pathname.includes("verify-account")
                          ? "active"
                          : null
                      }
                    >
                      <Link to="/verify-account">Verify Account</Link>
                    </li>
                    <li
                      className={
                        location.pathname.includes("expert-request")
                          ? "active"
                          : null
                      }
                    >
                      <Link to="/expert-request">Expert Request</Link>
                    </li>
                    <li
                      className={
                        location.pathname.includes("upgradation-request")
                          ? "active"
                          : null
                      }
                    >
                      <Link to="/upgradation-request">Upgradation Request</Link>
                    </li>
                  </ul>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </li>

          {/* Community Link */}
          <li
            className={
              location.pathname.includes("community") ? "active" : null
            }
          >
            <Link to="/community">
              <span>
                <i className="fa-solid fa-users" />
              </span>{" "}
              Community
            </Link>
          </li>

          {/* Message Link */}
          <li
            className={location.pathname.includes("message") ? "active" : null}
          >
            <Link to="/message">
              <span>
                <i className="fa-regular fa-message" />
              </span>{" "}
              Message
            </Link>
          </li>

          {/* Reported Post Link */}
          <li
            className={
              location.pathname.includes("reported-post") ? "active" : null
            }
          >
            <Link to="/reported-post">
              <span>
                <i className="fa-solid fa-circle-exclamation" />
              </span>{" "}
              Reported Post
            </Link>
          </li>

          {/* Accordion for Configuration */}
          <li className={activeKey == 1 ? "" : null}>
            <Accordion
              activeKey={activeKey}
              onSelect={(key) => {
                console.log("KEY=>", key);
                setActiveKey(key);
              }}
            >
              <Accordion.Item eventKey="1">
                <Accordion.Header>
                  <span>
                    <i className="fa-solid fa-gear" />
                    &nbsp;Configuration
                  </span>{" "}
                </Accordion.Header>
                <Accordion.Body>
                  <ul>
                    <li
                      className={
                        location.pathname.includes("interest") ? "active" : null
                      }
                    >
                      <Link to="/interest">Interest</Link>
                    </li>
                    <li
                      className={
                        location.pathname.includes("report") ? "active" : null
                      }
                    >
                      <Link to="/report">Report</Link>
                    </li>
                    <li
                      className={
                        location.pathname.includes("education")
                          ? "active"
                          : null
                      }
                    >
                      <Link to="/education">Education</Link>
                    </li>
                    <li
                      className={
                        location.pathname.includes("business-category")
                          ? "active"
                          : null
                      }
                    >
                      <Link to="/business-category">Business Category</Link>
                    </li>
                    <li
                      className={
                        location.pathname.includes("area-of-expertise")
                          ? "active"
                          : null
                      }
                    >
                      <Link to="/area-of-expertise">Area of Expertise</Link>
                    </li>
                  </ul>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </li>

          {/* Sub Admin Link */}
          <li
            className={
              location.pathname.includes("sub-admin") ? "active" : null
            }
          >
            <Link to="/sub-admin">
              <span>
                <i className="fa-solid fa-user" />
              </span>{" "}
              Sub Admin
            </Link>
          </li>

          {/* Notification Link */}
          <li
            className={
              location.pathname.includes("notification") ? "active" : null
            }
          >
            <Link to="/notification">
              <span>
                <i className="fa-solid fa-bell" />
              </span>{" "}
              Notification
            </Link>
          </li>

          {/* CMS Link */}
          <li className={location.pathname.includes("cms") ? "active" : null}>
            <Link to="/cms">
              <span>
                <i className="fa-regular fa-folder" />
              </span>{" "}
              CMS
            </Link>
          </li>
        </ul>
      </div>
    </>
  );
};

export default Sidebar;
