
import axiosInstance from "../axios/axios";
class CmsService{

    async getCmsContent(type){
        try {
            const res=await axiosInstance.get(`/admin/cms/getContentByCmsType?cmsType=${type}`)
            console.log("Getting Data",res.data)
            if(res?.data.success){
                return {
                    success: true,
                    data: res.data
                }
            }else{
                return{
                    success: false
                }
            }
            
        } catch (error) {
            console.log(error)
            
        }

    }

    async editCmsContent(data){

        try {
            const res=await axiosInstance.post(`/admin/cms/createOrUpdate`,data)
            // console.log("Getting Data",res.data.success)
            if(res?.data.success){
                return {
                    success: true,
                    data: res.data
                }
            }else{
                return{
                    success: false
                }
            }
            
        } catch (error) {
            console.log(error)
            
        }

    }



}

export const cmsServices=new CmsService();