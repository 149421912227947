import avatar from "../../assets/images/Avatar-1.png";
import { useEffect, useState } from "react";
import { setLoader } from "../../store/LoaderSlice";
import { useDispatch, useSelector } from "react-redux";
import { userService } from "../../services/UserService";
import { getFormattedDate, showNotification } from "../../helper/helper";
import { toast } from "react-toastify";
import { Dropdown, Form, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import Accordion from "react-bootstrap/Accordion";
import { IoMdArrowRoundBack } from "react-icons/io";
import UnauthorzePage from "./UnauthorzePage";

const UserList = () => {
  const dispatch = useDispatch();
  const permissions = useSelector((state) => state.admin.value.permissions);
  const [hasAccess, setHasAccess] = useState(true); 

  const today = new Date().toISOString().split("T")[0];


  const [usersList, setusersList] = useState([]);
  const [currentPage, setcurrentPage] = useState("");
  const [nextPage, setnextPage] = useState("");
  const [prevPage, setprevPage] = useState("");
  const [totalPage, settotalPage] = useState("");
  const [totalRecords, settotalRecords] = useState("");
  const [recordsInPage, setrecordsInPage] = useState("");

  //states for pagination and filters

  const [limit, setlimit] = useState(10);
  const [page, setpage] = useState(1);
  const [sort, setsort] = useState("");
  const [order, setorder] = useState("desc");
  const [search, setsesrch] = useState("");
  const [searchTerm, setsearchTerm] = useState("");
  const [status, setstatus] = useState("");
  const [accountType, setaccountType] = useState("");
  const [followerDeepSearch, setfollowerDeepSearch] = useState("");
  const [postDeepSearch, setpostDeepSearch] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [from_date_data, setfrom_date_data] = useState("");
  const [to_date_data, setto_date_data] = useState("");

  //states for more than less than select

  const [filterType, setFilterType] = useState("");
  const [number, setNumber] = useState("");
  const [filterState, setFilterState] = useState("");

  const [postFilterType, setPostFilterType] = useState("");
  const [postNumber, setPostNumber] = useState("");
  const [postFilterState, setPostFilterState] = useState("");

  // ====================drop down=============
  const [selectedOption, setSelectedOption] = useState("");


      // Handle block/unblock for a specific user
  const handleStatusChange = async(userId, newStatus) => {

    const data = {
    status :newStatus,
    userId : userId
    }
    try{

      const res=await userService.blockUnblockUser(data)
      console.log("ye aa erha h",res)
      if(res){
        showNotification("success","User status changed successfully", "success");
        // ==============fetch data======================

        getUserList(
          limit,
          page,
          sort,
          order,
          searchTerm,
          status,
          accountType,
          followerDeepSearch,
          postDeepSearch,
          from_date_data,
          to_date_data
        );


        // =================fetch data end===============

      }
    }catch(err){
      console.log("Error in block/unblock user: ", err.message);

    }

    console.log("change status is",newStatus);
   
  };



  // useEffect(() => {
  //   getUserList(
  //     limit,
  //     page,
  //     sort,
  //     order,
  //     searchTerm,
  //     status,
  //     accountType,
  //     followerDeepSearch,
  //     postDeepSearch,
  //     from_date_data,
  //     to_date_data
  //   );

  // }, []);

  useEffect(() => {
    const access = permissions.some(
      (perm) =>
        perm.module === "User / User List" && perm.readAccess === true
    );

    if (!access) {
      setHasAccess(false); 
      return; 
    }

    getUserList(
      limit,
      page,
      sort,
      order,
      searchTerm,
      status,
      accountType,
      followerDeepSearch,
      postDeepSearch,
      from_date_data,
      to_date_data
    );
  }, [
   
    limit,
    page,
    sort,
    order,
    searchTerm,
    status,
    accountType,
    followerDeepSearch,
    postDeepSearch,
    from_date_data,
    to_date_data,
    
  ]);

  async function getUserList(
    limit,
    page,
    sort,
    order,
    searchTerm,
    status,
    accountType,
    followerDeepSearch,
    postDeepSearch,
    from_date_data,
    to_date_data
  ) {
    dispatch(setLoader(true));
    const res = await userService.getUserList(
      limit,
      page,
      sort,
      order,
      searchTerm,
      status,
      accountType,
      followerDeepSearch,
      postDeepSearch,
      from_date_data,
      to_date_data
    );
    if (res?.success == true) {
      console.log("==>", res);
      setusersList(res?.userList);
      setcurrentPage(res?.currentPage);
      setnextPage(res?.nextPage);
      setprevPage(res?.prevPage);
      settotalPage(res?.totalPages);
      settotalRecords(res?.totalRecords);
      setrecordsInPage(res?.recordsInPage);
    } else {
      toast("No users found");
    }
    dispatch(setLoader(false));
  }

  async function resetFilter() {
    setlimit(10);
    setpage(1);
    setsort("");
    setorder("desc");
    setsesrch("");
    setsearchTerm("");
    setstatus("");
    setaccountType("");
    setfollowerDeepSearch("");
    setpostDeepSearch("");
    setFilterType("");
    setPostFilterType("");
    setNumber("");
    setPostNumber("");
    setfrom_date_data("");
    setto_date_data("");
    setFromDate("");
    setToDate("");
  }

  // async function DeleteUser(id) {
  //   console.log(id);
  //   dispatch(setLoader(true));
  //   const res = await userService.deleteUser(id);
  //   if (res?.success) {
  //     showNotification("success", "User Deleted", "User deleted successfuly.");
  //     getUserList(limit, page, sort, order, "");
  //   } else {
  //     toast("Something went wrong.");
  //   }
  //   dispatch(setLoader(false));
  // }

  async function DeleteUser(id) {

    if(permissions.some((perm) => perm.module == "User / User List" && perm.fullAccess != true)){
      return showNotification("error","Access denied","you are not allowed to access")
    };

    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    });

    if (result.isConfirmed) {
      console.log(id);
      dispatch(setLoader(true));

      const res = await userService.deleteUser(id);

      if (res?.success) {
        Swal.fire("Deleted!", "User has been deleted.", "success");
        getUserList(
          limit,
          page,
          sort,
          order,
          "",
          status,
          accountType,
          followerDeepSearch,
          postDeepSearch,
          from_date_data,
          to_date_data
        );
      } else {
        Swal.fire("Error!", "Something went wrong.", "error");
      }

      dispatch(setLoader(false));
    }
  }

  const updateFilterState = (filterType, number) => {
    if (filterType && number !== "") {
      const formattedState = `${
        filterType === "more" ? "moreThan" : "lessThan"
      },${number}`;
      setFilterState(formattedState);
    } else {
      setFilterState("");
    }
  };

  const updatePostFilterState = (filterType, number) => {
    if (filterType && number !== "") {
      const formattedState = `${
        filterType === "more" ? "moreThan" : "lessThan"
      },${number}`;
      setPostFilterState(formattedState);
    } else {
      setPostFilterState("");
    }
  };

  // ====================== Render UnauthorzePage if no access===================
 if (!hasAccess) {
  return <UnauthorzePage />; 
}

  return (
    <>
      <div className="WrapperArea">
        {searchTerm != "" ? (
          <div
            className="px-4 mt-2 cursor-pointer-class "
            onClick={resetFilter}
          >
            <IoMdArrowRoundBack fontSize={25} />
          </div>
        ) : null}
        <div className="WrapperBox">
          <div className="FilterBody">
            <div className="FilterLeft">
              <div className="form-group d-flex gap-1">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search users here"
                  // onChange={(e) => {
                  //   if (search == "") {
                  //     setsearchTerm("");
                  //   } else {
                  //     setsesrch(e.target.value);
                  //   }
                  // }}

                  value={search}
                  onChange={(e) => {
                    const value = e.target.value;
                    setsesrch(value);

                    // If input is cleared (backspace to empty), trigger API call
                    if (value === "") {
                      setsearchTerm("");
                    }
                  }}
                />
                <span className="Icon">
                  <i className="fa-solid fa-magnifying-glass" />
                </span>
                <button
                  className="btn btn-outline-dark"
                  onClick={() => {
                    setsearchTerm(search);
                  }}
                >
                  Search
                </button>
              </div>
            </div>
            {/* <div className="FilterRight">
              <div className="form-group">
                <div className="dropdown">
                  <a
                    className="Filter"
                    href="javascript:void(0);"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i className="fa-solid fa-filter" />
                  </a>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuLink"
                  >
                    <ul>
                      <li className="FilterOprions">
                        <h5>Filter Options</h5>
                        <a href="javascript:void(0);">Clear All</a>
                      </li>
                      <li>
                        <a href="" className="dropdown-item">
                          Account Type{" "}
                          <span>
                            <i className="fa-solid fa-chevron-right" />
                          </span>
                        </a>
                      </li>
                      <li>
                        <a href="" className="dropdown-item">
                          Status{" "}
                          <span>
                            <i className="fa-solid fa-chevron-right" />
                          </span>
                        </a>
                      </li>
                      <li>
                        <a href="" className="dropdown-item">
                          Date Range <input type="date" />{" "}
                        </a>
                      </li>
                      <li>
                        <div className="ApplyFilterBox">
                          <aside>
                            <h6>Followers</h6>
                            <div className="form-group">
                              <select>
                                <option>select</option>
                              </select>
                              <input type="text" className="form-control" />
                            </div>
                          </aside>
                          <aside>
                            <h6>Post</h6>
                            <div className="form-group">
                              <select>
                                <option>select</option>
                              </select>
                              <input type="text" className="form-control" />
                            </div>
                          </aside>
                          <button className="Button">Apply Filters</button>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="form-group">
                <div className="dropdown">
                  <a
                    className="Filter"
                    href="javascript:void(0);"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i className="fa-solid fa-bars-staggered" />
                  </a>
                  <div
                    className="dropdown-menu Width190"
                    aria-labelledby="dropdownMenuLink"
                  >
                    <ul>
                      <li className="FilterOprions">
                        <h5>Sort Options</h5>
                      </li>
                      <li>
                        <a href="javascript:void(0);" className="dropdown-item">
                          Join Date
                        </a>
                      </li>
                      <li>
                        <a href="javascript:void(0);" className="dropdown-item">
                          Followers
                        </a>
                      </li>
                      <li>
                        <a
                          href="javascript:void(0);"
                          className="dropdown-item border-0"
                        >
                          Post
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div> */}
            <div className="FilterRight">
              <div className="form-group">
                {/* Filter Dropdown */}
                <Dropdown>
                  <Dropdown.Toggle
                    variant="light"
                    id="dropdown-filter"
                    className="Filter"
                  >
                    <i className="fa-solid fa-filter"></i>
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <ul className="py-2 px-3">
                      <li className="FilterOptions d-flex justify-content-between mt-2">
                        <h5>Filter Options</h5>
                        <span className="clearAllBtn" onClick={resetFilter}>
                          Clear All
                        </span>
                      </li>
                      <li>
                        <Accordion defaultActiveKey="-1">
                          <Accordion.Item eventKey="0">
                            <Accordion.Header>Account Type</Accordion.Header>
                            <Accordion.Body>
                              <ul className="px-3 py-2">
                                <li
                                  className={
                                    accountType == "Individual"
                                      ? "dropItemActive cursor-pointer-class"
                                      : "cursor-pointer-class"
                                  }
                                  onClick={() => setaccountType("Individual")}
                                >
                                  Individual
                                </li>
                                <li
                                  className={
                                    accountType == "Experience"
                                      ? "dropItemActive cursor-pointer-class"
                                      : "cursor-pointer-class"
                                  }
                                  onClick={() => setaccountType("Experience")}
                                >
                                  Business Account
                                </li>
                                <li
                                  className={
                                    accountType == "Expert"
                                      ? "dropItemActive cursor-pointer-class"
                                      : "cursor-pointer-class"
                                  }
                                  onClick={() => setaccountType("Expert")}
                                >
                                  Business Expert
                                </li>
                              </ul>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey="1">
                            <Accordion.Header>Status</Accordion.Header>
                            <Accordion.Body  >
                              <ul className="px-3 py-2">
                                <li
                                  className={
                                    status == "active"
                                      ? "dropItemActive cursor-pointer-class"
                                      : "cursor-pointer-class"
                                  }
                                  onClick={() => setstatus("active")}
                                >
                                  Active
                                </li>
                                <li
                                  className={
                                    status == "blocked"
                                      ? "dropItemActive cursor-pointer-class"
                                      : "cursor-pointer-class"
                                  }
                                  onClick={() => setstatus("blocked")}
                                >
                                  Blocked
                                </li>
                              </ul>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                        {/* <Dropdown.Item href="#">
                          Account Type{" "}
                          <span>
                            <i className="fa-solid fa-chevron-right"></i>
                          </span>
                        </Dropdown.Item> */}
                      </li>
                      {/* <li>
                        <Dropdown.Item href="#">
                          Status{" "}
                          <span>
                            <i className="fa-solid fa-chevron-right"></i>
                          </span>
                        </Dropdown.Item>
                      </li> */}
                      <li className="filterFormDate">
                        <span>From Date</span>
                        <Form.Control
                          max={today}
                          type="date"
                          className="form-control"
                          value={fromDate}
                          onChange={(e) => setFromDate(e.target.value)}
                        />
                      </li>
                      <li className="filterFormDate">
                        <span>To Date</span>
                        <Form.Control
                          max={today}
                          type="date"
                          className="form-control"
                          value={toDate}
                          onChange={(e) => setToDate(e.target.value)}
                        />
                      </li>
                      <li>
                        <div className="ApplyFilterBox">
                          <aside>
                            <h6>Followers</h6>
                            <Form.Group className="d-flex gap-1 flex-column">
                              <Form.Control
                                as="select"
                                className="form-control "
                                value={filterType}
                                onChange={(e) => {
                                  setFilterType(e.target.value);
                                  updateFilterState(e.target.value, number);
                                }}
                              >
                                <option value="">Select</option>
                                <option value="more">More than</option>
                                <option value="less">Less than</option>
                              </Form.Control>
                              <Form.Control
                                type="text"
                                className="form-control"
                                placeholder="Enter number"
                                value={number}
                                onChange={(e) => {
                                  setNumber(e.target.value);
                                  updateFilterState(filterType, e.target.value);
                                }}
                              />
                            </Form.Group>
                            {/* <div>
                              <strong>Filter State: </strong> {filterState}
                            </div> */}
                          </aside>
                          {/* <aside>
                            <h6>Followers</h6>
                            <Form.Group>
                              <Form.Control
                                as="select"
                                className="form-control"
                              >
                                <option>Select</option>
                              </Form.Control>
                              <Form.Control
                                type="text"
                                className="form-control"
                              />
                            </Form.Group>
                          </aside> */}
                          {/* <aside>
                            <h6>Post</h6>
                            <Form.Group>
                              <Form.Control
                                as="select"
                                className="form-control"
                              >
                                <option>Select</option>
                              </Form.Control>
                              <Form.Control
                                type="text"
                                className="form-control"
                              />
                            </Form.Group>
                          </aside> */}
                          <aside>
                            <h6>Post</h6>
                            <Form.Group className="d-flex gap-1 flex-column">
                              <Form.Control
                                as="select"
                                className="form-control"
                                value={postFilterType}
                                onChange={(e) => {
                                  setPostFilterType(e.target.value);
                                  updatePostFilterState(
                                    e.target.value,
                                    postNumber
                                  );
                                }}
                              >
                                <option value="">Select</option>
                                <option value="more">More than</option>
                                <option value="less">Less than</option>
                              </Form.Control>
                              <Form.Control
                                type="text"
                                className="form-control"
                                placeholder="Enter number"
                                value={postNumber}
                                onChange={(e) => {
                                  setPostNumber(e.target.value);
                                  updatePostFilterState(
                                    postFilterType,
                                    e.target.value
                                  );
                                }}
                              />
                            </Form.Group>
                            {/* <div>
                              <strong>Post Filter State: </strong>{" "}
                              {postFilterState}
                            </div> */}
                          </aside>
                          <Button
                            className="Button"
                            onClick={() => {
                              if (filterState != "") {
                                setfollowerDeepSearch(filterState);
                              }

                              if (postFilterState != "") {
                                setpostDeepSearch(postFilterState);
                              }

                              if (fromDate != "" && toDate != "") {
                                setfrom_date_data(fromDate);
                                setto_date_data(toDate);
                              }
                              // else {
                              //   toast("Please provide some input.");
                              // }
                              if (filterType != "") {
                                if (number == "") {
                                  toast("Please fill number of followers");
                                }
                              }

                              if (postFilterType != "") {
                                if (postNumber == "") {
                                  toast("Please fill number of posts");
                                }
                              }

                              if (
                                fromDate == "" &&
                                toDate == "" &&
                                postFilterType == "" &&
                                filterType == ""
                              ) {
                                toast("Please provide some input");
                              }
                            }}
                          >
                            Apply Filters
                          </Button>
                        </div>
                      </li>
                    </ul>
                  </Dropdown.Menu>
                </Dropdown>
              </div>

              <div className="form-group">
                {/* Sort Dropdown */}
                <Dropdown>
                  <Dropdown.Toggle
                    variant="light"
                    id="dropdown-sort"
                    className="Filter"
                  >
                    <i className="fa-solid fa-bars-staggered"></i>
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="Width190 px-2 py-2">
                    <ul>
                      <li className="FilterOptions">
                        <h5>Sort Options</h5>
                      </li>
                      <li>
                        <Dropdown.Item href="#">Join Date</Dropdown.Item>
                      </li>
                      <li>
                        <Dropdown.Item
                          href="#"
                          onClick={() => setsort("followers")}
                        >
                          Followers
                        </Dropdown.Item>
                      </li>
                      <li>
                        <Dropdown.Item
                          href="#"
                          onClick={() => setsort("posts")}
                        >
                          Post
                        </Dropdown.Item>
                      </li>
                    </ul>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <button className="Button" onClick={resetFilter}>
                Clear
              </button>
            </div>
          </div>
          <div className="TableHeader">
            <h5>
              Total Users: {recordsInPage}/{totalRecords}
            </h5>
          </div>
          <div className="TableList">
            <table style={{ width: "150%" }}>
              <thead>
                <tr>
                  <th>Sr No.</th>
                  <th>Profile</th>
                  <th>Full Name &amp; Email</th>
                  <th>Username</th>
                  <th>Mobile Number</th>
                  <th>Join Date</th>
                  <th>Account Type</th>
                  <th>Status</th>
                  <th>No. of Followers</th>
                  <th>No. of Post</th>
                  <th>Unseen Report Count</th>
                  <th>Reported Count</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {usersList.length ? (
                  usersList.map((item, index) => (
                    <tr>
                      <td className="text-capitalize">{index + 1}</td>
                      <td>
                        <figure>
                          <img
                            src={
                              !item.profileImage || item.profileImage === ""
                                ? avatar
                                : item.profileImage
                            }
                          />
                        </figure>
                      </td>
                      <td>
                        {item.name} {item.name ? "/" : null}
                        <br /> {item.email}
                      </td>
                      <td>{item.username} </td>
                      <td>{item.phoneNumber} </td>
                      <td>
                        {getFormattedDate(item.createdAt, "Do MMMM YYYY")}
                      </td>
                      <td className="text-capitalize">{item.accountType}</td>
                      <td className="text-capitalize">
                        {
                          item.status=="active"?
                          <span className="Approved">Active</span>
                          :
                          <span className="Rejected">Blocked</span>
                        }
                        
                        
                      </td>
                      <td>{item.numberOfFollowers}</td>
                      <td>{item.numberOfPosts}</td>
                      <td>{item.unseenReportsCount}</td>

                      <td>{item.numberOfReports}</td>
                      <td>
                        <div className="Actions">
                         
                          <Link
                            className="Blue"
                            to={`/user-details/list/${item?._id}`}
                          >
                            <i className="fa fa-eye" />
                          </Link>
                          <a className="Green" href="javascript:void(0)">
                            <i className="fa-solid fa-message" />
                          </a>
                          <a
                            className="Red"
                            onClick={() => DeleteUser(item._id)}
                          >
                            <i className="fa fa-trash" />
                          </a>
                   {/* ===================bloc unblock section================== */}
                      <Dropdown className="mx-2"   onClick={(e) => {
                             e.preventDefault();
                             if (permissions.some(
                               (perm) =>
                                 perm.module === "User / User List" && perm.fullAccess ==false
                             )) {
                                      
                               e.preventDefault(); 
                               showNotification("error","Access denied","you are not allowed to access")
                             }
                           }}>

                      <Dropdown.Toggle variant={item.status=="active"?"primary":"danger"} id={`dropdown-${item._id}`}  
                     >
                        {item.status === "active" ? "Active" : "Blocked"}
                    
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        {
                          item.status=="active"?
                          <Dropdown.Item
                            onClick={() => handleStatusChange(item._id, "blocked")}
                          >
                            Block
                          </Dropdown.Item>
                          :

                          <Dropdown.Item
                            onClick={() => handleStatusChange(item._id, "active")}
                          >
                            Active
                          </Dropdown.Item>
                        }
                        </Dropdown.Menu>
                     </Dropdown>



                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>No users to display</tr>
                )}
              </tbody>
            </table>
          </div>
          <div className="Pagination">
            <div className="Left">
              <p>Show Records:</p>
              <select
                value={limit} // Bind state to the select value
                onInput={(event) => {
                  setlimit(event.target.value);
                  console.log("Selected Value:", event.target.value);
                }}
                className="form-control"
              >
                <option value={10}>10</option>
                <option value={20}>20</option>
                <option value={30}>30</option>
                <option value={40}>40</option>
              </select>
            </div>
            <ul>
              {page == 1 ? null : (
                <li>
                  <a
                    href="javascript:void(0);"
                    onClick={() => {
                      if (page == 1) {
                        toast("Cannot go back as you are on 1st page");
                      } else {
                        console.log("HIT");
                        setpage((prevvalue) => prevvalue - 1);
                      }
                    }}
                  >
                    <i className="fa-solid fa-chevron-left" />
                  </a>
                </li>
              )}

              {prevPage != "" ? (
                <li onClick={() => setpage(prevPage)}>
                  <a href="javascript:void(0);">
                    {prevPage != "" ? prevPage : null}
                  </a>
                </li>
              ) : null}

              <li>
                <a href="javascript:void(0);" className="active">
                  {currentPage}
                </a>
              </li>

              {nextPage != "" ? (
                <li onClick={() => setpage(nextPage)}>
                  <a href="javascript:void(0);">
                    {nextPage != "" ? nextPage : null}
                  </a>
                </li>
              ) : null}

              {currentPage == totalPage ? null : (
                <li>
                  <a
                    href="javascript:void(0);"
                    onClick={() => {
                      if (currentPage < totalPage) {
                        setpage((prevvalue) => prevvalue + 1);
                      } else {
                        toast("You are on last page");
                      }
                    }}
                  >
                    <i className="fa-solid fa-chevron-right" />
                  </a>
                </li>
              )}
            </ul>
          </div>
        </div>
      </div>
      
    </>
  );
};

export default UserList;
