import axios from "axios";
import { toast } from "react-toastify";
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL,
  // baseURL: process.env.REACT_APP_BASE_URL,
  timeout: 20000,
});

axiosInstance.interceptors.request.use( 
  (config) => {
    const token = localStorage.getItem("askbiz_admin");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
      config.headers["x-access-token"] = token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    // Any status code that lies within the range of 2xx cause this function to trigger
    return response;
  },
  (error) => {
    // Any status codes that fall outside the range of 2xx cause this function to trigger
    const { response } = error;

    if (response && response.status === 401) {
      // Handle token expiration or forbidden errors
      toast.error("Session expired.");
      // localStorage.removeItem("token");
      localStorage.clear();
      // Optionally redirect to login page
      window.location.href = "/";
    } else if (response && response.status >= 500) {
      // toast.error("An error occurred on the server. Please try again later.");
    } else {
      // Handle other types of errors
      // toast.error(
      //   response?.data?.msg || "An error occurred. Please try again."
      // );
    }

    return Promise.reject(error);
  }
);

// Response interceptor for handling response errors
// axiosInstance.interceptors.response.use(
//   (response) => {
//     return response;
//   },
//   (error) => {
//     if (error.response) {
//       console.error("Response error:", error.response.data);
//       console.error("Status code:", error.response.status);

//       const status = error.response.status; // Corrected: accessing status from error.response
//       if (status === 200) {
//         // Handle status 200
//       } else if (status === 201) {
//         toast("Data Saved");
//       } else if (status === 204) {
//         toast("No content available");
//       } else if (status === 400) {
//         console.log("Bad request. Please check your input.");
//       } else if (status === 401) {
//         toast("Unauthorized. Please login to access this resource.");
//       } else if (status === 403) {
//         toast("Forbidden. You don't have permission to access this resource.");
//       } else if (status === 404) {
//         toast("Resource not found. Please try again later.");
//       } else if (status === 500) {
//         console.log("Internal server error. Please try again later.");
//       } else {
//         toast("Unexpected error occurred.");
//       }
//     } else if (error.request) {
//       console.error("Request error:", error.request);
//     } else {
//       console.error("Error:", error.message);
//     }
//     return Promise.reject(error);
//   }
// );

export default axiosInstance;
