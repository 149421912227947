
import axiosInstance from "../axios/axios";

class UserService {
  async getUserList(
    limit,
    page,
    sort,
    order,
    searchTerm,
    status,
    accountType,
    followerDeepSearch,
    postDeepSearch,
    from_date_data,
    to_date_data
  ) {
    try {
      const res = await axiosInstance.get(
        `/admin/user/getUsers?limit=${limit}&pageNo=${page}&sortBy=${sort}&order=${order}&by=${searchTerm}&status=${status}&accountType=${accountType}&followersDeepSearch=${followerDeepSearch}&postsDeepSearch=${postDeepSearch}&fromDate=${from_date_data}&toDate=${to_date_data}`
      );

      console.log("users list=>", res);
      if (res.data.success) {
        return {
          next: res?.data?.data.nextLink,
          prev: res?.data?.data.prevLink,
          totalPages: res?.data?.data.totalPages,
          currentPage: res.data.data.currentPage,
          nextPage: res.data.data.nextPage ? res.data.data.nextPage : "",
          prevPage: res.data.data.prevPage ? res.data.data.prevPage : "",
          userList: res.data.data.users,
          success: true,
          totalRecords: res?.data?.data.totalUsers,
          recordsInPage: res?.data?.data.totalUserInAPage,
        };
      }
    } catch (err) {
      console.log(err.message);
    }
  }

  async getUserById(id) {
    try {
      const res = await axiosInstance.get(
        `admin/user/getUserById?userId=${id}`
      );

      console.log("user=>", res);
      if (res.data.success) {
        return {
          success: true,
          data: res?.data?.data,
        };
      } else {
        return { success: false };
      }
    } catch (err) {
      console.log(err.message);
    }
  }

  async deleteUser(id) {
    try {
      const res = await axiosInstance.delete(
        `/admin/user/deleteUser?userId=${id}`
      );

      console.log("del user=>", res);
      if (res.data.success) {
        return {
          success: true,
        };
      } else {
        return { success: false };
      }
    } catch (err) {
      console.log(err.message);
    }
  }

  async getVerifyAndExpertList(
    accType,
    status = "",
    filter,
    search,
    selectedValue,
    currentPage
  ) {
    try {
      const res = await axiosInstance.get(
        `/admin/user/getAllVerifyReq?accountType=${accType}&status=${filter}&by=${search}&limit=${selectedValue}&pageNo=${currentPage}`
      );

      console.log("list=>", res);
      if (res?.data?.success) {
        return {
          success: true,
          data: res?.data,
        };
      } else {
        return { success: false };
      }
    } catch (err) {
      console.log(err.message);
    }
  }

  async getExtraDataVerifyList() {
    try {
      const res = await axiosInstance.get(`/admin/user/getVerifyDetails`);

      // console.log("verify extra data=>", res);
      if (res.data.success) {
        return {
          success: true,
          data: res?.data?.data,
        };
      } else {
        return { success: false };
      }
    } catch (err) {
      console.log(err.message);
    }
  }

  async getDetailedData(id) {
    try {
      const res = await axiosInstance.get(
        `/admin/user/getVerifyReqById?reqId=${id}`
      );

      console.log("detailed data=>", res);
      if (res.data.success) {
        return {
          success: true,
          data: res?.data?.data,
        };
      } else {
        return { success: false };
      }
    } catch (err) {
      console.log(err.message);
    }
  }

  async approveRequest(id) {
    try {
      const res = await axiosInstance.get(
        `/admin/user/approveAccountReq?reqId=${id}`
      );

      
      if (res.data.success) {
        return true;
      } else {
        return false;
      }
    } catch (err) {
      console.log(err.message);
    }
  }

  async rejectRequest(id) {
    try {
      const res = await axiosInstance.get(
        `/admin/user/reqAccountReq?reqId=${id}`
      );

      console.log("rejected requested",res)
      
      if (res.data.success) {
        return true;
      } else {
        return false;
      }
    } catch (err) {
      console.log(err.message);
    }
  }


  async blockUnblockUser(data){

  
    try {
      const res= await axiosInstance.put("/admin/user/updateUserStatus",data)
    
      if(res.data.success){
        return true;
      }else{
        return false;
      }

    } catch (error) {
      console.log(error.message);
      
    }
  }


  }

export const userService = new UserService();
