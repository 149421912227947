import thumbnail from "../../assets/images/thumbnail.svg";
import graph from "../../assets/images/graph.svg";
import { configurationService } from "../../services/ConfigurationService";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setLoader } from "../../store/LoaderSlice";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { showNotification } from "../../helper/helper";
import Swal from "sweetalert2";
import UnauthorzePage from "./UnauthorzePage";

const Interest = () => {
  const dispatch = useDispatch();
  const [hasAccess, setHasAccess] = useState(true); // Default: true
    const permissions = useSelector((state) => state.admin.value.permissions);
  
  const [reLoadPage,setReLoadPage] = useState(true)

  const [interests, setinterests] = useState([]);
  
  const [interestsBackup, setinterestsBackup] = useState([]);

  //states for search limit and pagination.

  const [search, setsearch] = useState("");
  const [searchedString, setsearchedString] = useState("");
  const [limit, setlimit] = useState(10);



    // ==============pagination code===================

    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    // const itemsPerPage = 10; 
  
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = interests?.slice(indexOfFirstItem, indexOfLastItem);
  
  const totalPages = Math.ceil(interests?.length / itemsPerPage);
  
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  
   // =================pagination code ends =================
  //.....................................

  useEffect(() => {
    const access = permissions.some(
      (perm) =>
        perm.module === "Configuration / Interest" && (perm.fullAccess === true ||perm.readAccess === true)
    );

    if (!access) {
      setHasAccess(false); 
      return; 
    }
  
    getInterests();

  }, [reLoadPage]);

  console.log(interests)
  useEffect(() => {
    if (search !== "") {
      const lowerCaseSearch = search.toLowerCase();
      const filtered = interests.filter(
        (record) =>
          record.categoryName.toLowerCase().includes(lowerCaseSearch) ||
          record.status.toLowerCase().includes(lowerCaseSearch) ||
          record.interestsName.some((interest) =>
            interest.toLowerCase().includes(lowerCaseSearch)
          )
      );
      if (filtered.length) {
        setinterests(filtered);
      } else {
        toast("No match found.");
      }
    }
  }, [search]);

  useEffect(() => {
    const limitedData = interestsBackup.slice(0, limit);
    if (limitedData.length) {
    }

    console.log("Limit =", limitedData);
  }, [limit]);

  async function getInterests() {
    dispatch(setLoader(true));

    const res = await configurationService.getInterest("interests");

    if (res?.success) {
      setinterests(res.data);
      setinterestsBackup(res.data);
    }

    dispatch(setLoader(false));
  }

  async function deleteConfigHandler(id) {

    if(permissions.some((perm) => perm.module == "Configuration / Interest" && perm.fullAccess != true)){
      return showNotification("error","Access denied","you are not allowed to access")
   };


    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "OK",
      cancelButtonText: "Cancel",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
     console.log("deleted id",id)
    const data=  {
      configType:"interests",
      reportId: id,
  
    }      

    const res = await configurationService.deleteConfigData(data);
   
    if (res?.success) {
      console.log("delete",res)
      showNotification("success","deleted","deleted successfully")
      setReLoadPage(prevStatus =>!prevStatus)
      setinterestsBackup(res.data);
    
    }
  }
    dispatch(setLoader(false));
  });
  }

     // ================================Switch Handler Code===============================
     const statusHandler=async(e,item)=>{
      e.preventDefault();
      if(permissions.some((perm) => perm.module == "Configuration / Interest" && perm.fullAccess != true)){
        return showNotification("error","Access denied","you are not allowed to access")
     };

      const newStatus = item.status === "active" ? "inactive" : "active";
      const data=  {
        configType:"interests",
        reportId: item._id,
        status:newStatus
      }      
      const res = await configurationService.updateConfigData(data);
   
      if (res?.success) {
  
   // ==============getintrest data-==============================
        dispatch(setLoader(true));
        const res = await configurationService.getInterest("interests");
        if (res.success) {
          // console.log("businessCategory", res.data);
          setinterests(res.data);
          setinterestsBackup(res.data);
        }
    
      dispatch(setLoader(false));
    
      // ==============genintrest data end-==============================
  
    
        setinterestsBackup(res.data);
      }
      dispatch(setLoader(false));
    }
    

 
    // ====================== Render UnauthorzePage if no access===================
    if (!hasAccess) {
      return <UnauthorzePage />; 
    }



  return (
    <>
      <div className="WrapperArea">
        <div className="WrapperBox">
          {/* <div class="my-2"><h6 data-toggle="modal" data-target="#MoviesEntertainmentModal">MoviesEntertainmentModal</h6></div> */}
          <div className="FilterBody">
            <div className="FilterLeft d-flex gap-1">
              <div className="form-group">
                <input
                  type="text"
                  value={searchedString}
                  className="form-control"
                  placeholder="Search For Category"
                  onChange={(e) => setsearchedString(e.target.value)}
                />
                <span className="Icon">
                  <i className="fa-solid fa-magnifying-glass" />
                </span>
              </div>
              <div className="d-flex gap-1">
                {searchedString.length ? (
                  <button
                    className="btn btn-outline-dark mx-2"
                    onClick={() => {
                      searchedString !== ""
                        ? setsearch(searchedString)
                        : toast("Please provide an input.");
                    }}
                  >
                    Search
                  </button>
                ) : null}
                {search.length ? (
                  <button
                    className="Button"
                    onClick={() => {
                      setinterests(interestsBackup);
                      setsearch("");
                      setsearchedString("");
                    }}
                  >
                    Clear
                  </button>
                ) : null}
              </div>
            </div>
            <div className="FilterRight">
              
              <Link 
              className="Button"
               to="/add-category"
               onClick={(e) => {
              
                if (permissions.some((perm) => perm.module == "Configuration / Interest" && perm.fullAccess != true)) {
                         
                  e.preventDefault(); 
                  showNotification("error","Access denied","you are not allowed to access")
                }
              }}
                >
                Add New &nbsp; <i className="fa-solid fa-circle-plus" />
              </Link>
            </div>
          </div>
          <div className="TableHeader">
            <h5>Total Report Name: {currentItems.length}/{interests.length}</h5>
          </div>
          <div className="TableList">
            <table>
              <thead>
                <tr>
                  <th>Sr No.</th>
                  <th>Thumbnail</th>
                  <th>Category</th>
                  <th>Interest Count</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {currentItems?.length
                  ? currentItems.map((item, index) => (
                      <tr>
                        <td>{index + 1}</td>
                        <td>
                          <span className="text-left pl-0">
                            <img
                              className="interest-thumbnail"
                              src={
                                item?.thumbnail ? item?.thumbnail : thumbnail
                              }
                            />
                          </span>
                        </td>
                        <td>
                          <a
                            href="javascript:void(0);"
                            className="AnchorLink" 
                            data-toggle="modal"
                            data-target="#MoviesEntertainmentModal"
                          >
                            {item?.categoryName}
                          </a>
                        </td>
                        <td>{item?.interestsName?.length}</td>
                        <td>
                          <div className="Actions justify-content-center">
                            <label className="switch">
                            <input type="checkbox" checked={item.status== "active"} onClick={(e)=>statusHandler(e,item)}/>
                              <span className="slider" />
                            </label>
                          </div>
                        </td>
                        <td>
                          <div className="Actions">
                          {permissions.map((prmis) => (
                    prmis.module === "Configuration / Interest" && (
                      // <> 
                      //   <Link className="Blue" 
                        
                      // data-tooltip-id="my-tooltip-1"
                      // to="/report/view"
                      //  state={
                      //   { item: item
                      //    }
                      //   }
                      //   onClick={(e) => {
                      //     console.log("prmis.readAccess",prmis.readAccess)
                      //     if (!prmis.readAccess) {
                                   
                      //       e.preventDefault(); 
                      //       showNotification("error","Access denied","you are not allowed to access")
                      //     }
                      //   }}
                      //   >
                      //   <i className="fa fa-eye"  />
                      // </Link>

                      // <Link
                      //  onClick={(e)=>handleShow(e,item._id,item.status,item ,prmis)}
                      //   className="Green"
                    
                      //   data-tooltip-id="my-tooltip-2"
                      // >
                      //   <i className="fa fa-pencil" />
                      // </Link>
                      // <Link
                      //   className="Red"
                      //    data-tooltip-id="my-tooltip-3"
                      // >
                      //   <i className="fa fa-trash"  onClick={(e)=>deleteConfigHandler(e,item._id,prmis)}/>
                      // </Link>
                      // </>
    


                <>
                 <a className="Blue">
                              <i className="fa fa-eye" />
                            </a>
                            <Link 
                            className="Green" 
                            to={{
                              pathname: "/interest-edit",
                            
                            }
                          }
                            state={{ item: item }}
                            onClick={(e) => {
                                  if (!prmis.fullAccess) {
                                           
                                    e.preventDefault(); 
                                    showNotification("error","Access denied","you are not allowed to access")
                                  }
                            }}

                          >
                            <i className="fa fa-pencil"  />
                            </Link>
                            <a
                              className="Red"
                              data-toggle="modal"
                              data-target="#DeleteBusinessCategory"
                              onClick={()=>deleteConfigHandler(item._id)}
                            >
                              <i className="fa fa-trash" />
                            </a>
                </>
                   
                 
              )
            ))}



                            {/* <a className="Blue">
                              <i className="fa fa-eye" />
                            </a>
                            <Link 
                            className="Green" 
                            to={{
                              pathname: "/interest-edit",
                            
                            }
                          }
                            state={{ item: item }}

                          >
                            <i className="fa fa-pencil"  />
                            </Link>
                            <a
                              className="Red"
                              data-toggle="modal"
                              data-target="#DeleteBusinessCategory"
                              onClick={()=>deleteConfigHandler(item._id)}
                            >
                              <i className="fa fa-trash" />
                            </a> */}


                          </div>
                        </td>
                      </tr>
                    ))
                  : <div>No data found</div>}
              </tbody>
            </table>
          </div>
          <div className="Pagination">
            <div className="Left">
              <p>Show Records:</p>
              <select
                value={limit} // Bind state to the select value
                onInput={(event) => {
                  setlimit(event.target.value);
                  console.log("Selected Value:", event.target.value);
                }}
                className="form-control"
              >
                <option value={10}>10</option>
                <option value={20}>20</option>
                <option value={30}>30</option>
                <option value={40}>40</option>
              </select>
            </div>
            <ul>
            <li>
                <a
                  href="javascript:void(0);"
                  onClick={() => {
                    if (currentPage > 1) {
                      handlePageChange(currentPage - 1);
                    }
                  }}
                  className={currentPage === 1 ? "disabled" : ""}
                >
                  <i className="fa-solid fa-chevron-left" />
                </a>
          </li>

          {[...Array(totalPages)].map((_, i) => {
              const pageNumber = (i + 1).toString().padStart(2, "0"); // Pad single-digit numbers
              return (
                <li key={i} onClick={() => handlePageChange(i + 1)}>
                  <a
                    href="javascript:void(0);"
                    className={currentPage === i + 1 ? "active" : ""}
                  >
                    {pageNumber}
                  </a>
                </li>
              );
            })}

              {/* <li>
                <a href="javascript:void(0);" className="active">
                  01
                </a>
              </li> */}
            
            
              <li>
                <a href="javascript:void(0);">
                  <i className="fa-solid fa-chevron-right" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Interest;
