import { Link } from "react-router-dom";
import forgotPass from "../../assets/images/forgot-password.svg";
import { useState } from "react";
import { toast } from "react-toastify";
import { isValidEmail, showNotification } from "../../helper/helper";
import { loginService } from "../../services/LoginService";
import { useNavigate } from "react-router-dom";
import { setLoader } from "../../store/LoaderSlice";
import { useDispatch } from "react-redux";
import { setOtp } from "../../store/OtpSlice";

const ForgotPassword = () => {
  const [email, setemail] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  async function handleOtp(e) {
    e.preventDefault();

    if (isValidEmail(email)) {
      dispatch(setLoader(true));
      const res = await loginService.sendOtp(email);
      if (res?.data?.success) {
        dispatch(
          setOtp({
            otp: res?.data?.data?.otp,
            timestamp: res?.data?.data?.otpTimeStamp,
            email: email,
          })
        );
        showNotification("success", "Otp is", res?.data?.data?.otp);
        navigate("/send-otp");
      } else {
        if (res.data.message == "admin doesn't exist.") {
          toast("Invalid Email");
        }
      }
    } else {
      toast("Please enter a valid email.");
    }
    dispatch(setLoader(false));
  }
  return (
    <>
      <div className="LoginArea">
        <div className="LoginRight">
          <img src={forgotPass} />
        </div>
        <div className="LoginLeft">
          {/* <h2>Huk N <span>Buk</span></h2> */}
          <h3>
            <span>Forgot Password</span>
          </h3>
          <h4>Please enter the registered email address</h4>
          <form onSubmit={handleOtp}>
            <div className="form-group">
              <label>Email Address</label>
              <input
                type="email"
                placeholder="Enter Email Address"
                className="form-control"
                required
                onChange={(e) => setemail(e.target.value)}
              />
              <span className="icon">
                <i className="fa-solid fa-envelope" />
              </span>
            </div>
            {/* <div class="Checkboxs">
              <label class="CheckBox">Remember Me 
                  <input type="checkbox">
                  <span class="checkmark"></span>
              </label>
          </div> */}
            <div className="d-flex justify-content-between gap-1">
              <Link className="Login Cancel " to="/">
                Cancel
              </Link>

              <button type="submit" className="Login ">
                Submit
              </button>
            </div>
            {/* <button>Log In <i class="fa fa-sign-in"></i></button> */}
          </form>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
