// import { Link } from "react-router-dom";
// import forgot from "../../assets/images/forgot-password.svg";
// const LoginOtp = () => {
//   return (
//     <>
//       <div className="LoginArea">
//         <div className="LoginRight">
//           <img src={forgot} />
//         </div>
//         <div className="LoginLeft">
//           {/* <h2>Huk N <span>Buk</span></h2> */}
//           <h3>
//             <span>OTP Verification</span>
//           </h3>
//           <h4>
//             Please enter 6 digit OTP Verification code recieved <br /> on your
//             email address.
//           </h4>
//           <form>
//             <div className="form-group">
//               <div className="OTPBox">
//                 <input type="text" className="form-control" maxLength={1} />
//                 <input type="text" className="form-control" maxLength={1} />
//                 <input type="text" className="form-control" maxLength={1} />
//                 <input type="text" className="form-control" maxLength={1} />
//                 <input type="text" className="form-control" maxLength={1} />
//                 <input type="text" className="form-control" maxLength={1} />
//               </div>
//               <p className="text-right">
//                 <a>Resend</a>
//               </p>
//             </div>
//             {/* <button class="Login">Submit</button>  */}
//             <div className="d-flex justify-content-between">
//               <a className="Login Cancel mr-3" href="login-forgot.html">
//                 Cancel
//               </a>
//               <Link className="Login ml-3" to="/reset-password">
//                 Verify otp
//               </Link>
//             </div>
//           </form>
//         </div>
//       </div>
//     </>
//   );
// };

// export default LoginOtp;

import { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { setLoader } from "../../store/LoaderSlice";
import PinInput from "react-pin-input";
import forgot from "../../assets/images/forgot-password.svg";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { isTimestampValid, showNotification } from "../../helper/helper";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { loginService } from "../../services/LoginService";
import { setOtp } from "../../store/OtpSlice";
import Timer from "../helperComponnets/Timer";

const LoginOtp = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const originalOtp = useSelector((state) => state.otp.value.otp);
  const originalOtpTimestamp = useSelector(
    (state) => state.otp.value.timestamp
  );
  const email = useSelector((state) => state.otp.value.email);
  const [otp, setOTP] = useState("");
  const pinRef = useRef(null);
  const [showResendBtn, setshowResendBtn] = useState(false);

  useEffect(() => {
    if (showResendBtn == false) {
      setTimeout(() => {
        setshowResendBtn(true);
      }, 60000);
    }
  }, [showResendBtn]);

  const handleVerify = () => {
    if (otp.length === 6) {
      console.log("OTP Entered:", otp);
      if (isTimestampValid(originalOtpTimestamp)) {
        if (otp == originalOtp) {
          toast("Otp verified");
          navigate("/reset-password");
        } else {
          toast("Otp is incorrect");
        }
      } else {
        toast("Otp expired. Please resend again.");
      }
    } else {
      console.log("Please fill all the OTP inputs.");
    }
  };

  const handleResend = async (e) => {
    e.preventDefault();
    dispatch(setLoader(true));
    const res = await loginService.sendOtp(email);
    console.log("res from otp page", res);
    if (res?.data?.success) {
      dispatch(
        setOtp({
          otp: res?.data?.data?.otp,
          timestamp: res?.data?.data?.otpTimeStamp,
          email: email,
        })
      );

      showNotification("success", "Otp is", res?.data?.data?.otp);

      toast("Otp sent successfuly");
      setshowResendBtn(false);
    }
    dispatch(setLoader(false));
  };

  return (
    <>
      <div className="LoginArea">
        <div className="LoginRight">
          <img src={forgot} alt="Forgot Password" />
        </div>
        <div className="LoginLeft">
          <h3>
            <span>OTP Verification</span>
          </h3>
          <h4>
            Please enter the 6-digit OTP code received on your email address.
          </h4>
          <form
            onSubmit={(e) => {
              e.preventDefault(); // Prevent form submission
              handleVerify(); // Trigger OTP verification on submit
            }}
          >
            <div className="form-group">
              <div className="OTPBox">
                {/* Pin Input */}
                <PinInput
                  ref={pinRef}
                  length={6} // Number of digits
                  initialValue=""
                  onChange={(value) => setOTP(value)} // Store OTP in state
                  type="numeric"
                  inputMode="number"
                  style={{ padding: "10px" }}
                  inputStyle={{ borderColor: "gray" }} // Input box styling
                  inputFocusStyle={{ borderColor: "blue" }} // Focused input styling
                  autoSelect
                  focus
                />
              </div>
              <p className="text-right">
                {showResendBtn ? (
                  <a type="button" onClick={handleResend}>
                    Resend OTP
                  </a>
                ) : (
                  <>
                    <Timer />
                  </>
                )}
              </p>
            </div>
            <div className="d-flex justify-content-between gap-1">
              <Link className="Login Cancel mr-3" to="/">
                Cancel
              </Link>
              <button type="submit" className="Login ml-3">
                Verify OTP
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default LoginOtp;
