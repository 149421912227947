import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
function BussinessPop({ showModal, handleClose, editFormHandler, setEducationReport, editEducation }) {
  return (
    <>
    <Modal show={showModal} onHide={handleClose}>
    <Modal.Body >
    <div className="modal-body">
          <div className="Category">
            <div className='text-end'>
            <a
              href="javascript:void(0);"
              className="CloseModal"
              data-dismiss="modal"
              onClick={()=>handleClose()}
            >
              ×
            </a>

            </div>
            <h4>Configuration - Business Category</h4>
            <form onSubmit={editFormHandler}>

            <div className="form-group">
              <label>Business Category</label>
              <input
                type="text"
                value={editEducation}
                className="form-control"
                placeholder="Enter the business category"
                onChange={(e)=>setEducationReport(e.target.value)}
              />
            </div>
            <div className="d-flex justify-content-evenly my-4">
              <a className="Button Cancel mr-3" data-dismiss="modal"   onClick={()=>handleClose()} style={{cursor:"pointer"}}>
                Cancel
              </a>
              <button className="Button ml-3" data-dismiss="modal">
                Update
              </button>
            </div>
            </form>
          </div>
    </div>
    


  </Modal.Body>
  </Modal>
    </>
  )
}

export default BussinessPop