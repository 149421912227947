

import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';



function ReportPop({ showModal, handleClose, editFormHandler, inputHandler, editReport }) {

  return (
    <>
  <Modal show={showModal} onHide={handleClose}>
    <Modal.Body >
    <div className="modal-body">
          <div className="Category">
            <div style={{textAlign: 'end'}}>

            <a 
              href="javascript:void(0);"
              className="CloseModal"
              data-dismiss="modal"

             onClick={()=>handleClose()} 
            >
              ×
            </a>
            </div>
            <h3>Configuration - Report</h3>
            <form onSubmit={editFormHandler}>

            <div className="form-group">
              <label>Report Name</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Report Name"
                value={editReport.Report_Name}
                name='Report_Name'
                onChange={inputHandler}
              />
            </div>
            <div className="form-group">
              <label>Description</label>
              <textarea
                rows={4}
                value={editReport.Description}
                className="form-control"
                placeholder="Enter Description"
                name='Description'
                // defaultValue={""}
                onChange={inputHandler}
              />
            </div>
            <div className="d-flex justify-content-evenly mt-4">
              <Link className="Button Cancel mr-3 mx-3" data-dismiss="modal"   onClick={()=>handleClose()} style={{cursor:"pointer"}} >
                Cancel
              </Link>
              <button className="Button" data-dismiss="modal">
                Submit
              </button>
            </div>
            </form>

          </div>
        </div>

           {/* <div className="Category" >
                    
                    <h3 className='text-center'>Add FAQ's</h3>
                    <form onSubmit={editFormHandler}>

                    <div className="form-group mt-4">
                      <label>Question</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Question"
                        name="Report_Name"
                        value={editReport.Report_Name}
                        onChange={inputHandler}
                      />
                    </div>
                    <div className="form-group">
                      <label>Answer</label>
                      <textarea
                        className="form-control"
                        placeholder="write here..."
                        rows={4}
                        value={editReport.Description}
                        name="Description"
                        onChange={inputHandler}
                      />
                    </div>
                    <button className="Button" data-toggle="modal">
                      Save
                    </button>
                    </form>
                  </div> */}


  </Modal.Body>
  </Modal>
  </>
  )
}

export default ReportPop