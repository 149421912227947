import axiosInstance from "../axios/axios";

class ConfigurationService {
  async getInterest(type) {
    try {
      const res = await axiosInstance.get(
        `/admin/config/getConfig?configType=${type}`
      );

      console.log("interests=>", res);
      if (res.data.success) {
        return {
          success: true,
          data: res.data.data,
        };
      } else {
        return false;
      }
    } catch (err) {
      console.log(err.message);
    }
  }

  async addInterest(data) {
    try {
      const res = await axiosInstance.post(`/admin/config/addConfig`, data);

      console.log("post interests=>", res);
      if (res.data.success) {
        return {
          success: true,
        };
      } else {
        return false;
      }
    } catch (err) {
      console.log(err.message);
    }
  }




  
  
  async getConfigById(data){
  
    try {
      // /admin/config/getConfigById?configType=interests&configId=67051c921719cef342854d83
      const res = await axiosInstance.get(`/admin/config/getConfigById?/configType=${data.configType}&configId=${data.reportId}`,data);
 
      if (res.data.success) {
        return {
          success: true,
        };
      } else {
        return false;
      }
    } catch (err) {
      console.log("Error Message:", err.message);  // Specific error message
      console.log("Error Response:", err.response); // Server response (if available)
      console.log("Error Config:", err.config);     // Axios request configuration
    }
  }

  async updateConfigData(data){
    
    try {
      const res = await axiosInstance.put(`/admin/config/updateConfig?configType=${data.configType}&configId=${data.reportId}`,data);
      console.log("config data",res.data)
      if (res.data.success) {
        return {
          success: true,
          data: res.data.data,
        };
      } else {
        return false;
      }
    } catch (err) {
      console.log("Error Message:", err.message);  // Specific error message
      console.log("Error Response:", err.response); // Server response (if available)
      console.log("Error Config:", err.config);     // Axios request configuration
    }
  }


  async deleteConfigData(data){
    try {
      const res = await axiosInstance.get(`/admin/config/deleteConfigById?configType=${data.configType}&configId=${data.reportId}`);
      if (res.data.success) {
        return {
          success: true,
          data: res.reportedPosts
        };
      } else {
        return false;
      }
    } catch (err) {
      console.log(err.message);
    }
  }
}

export const configurationService = new ConfigurationService();
