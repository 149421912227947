import ResetPass from "../../assets/images/reset-password.svg";
import { useState } from "react";
import { setLoader } from "../../store/LoaderSlice";
import { useDispatch, useSelector } from "react-redux";
import { loginService } from "../../services/LoginService";
import { toast } from "react-toastify";
import { showNotification } from "../../helper/helper";
import { useNavigate } from "react-router-dom";
const LoginReset = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [newPAss, setnewPAss] = useState("");
  const [confPass, setconfPass] = useState("");
  const [showp1, setshowp1] = useState("password");
  const [showp2, setshowp2] = useState("password");

  const email = useSelector((state) => state.otp.value.email);

  async function changePassword(e) {
    e.preventDefault();
    if (newPAss === confPass) {
      dispatch(setLoader(true));
      const res = await loginService.resetPassword(newPAss, confPass, email);
      if (res.data.success) {
        showNotification(
          "success",
          "Password Changed",
          "Password changed succesfuly."
        );
        navigate("/");
      } else {
        toast("Something went wrong.");
      }
      dispatch(setLoader(false));
    } else {
      toast("Passwords do not match");
    }
  }
  return (
    <>
      <div className="LoginArea">
        <div className="LoginRight">
          <img src={ResetPass} />
        </div>
        <div className="LoginLeft">
          <h3>
            <span>Reset Password</span>
          </h3>
          <h4>No Problem! Enter your new password</h4>
          <form onSubmit={changePassword}>
            <div className="form-group">
              <label>Enter New Password</label>
              <input
                type={showp1}
                className="form-control"
                placeholder="Password"
                required
                onChange={(e) => setnewPAss(e.target.value)}
                minLength="6"
                pattern=".*[\W_].*"
                title="Password must be at least 6 characters long and include at least one special character"
              />
              <span className="icon">
                <i
                  className="fa fa-eye"
                  onClick={() =>
                    showp1 == "password"
                      ? setshowp1("text")
                      : setshowp1("password")
                  }
                />
              </span>
            </div>
            <div className="form-group">
              <label>Confirm Password</label>
              <input
                type={showp2}
                className="form-control"
                placeholder="Password"
                required
                onChange={(e) => setconfPass(e.target.value)}
                minLength="6"
                pattern=".*[\W_].*"
                title="Password must be at least 6 characters long and include at least one special character"
              />
              <span className="icon">
                <i
                  className="fa fa-eye"
                  onClick={() =>
                    showp2 == "password"
                      ? setshowp2("text")
                      : setshowp2("password")
                  }
                />
              </span>
            </div>
            {/* <button class="Login">Submit</button>  */}
            <div className="d-flex justify-content-between gap-1">
              <a className="Login Cancel mr-3" href="login-otp.html">
                Cancel
              </a>
              <button className="Login ml-3" type="submit">
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default LoginReset;
