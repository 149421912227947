import { Link, useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { admin } from "../../store/AdminSlice";
import { useDispatch, useSelector } from "react-redux";
import adminAvatar from "../../assets/images/admin-avatar.jpg";
import HeadTitle from "./HeadTitle";


const Header = () => {
  const location = useLocation();
  const adminProfile = useSelector((state) => state.admin.value);
 
  const navigate = useNavigate();
  const dispatch = useDispatch();
  function logout() {
    localStorage.clear();
    dispatch(admin({}));
    navigate("/");
  }


  const handleLogout = () => {
    Swal.fire({
      title: "Are you sure you want to log out?",
      text: "You will be logged out from the system!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Logout",
      cancelButtonText: "Cancel",
      reverseButtons: true,
      customClass: {
        title: "swal-title",
        content: "swal-content",
        confirmButton: "swal-button",
        cancelButton: "swal-button",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        logout();
        Swal.fire(
          "Logged Out!",
          "You have successfully logged out.",
          "success"
        );
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        // Simply close the alert if Cancel is pressed
      }
    });
  };
  return (
    <>
      <div className="Header">
        <div className="Logo">
          <img src={require("../../assets/images/Logo.png")} />
        </div>
        <div className="Navigation">
  

  <HeadTitle/>
        
          {/* {
            location.pathname.startsWith("/dashboard")?
            <h3 className="HeaderTitle">Dashboard</h3>
           
            :  location.pathname.startsWith("/community")?
              <h3 className="HeaderTitle">Community</h3>
            :  location.pathname.startsWith("/message")?
              <h3 className="HeaderTitle">Message</h3>
              :  location.pathname.startsWith("/sub-admin")?
              <h3 className="HeaderTitle">Sub Admin</h3>
              :  location.pathname.startsWith("/reported-post")?
              <h3 className="HeaderTitle">Reported Post</h3>
              :  location.pathname.startsWith("/notification")?
              <h3 className="HeaderTitle">Notification</h3>
              :  location.pathname.startsWith("/cms")?
              <h3 className="HeaderTitle">CMS</h3>
              // ==================configuration part=======
              :  location.pathname.startsWith("/interest")?
              <h3 className="HeaderTitle"><span>Configuration</span><i class="fa-solid fa-chevron-right"></i>Interest</h3>
              :  location.pathname.startsWith("/report")?
              <h3 className="HeaderTitle"><span>Configuration</span><i class="fa-solid fa-chevron-right"></i>Report</h3>
              :  location.pathname.startsWith("/education")?
              <h3 className="HeaderTitle"><span>Configuration</span><i class="fa-solid fa-chevron-right"></i>Education</h3>
              :  location.pathname.startsWith("/business-category")?
              <h3 className="HeaderTitle"><span>Configuration</span><i class="fa-solid fa-chevron-right"></i>Business Category</h3>
              :  location.pathname.startsWith("/area-of-expertise")?
              <h3 className="HeaderTitle"><span>Configuration</span><i class="fa-solid fa-chevron-right"></i>Area of Expertise</h3>
              // ==========use list==========
              :location.pathname.startsWith("/user-list")?
              <h3 className="HeaderTitle"><span>User</span><i class="fa-solid fa-chevron-right"></i>User List</h3>
              : location.pathname.startsWith("/verify-account")?
              <h3 className="HeaderTitle"><span>User</span><i class="fa-solid fa-chevron-right"></i>Verify Account</h3>
              : location.pathname.startsWith("/expert-request")?
              <h3 className="HeaderTitle"><span>User</span><i class="fa-solid fa-chevron-right"></i>Expert Request</h3>
              : location.pathname.startsWith("/upgradation-request")?
              <h3 className="HeaderTitle"><span>User</span><i class="fa-solid fa-chevron-right"></i>Upgradation Request</h3>
              : null

   


            
          } */}
          {/* <h3 className="HeaderTitle">Dashboard</h3> */}
          <div className="Avater text-capitalize">
            <a href="javascript:void(0);">
              <figure>
                <img src={adminProfile?.profileImage} />
              </figure>
              {adminProfile?.name} <br /> {adminProfile?.roles}
            </a>
            <ul>
              <li>
                <figure>
                  <img src={adminAvatar} />
                </figure>
                <h4>
                  {adminProfile?.name} <span>{adminProfile?.roles}</span>
                </h4>
              </li>
              <li>
                <a href="javascript:void(0);">
                  <strong>Account Options</strong>
                </a>
              </li>
              <li>
                <Link to="/change-password">
                  Change Password
                  <span>
                    <i className="fa-solid fa-lock" />
                  </span>
                </Link>
              </li>
              <li onClick={handleLogout}>
                <a
                  href="javascript:void(0);"
               
                >
                  Logout
                  <span>
                    <i className="fa fa-sign-out" />
                  </span>
                </a>
              </li>
            </ul>
          </div>
          {/* <div class="clear"></div> */}
        </div>
      </div>
    </>
  );
};

export default Header;
