import axiosInstance from "../axios/axios";

class ReportServices {
    async getReportedData(id=""){
    
        try {
          // const res = await axiosInstance.get(`/admin/report/getAllReportedPosts?postType=individualPost&limit=2`);
          // const res = await axiosInstance.get(`/admin/report/getAllReportedPosts?postType=individualPost`);
          const res = await axiosInstance.get(`/admin/report/getAllReportedPosts?postType=individualPost&&userId=${id}`);
          console.log("Reported Data data",res.data)
          if (res.data.success) {
            return {
              success: true,
              data: res.data.data,
            };
          } else {
            return false;
          }
        } catch (err) {
          console.log("Error Message:", err.message);  // Specific error message
          console.log("Error Response:", err.response); // Server response (if available)
          console.log("Error Config:", err.config);     // Axios request configuration
        }
      }

    async getReportedUserData(id){
    
        try {
          // const res = await axiosInstance.get(`/admin/report/getAllReportedPosts?postType=individualPost&&userId=${id}`);
          const res = await axiosInstance.get(`/admin/report/getAllReportedBy/${id}`);
          // const res = await axiosInstance.get(`/admin/report/getAllReportedPosts?userId=${id}`);

          console.log("Reported Data users",res.data)
          if (res.data.success) {
            return {
              success: true,
              data: res.data.data,
            };
          } else {
            return false;
          }
        } catch (err) {
          console.log("Error Message:", err.message);  // Specific error message
          console.log("Error Response:", err.response); // Server response (if available)
          console.log("Error Config:", err.config);     // Axios request configuration
        }
      }

      async deletedRepost(id){
        try {
          const res = await axiosInstance.delete(`/admin/report/deletePost?postId=${id}`);
          console.log("Deleted Repost",res.data)
          if (res.data.success) {
            return {
              success: true,
            };
          } else {
            return false;
          }
          
        } catch (error) {
          
        }

      }

}

export const  reportServices=new ReportServices();