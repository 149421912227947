// store/index.js

import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import loaderReducer from "./LoaderSlice";
import adminReducer from "./AdminSlice";
import otpReducer from "./OtpSlice";

const persistConfig = {
  key: "myAppLoader",
  storage,
  whitelist: ["loader", "admin", "otp"],
};

const rootReducer = combineReducers({
  loader: loaderReducer,
  admin: adminReducer,
  otp: otpReducer,
});

// Persisted reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Configure store
export const store = configureStore({
  reducer: persistedReducer,
});

// Persistor for persistence logic
export const persistor = persistStore(store);
