// import React from "react";
// import {
//   LineChart,
//   Line,
//   XAxis,
//   YAxis,
//   CartesianGrid,
//   Tooltip,
//   Legend,
// } from "recharts";

// const UserActivityChart = () => {
//   const data = [
//     { name: "Day 1", logins: 4000, interactions: 2400, posts: 2400 },
//     { name: "Day 2", logins: 3000, interactions: 1398, posts: 2210 },
//     { name: "Day 3", logins: 2000, interactions: 9800, posts: 2290 },
//     { name: "Day 4", logins: 2780, interactions: 3908, posts: 2000 },
//     { name: "Day 5", logins: 1890, interactions: 4800, posts: 2181 },
//     { name: "Day 6", logins: 2390, interactions: 3800, posts: 2500 },
//     { name: "Day 7", logins: 3490, interactions: 4300, posts: 2100 },
//   ];

//   return (
//     <LineChart
//       width={500}
//       height={250}
//       data={data}
//       margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
//     >
//       <CartesianGrid strokeDasharray="3 3" />
//       <XAxis dataKey="name" />
//       <YAxis />
//       <Tooltip />
//       <Legend />

//       {/* Line for logins */}
//       <Line type="monotone" dataKey="logins" stroke="#8884d8" />

//       {/* Line for interactions */}
//       <Line type="monotone" dataKey="interactions" stroke="#82ca9d" />

//       {/* Line for posts */}
//       <Line type="monotone" dataKey="posts" stroke="#ff7300" />
//     </LineChart>
//   );
// };

// export default UserActivityChart;

import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const UserActivityChart = () => {
  const data = [
    { name: "Day 1", logins: 4000, interactions: 2400, posts: 2400 },
    { name: "Day 2", logins: 3000, interactions: 1398, posts: 2210 },
    { name: "Day 3", logins: 2000, interactions: 9800, posts: 2290 },
    { name: "Day 4", logins: 2780, interactions: 3908, posts: 2000 },
    { name: "Day 5", logins: 1890, interactions: 4800, posts: 2181 },
    { name: "Day 6", logins: 2390, interactions: 3800, posts: 2500 },
    { name: "Day 7", logins: 3490, interactions: 4300, posts: 2100 },
  ];

  return (
    <ResponsiveContainer width="100%" height={250}>
      <LineChart
        data={data}
        margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />

        {/* Line for logins */}
        <Line type="monotone" dataKey="logins" stroke="#8884d8" />

        {/* Line for interactions */}
        <Line type="monotone" dataKey="interactions" stroke="#82ca9d" />

        {/* Line for posts */}
        <Line type="monotone" dataKey="posts" stroke="#ff7300" />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default UserActivityChart;
