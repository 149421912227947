import { MoonLoader } from "react-spinners";
const Loader = () => {
  return (
    <>
      <div className="loader">
        <MoonLoader className="load" size={40} color="#fff" />
      </div>
    </>
  );
};

export default Loader;

