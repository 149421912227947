import React from 'react'
import { useLocation } from 'react-router-dom'


function AreaOfExperties() {
    const location = useLocation();
    const {item} = location.state ||""; // Extracting area of expertise item from location state
    
  return (
    <>
    <div className="WrapperArea">
  <div className="WrapperBox">
    <div className="Small-Wrapper">
      <div className="SubAdminBox mb-0">
        <figcaption>
          <p>
            <strong>Area of Expertise</strong>
            <span>{item.areaOfExpertiseName}</span>
          </p>
          <p>
            <strong>Status</strong>
            <span>{item.status}</span>
          </p>
        </figcaption>
      </div>
    </div>
  </div>
</div>

 
    </>
  )
}

export default AreaOfExperties