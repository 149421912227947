import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  value: {},
};

export const otpSlice = createSlice({
  name: "otp",
  initialState,
  reducers: {
    setOtp: (state, action) => {
      // renamed from otpSlice to setOtp
      console.log("action triggered", action);
      console.log("state before update", state.value);
      state.value = action.payload;
      console.log("state after update", state.value);
    },
  },
});

// Export the action creator (setOtp in this case)
export const { setOtp } = otpSlice.actions;

// Export the reducer
export default otpSlice.reducer;
