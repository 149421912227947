import { useState } from "react";
import avatar from "../../assets/images/Avatar-1.png";
import { useEffect } from "react";
import { communityServices } from "../../services/CommunityService";
import { Link } from "react-router-dom";

import Swal from "sweetalert2";
import { useDebounce } from "use-debounce";

const Community = () => {
  const [searchText, setSearchText] = useState('');
  const [value] = useDebounce(searchText, 1000);
  const [comunityData,setComunityData]=useState([])





const getComunityData=async(value="")=>{

  try {
    
    const res=await communityServices.getCommunity(value);
 
    if(res){
   
      setComunityData(res)
    }
  } catch (error) {
    console.log(error)
  }

}

useEffect(()=>{
  getComunityData(value)
},[value])

const DataConversion=(dataString)=>{
const data=new Date(dataString)

const year=data.getFullYear();

const month=data.getMonth()+1;

const day=data.getDate();

return `${day}/${month}/${year}`;
}




const deleteCommunityFun=(id)=>{
  Swal.fire({
    title: 'Are you sure?',
    text: "You won't be able to revert this!",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes, delete it!'
  }).then((result) => {
    if (result.isConfirmed) {
      communityServices.deleteCommunityById(id)
       .then(()=>{
          Swal.fire(
            'Deleted!',
            'Your community has been deleted.',
           'success'
          )
          getComunityData()
        })
       .catch((error)=>{
          console.log(error)
        })
    }
  })

}


const sortJoinData=()=>{

 const sortedData = [...comunityData].sort((a, b) => {
      
        const dateA = new Date(a.createdAt);
        const dateB = new Date(b.createdAt);
        
        // return dateB - dateA; 
        return dateA - dateB; 
    });

    setComunityData(sortedData);

}
const shortbyTotalMember=()=>{

 const sortedData = [...comunityData].sort((a, b) => {
      
        const dateA = a.totalMembers
        const dateB = b.totalMembers;
        
        return dateB - dateA; 

    });

    setComunityData(sortedData);

}

  // ==============pagination code===================

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

const indexOfLastItem = currentPage * itemsPerPage;
const indexOfFirstItem = indexOfLastItem - itemsPerPage;
const currentItems = comunityData.slice(indexOfFirstItem, indexOfLastItem);

const totalPages = Math.ceil(comunityData.length / itemsPerPage);

const handlePageChange = (pageNumber) => {
  setCurrentPage(pageNumber);
};


  // =================pagination code ends =================

  return (
    <>
      <div className="WrapperArea">
        <div className="WrapperBox">
          <div className="FilterBody">
            <div className="FilterLeft">
              <div className="form-group">
                <input
                  type="search"
                  className="form-control"
                  placeholder="Search users here"
                  onChange={(e)=>setSearchText(e.target.value)}
                />
                <span className="Icon">
                  <i className="fa-solid fa-magnifying-glass" />
                </span>
              </div>
            </div>
            <div className="FilterRight">
              <div className="form-group">
                <div className="dropdown">
                  <a
                    className="Filter"
                    href="javascript:void(0);"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i className="fa-solid fa-filter" />
                  </a>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuLink"
                  >
                    <ul>
                      <li className="FilterOprions">
                        <h5>Filter Options</h5>
                      </li>
                      <li>
                        <a href="" className="dropdown-item border-0">
                          Type{" "}
                          <span>
                            <i className="fa-solid fa-chevron-right" />
                          </span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="form-group">
                <div className="dropdown">
                  <a
                    className="Filter"
                    href="javascript:void(0);"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i className="fa-solid fa-bars-staggered" />
                  </a>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuLink"
                  >
                    <ul>
                      <li className="FilterOprions">
                        <h5>Sort Options</h5>
                      </li>
                      <li>
                        <a href="javascript:void(0);" className="dropdown-item" onClick={sortJoinData}>
                          Join Date
                        </a>
                      </li>
                      <li>
                        <a href="javascript:void(0);" className="dropdown-item" onClick={shortbyTotalMember}>
                          Number of Members
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <button className="Button" onClick={()=>getComunityData()}>Clear</button>

            </div>
          </div>
          <div className="TableList">
            <table style={{ width: "130%" }}>
              <thead>
                <tr>
                  <th>Sr No.</th>
                  <th>Profile</th>
                  <th>Community Name</th>
                  <th>Community Username</th>
                  <th>Join Date</th>
                  <th>Type</th>
                  <th>Location</th>
                  <th>Total Members</th>
                  <th>Unseen Report Count</th>
                  <th>Reported Count</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
               {comunityData?.length>0 ?
                comunityData?.map((item,index)=>(
                
                <tr>
                  <td>{index+1}</td>
                  <td>
                    <figure>
                      <img src={item.logo}  style={{height:"50px"}} />
                    </figure>
                  </td>
                  <td>{item.communityName}</td>
                  <td>{item.communityUsername}</td>
                  
                  <td>{DataConversion(item.createdAt)}</td>
                  {/* <td>{item.createdAt}</td> */}
                  <td>{item.communityType}</td>
                  <td>{item.location}</td>
                  <td>{item.totalMembers}</td>
                  <td>{item.unseenReportCount}</td>
                  <td>{item.totalReports}</td>
                  <td>
                    <div className="Actions">
                      <Link className="Blue" to={`/community/community-details/${item._id}`} >
                        <i className="fa fa-eye" />
                      </Link>
                      <a
                        className="Red"
                        data-toggle="modal"
                        onClick={()=>deleteCommunityFun(item._id)}
                       
                      >
                        <i className="fa fa-trash" />
                      </a>
                    </div>
                  </td>
                </tr>
                ))
                :<div>Data Not Found</div>
               }
          
              </tbody>
            </table>
          </div>
          {comunityData.length > 0 &&

          <div className="Pagination">
            <div className="Left">
              <p>Show Records:</p>
              <select className="form-control" value={itemsPerPage} onChange={(e)=>setItemsPerPage(e.target.value)}>
                <option selected="" value={10}>10</option>
                <option value={20}>20</option>
                <option value={30}>30</option>
                <option value={40}>40</option>
              </select>
            </div>
            <ul>

            
              <li>
                <a
                  href="javascript:void(0);"
                  onClick={() => {
                    if (currentPage > 1) {
                      handlePageChange(currentPage - 1);
                    }
                  }}
                  className={currentPage === 1 ? "disabled" : ""}
                >
                  <i className="fa-solid fa-chevron-left" />
                </a>
          </li>
        {[...Array(totalPages)].map((_, i) => {
              const pageNumber = (i + 1).toString().padStart(2, "0"); // Pad single-digit numbers
              return (
                <li key={i} onClick={() => handlePageChange(i + 1)}>
                  <a
                    href="javascript:void(0);"
                    className={currentPage === i + 1 ? "active" : ""}
                  >
                    {pageNumber}
                  </a>
                </li>
              );
            })}




              <li>
              <a
                href="javascript:void(0);"
                onClick={() => {
                  if (currentPage < totalPages) {
                    handlePageChange(currentPage + 1);
                  }
                }}
                className={currentPage === totalPages ? "disabled" : ""}
              >
                <i className="fa-solid fa-chevron-right" />
              </a>
            </li>

              
            </ul>
          </div>
        }
        </div>
      </div>
    </>
  );
};

export default Community;
