import { useState } from "react";
import Avatar from "../../../assets/images/Avatar-1.png";

import { uploadToAws } from "../../../helper/s3Helper";
import { bucketService } from "../../../services/BucketService";
import { bucketCreds } from "../../../config/s3config";
import { setLoader } from "../../../store/LoaderSlice";
import { useDispatch } from "react-redux";
import { configurationService } from "../../../services/ConfigurationService";
import { showNotification } from "../../../helper/helper";
import { useLocation } from "react-router-dom";


function IntrestEdit() {

  const location = useLocation();
  const {item} = location.state ||""; 
  const dispatch = useDispatch();
  const [categoryName, setCategoryName] = useState(item?.categoryName);
  const [interestList, setinterestList] = useState(item?.interestsName);
// console.log(item._id)
  const [interest, setInterest] = useState(item?.interestsName);
  // const [interest, setInterest] = useState([]);
  const [image, setImage] = useState(Avatar);
  const [preview, setpreview] = useState(Avatar);
  const [fileError, setFileError] = useState("");

console.log(item)
  // Handle file upload and validation
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const fileTypes = ["image/jpeg", "image/png", "image/jpg"];
      if (!fileTypes.includes(file.type)) {
        setFileError("Please select a valid image file (jpg, png).");
        return;
      } else {
        setFileError("");
        const reader = new FileReader();
        reader.onloadend = () => {
          setImage(file);
          setpreview(reader.result);
        };
        reader.readAsDataURL(file);
      }
    }
  };

  const handleCategoryNameChange = (e) => {
    setCategoryName(e.target.value);
  };

  const handleInterestChange = (e, index) => {
    // Create a copy of the interests array
    const updatedInterests = [...interest];
        
    // Update the specific index with the new value
    updatedInterests[index] = e.target.value;

    // Set the updated array to the state
    setInterest(updatedInterests);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    dispatch(setLoader(true));
    const creds = await bucketService.getCreds();
    if (creds.success) {
      bucketCreds.accessKeyId = creds.access;
      bucketCreds.secretAccessKey = creds.secret;

      console.log("Creds====>", bucketCreds);
      const link = await uploadToAws(image, bucketCreds);

      console.log("Category Name:", categoryName);
      console.log("Interest:", interest);
      console.log("Link", link);

      if (link && categoryName && interest?.length) {

        const dataObj = {
          configType: "interests",
            thumbnail: link,
            categoryName: categoryName,
            interestsName: interest,
            reportId:item._id,
            status: "active",
          }


        const res = await configurationService.updateConfigData(dataObj);
        console.log(res);
        if (res?.success) {
          showNotification(
            "success",
            "Interest category saved successfuly",
            "Saved Successfuly"
          );

          setInterest([]);
          setCategoryName("");
          setImage("");
          setpreview(Avatar);
          setinterestList([1]);
        } else {
          showNotification(
            "error",
            "Something went wrong",
            "Interest not saved."
          );
        }
      }

      dispatch(setLoader(false));
    }
  };

  function removeInput(index) {
    console.log("index =>", index);
    // console.log("interestList =>", interestList);

    // Remove the item from interestList at the given index
    const filteredList = interestList.filter((_, i) => i !== index - 1); // Filter by index
    // console.log("filtered List =>", filteredList);
    setinterestList(filteredList);

    console.log("Interest = ", interest);

    const filteredInterest = interest.filter((_, i) => i !== index - 1);
    setInterest(filteredInterest);
    console.log("Filtered Interest = ", filteredInterest);
  }






  return (
   <>
 <div className="WrapperArea">
        <div className="WrapperBox">
          <div className="Small-Wrapper">
            <div className="TitleBox">
              <h4 className="Title">Add Thumbnail</h4>
            </div>
            <div className="CommonForm">
              <div className="row">
                <div className="col-sm-5">
                  <div className="AddThumbnailBody">
                    <form onSubmit={handleSubmit}>
                      <figure>
                        {/* Show image preview */}
                        <img src={preview} alt="Thumbnail Preview" />
                        <div className="EditBtn">
                          <i className="fa fa-pencil"></i>
                          <input
                            type="file"
                            accept="image/*"
                            onChange={handleFileChange}
                        
                          />
                        </div>
                      </figure>
                      {fileError && <p style={{ color: "red" }}>{fileError}</p>}

                      <div className="form-group">
                        <label>Category Name</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Category Name"
                          value={categoryName}
                          onChange={handleCategoryNameChange}
                          required
                        />
                      </div>

                      <div className="form-group">
                        <label>Interest (Optional)</label>
                        {console.log("INT",interestList)}
                        {interestList?.length
                          ? interestList.map((item, index) => (
                              <div key={index} className="d-flex gap-4 my-3">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Add Interest"
                                  value={interest[index]}
                                  onChange={(e) =>
                                    handleInterestChange(e, index)
                                  }
                                  required
                                />

                                {index == 0 ? null : (
                                  <button
                                    type="button"
                                    className="btn btn-sm btn-outline-dark"
                                    onClick={() => removeInput(index + 1)}
                                  >
                                    Remove
                                  </button>
                                )}
                              </div>
                            ))
                          : null}

                        <a
                          href="javascript:void(0);"
                          className="PlusBtn"
                          data-toggle="modal"
                          data-target="#AddInterest"
                          onClick={() => {
                            const lastValue =
                              interestList?.length > 0
                                ? Math.max(...interestList)
                                : 0;
                            setinterestList([...interestList, lastValue + 1]);
                          }}
                        >
                          <i className="fa-solid fa-plus" />
                        </a>
                      </div>
                      <a
                        href="interest.html"
                        className="Button Cancel ml-0 mr-3 mx-2"
                      >
                        Cancel
                      </a>
                      <button type="submit" className="Button">
                        Submit
                      </button>
                    </form>
                  </div>
                </div>
              </div>
              {/* Buttons */}
            </div>
          </div>
        </div>
      </div>

   </>
  )
}

export default IntrestEdit