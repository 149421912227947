import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { subAdminServices } from "../../services/subAdminServices";
import { toast } from "react-toastify";
import { useDebounce } from 'use-debounce';
import { setLoader } from "../../store/LoaderSlice";
import { useDispatch } from "react-redux";



const SubAdmin = () => {
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState('');
  const [value] = useDebounce(searchText, 1000);

  const [subAdminData,setSubAdminData]=useState([]);

    // ==============pagination code===================

    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    
  
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = subAdminData?.slice(indexOfFirstItem, indexOfLastItem);
  
  const totalPages = Math.ceil(subAdminData?.length / itemsPerPage);
  
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  
  
    // =================pagination code ends =================



  
  useEffect(()=>{
      
  searchFunction();
  },[value])


// =============search impilimentation=
  const searchFunction=()=>{
    if(value!=""){

      const filterData= subAdminData?.filter((item)=> 
        item.name.toLocaleLowerCase().includes(value.toLocaleLowerCase())
      || item.email.toLocaleLowerCase().includes(value.toLocaleLowerCase())
      || item.phoneNumber.includes(value)
    );
      console.log("Filtered data",filterData);
      setSubAdminData(filterData);
    }else{
      getAdminData();
    }
  }



  const getAdminData= async()=>{
    dispatch(setLoader(true))
    try {
       
      const res= await  subAdminServices?.getSubAdmin();

      if(res.success){
        setSubAdminData(res.data?.data.subAdmins);
        console.log("console log",res.data );
      }else{
        toast.error(res.message);
      }
      
    } catch (error) {
      console.log(error)
      
    }
    dispatch(setLoader(false))

  }



  // ==============Date covertion function============
   const dateConverter=(dateString)=>{
    const date=new Date(dateString);
    const year=date.getFullYear();
    const month=date.getMonth()+1;
    const day=date.getDate();
    return `${day}/${month}/${year}`;
  }

  const statusHandler=async(item)=>{

    const newState=item.status=="Active"?"Inactive":"Active";
   
    try {
      const data={
        subAdminId:item._id,
        status:newState
      }
      
      const res= await  subAdminServices.updateStaf(data);
      if(res){

        getAdminData();
      }
    } catch (error) {
       toast.error(error);
    }


  }

  useEffect(() => {
    getAdminData();
  }, []);

  return (
    <>
      <div className="WrapperArea">
        <div className="WrapperBox">
          <div className="FilterBody">
            <div className="FilterLeft">
              <div className="form-group">
                <input
                  type="search"
                  className="form-control crossHandler"
                  placeholder="Search users here"
                  
                  onChange={(e) => {
                    setSearchText(e.target.value);
                  }}
                
                />

                <span className="Icon">
                  <i className="fa-solid fa-magnifying-glass" />
                </span>
              </div>
            </div>
            <div className="FilterRight">
              <Link className="Button"  to={"/sub-admin/add-subAdmin"}>
                Add Sub Admin
              </Link>
            </div>
          </div>
          <div className="TableList">
            <table style={{ width: "110%" }}>
              <thead>
                <tr>
                  <th>Sr No.</th>
                  <th>Sub Admin Id</th>
                  <th>Sub Admi Name</th>
                  <th>Number</th>
                  <th>Email ID</th>
                  <th>Registered Date</th>
                  <th>Role</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>

                {subAdminData?.length?
                  subAdminData?.map((item,idx)=>(
                <tr>
                  <td>{idx+1}</td>
                  <td>{item.adminId}</td>
                  <td>{item.name}</td>
                  <td>{item.phoneNumber}</td>
                  <td>{item.email}</td>
                  {/* <td>{item.createdAt}</td> */}
                  <td>{dateConverter(item.createdAt)}</td>
                  <td>{item.roles}</td>
                  <td>
                    {
                      item.status==="Inactive"? <span className="Red">Inactive</span> : <span className="Green">Active</span>
                    }
                  
                  </td>
                  <td>
                    <div className="Actions">
                      <label className="switch">
                        <input type="checkbox"  checked={item.status== "Active"} onClick={()=>statusHandler(item)}/>
                        <span className="slider"  />
                      </label>
                      <Link className="Green" to="/sub-admin/edit-subDomin" state={{id:item._id}}>
                        <i className="fa fa-pencil" />
                      </Link>
                      <Link className="Blue" to="/sub-admin/view-subDomin" state={{id:item._id}}>
                        <i className="fa fa-eye" />
                      </Link>
                    </div>
                  </td>
                </tr>
                  ))
      
                :<div className="text-center">Data not found</div>
                }
             
              </tbody>
            </table>
          </div>
          {
            subAdminData.length>0 &&
          <div className="Pagination">
            <div className="Left">
              <p>Show Records:</p>
              <select className="form-control" value={itemsPerPage} onChange={(e)=>setItemsPerPage(e.target.value)}>
                <option selected="" value={10}>10</option>
                <option value={20}>20</option>
                <option value={30}>30</option>
                <option value={40}>40</option>
              </select>
            </div>
            <ul>
            <li>
                <a
                  href="javascript:void(0);"
                  onClick={() => {
                    if (currentPage > 1) {
                      handlePageChange(currentPage - 1);
                    }
                  }}
                  className={currentPage === 1 ? "disabled" : ""}
                >
                  <i className="fa-solid fa-chevron-left" />
                </a>
          </li>

          {[...Array(totalPages)].map((_, i) => {
              const pageNumber = (i + 1).toString().padStart(2, "0"); // Pad single-digit numbers
              return (
                <li key={i} onClick={() => handlePageChange(i + 1)}>
                  <a
                    href="javascript:void(0);"
                    className={currentPage === i + 1 ? "active" : ""}
                  >
                    {pageNumber}
                  </a>
                </li>
              );
            })}

              {/* <li>
                <a href="javascript:void(0);">
                  <i className="fa-solid fa-chevron-left" />
                </a>
              </li> */}
              {/* <li>
                <a href="javascript:void(0);" className="active">
                  01
                </a>
              </li>
              <li>
                <a href="javascript:void(0);">02</a>
              </li>
              <li>
                <a href="javascript:void(0);">03</a>
              </li> */}


              <li>
              <a
                href="javascript:void(0);"
                onClick={() => {
                  if (currentPage < totalPages) {
                    handlePageChange(currentPage + 1);
                  }
                }}
                className={currentPage === totalPages ? "disabled" : ""}
              >
                <i className="fa-solid fa-chevron-right" />
              </a>
            </li>

              {/* <li>
                <a href="javascript:void(0);">
                  <i className="fa-solid fa-chevron-right" />
                </a>
              </li> */}
            </ul>
          </div>
          }
        </div>
      </div>
    </>
  );
};

export default SubAdmin;
