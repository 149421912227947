// import React from "react";
// import {
//   LineChart,
//   Line,
//   XAxis,
//   YAxis,
//   CartesianGrid,
//   Tooltip,
//   Legend,
// } from "recharts";

// const UserTrend = () => {
//   const data = [
//     {
//       name: "Day 1",
//       newUsers: 150,
//       activeUsers: 1200,
//       posts: 800,
//       interactions: 1200,
//     },
//     {
//       name: "Day 2",
//       newUsers: 200,
//       activeUsers: 1500,
//       posts: 900,
//       interactions: 1500,
//     },
//     {
//       name: "Day 3",
//       newUsers: 180,
//       activeUsers: 1600,
//       posts: 1000,
//       interactions: 1800,
//     },
//     {
//       name: "Day 4",
//       newUsers: 220,
//       activeUsers: 1900,
//       posts: 1200,
//       interactions: 2200,
//     },
//     {
//       name: "Day 5",
//       newUsers: 250,
//       activeUsers: 2100,
//       posts: 1400,
//       interactions: 2300,
//     },
//     {
//       name: "Day 6",
//       newUsers: 300,
//       activeUsers: 2500,
//       posts: 1600,
//       interactions: 2500,
//     },
//     {
//       name: "Day 7",
//       newUsers: 350,
//       activeUsers: 2800,
//       posts: 1800,
//       interactions: 2800,
//     },
//   ];

//   return (
//     <LineChart
//       width={500}
//       height={250}
//       data={data}
//       margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
//     >
//       <CartesianGrid strokeDasharray="3 3" />
//       <XAxis dataKey="name" />
//       <YAxis />
//       <Tooltip />
//       <Legend />

//       {/* Line for newUsers */}
//       <Line type="monotone" dataKey="newUsers" stroke="#8884d8" />

//       {/* Line for activeUsers */}
//       <Line type="monotone" dataKey="activeUsers" stroke="#82ca9d" />

//       {/* Line for posts */}
//       <Line type="monotone" dataKey="posts" stroke="#ff7300" />

//       {/* Line for interactions */}
//       <Line type="monotone" dataKey="interactions" stroke="#ff0000" />
//     </LineChart>
//   );
// };

// export default UserTrend;

import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const UserTrend = () => {
  const data = [
    {
      name: "Day 1",
      newUsers: 150,
      activeUsers: 1200,
      posts: 800,
      interactions: 1200,
    },
    {
      name: "Day 2",
      newUsers: 200,
      activeUsers: 1500,
      posts: 900,
      interactions: 1500,
    },
    {
      name: "Day 3",
      newUsers: 180,
      activeUsers: 1600,
      posts: 1000,
      interactions: 1800,
    },
    {
      name: "Day 4",
      newUsers: 220,
      activeUsers: 1900,
      posts: 1200,
      interactions: 2200,
    },
    {
      name: "Day 5",
      newUsers: 250,
      activeUsers: 2100,
      posts: 1400,
      interactions: 2300,
    },
    {
      name: "Day 6",
      newUsers: 300,
      activeUsers: 2500,
      posts: 1600,
      interactions: 2500,
    },
    {
      name: "Day 7",
      newUsers: 350,
      activeUsers: 2800,
      posts: 1800,
      interactions: 2800,
    },
  ];

  return (
    <ResponsiveContainer width="100%" height={250}>
      <LineChart
        data={data}
        margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Line type="monotone" dataKey="newUsers" stroke="#8884d8" />
        <Line type="monotone" dataKey="activeUsers" stroke="#82ca9d" />
        <Line type="monotone" dataKey="posts" stroke="#ff7300" />
        <Line type="monotone" dataKey="interactions" stroke="#ff0000" />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default UserTrend;
