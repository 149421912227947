import { useLocation, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { setLoader } from "../../store/LoaderSlice";
import { useDispatch, useSelector } from "react-redux";
import { userService } from "../../services/UserService";
import { toast } from "react-toastify";
import roundedImg from "../../assets/images/round-1.svg"
import Swal from "sweetalert2";
import { showNotification } from "../../helper/helper";
import { getFormattedDate, triggerDownloadFromLink } from "../../helper/helper";
import { reportServices } from "../../services/ReportServices";
import actress from "../../assets/images/Avatar-1.png";
import ReactPlayer from 'react-player'
// import Swiper JS
import { Swiper, SwiperSlide } from "swiper/react";
// import required modules
import { Pagination } from 'swiper/modules';

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import ReportedPost from "../Pages/ReportedPost";
const UserDetails = () => {
  const permissions = useSelector((state) => state.admin.value.permissions);
  const [user, setuser] = useState(null);

  const params = useParams();
  const dispatch = useDispatch();

  // ================post report data===========
  const [postreportData, setPostReportData] = useState([]);
    const [userReportrdata,setUserReportrdata]=useState([]);

  

  // ====show more variable===============
  const [isExpanded, setExpanded] = useState(false)
  
  const [expandedCardId, setExpandedCardId] = useState(null);
  const [index, setIndex] = useState(0);

  const handleShowMore=(index)=>{
    setExpandedCardId(index=== expandedCardId ? null : index);
   
  }
  const deletedPost=async(id)=>{
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "OK",
      cancelButtonText: "Cancel",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        const data = {
          configType: "reports",
          reportId: id,
        };
  
        try {
          const res = await reportServices.deletedRepost(data);
          if (res?.success) {
            console.log("delete", res);
            showNotification("success", "deleted", "deleted successfully");
            // showNotification("success","Bussiness ","bussiness added successfully!")
          
            // setinterestsBackup(res.data);
          }
        } catch (error) {
          console.error("Error deleting config:", error);
        } finally {
          dispatch(setLoader(false));
        }
      }
    });
  }
  

  useEffect(() => {
    if (params.id) {
      getUser(params.id);
    }
  }, []);

  async function getUser(id) {
    dispatch(setLoader(true));

    const res = await userService.getUserById(id);
    if (res?.success == true) {
      setuser(res?.data);
    } else {

      toast("Something went wrong. or user Not found");
    }

    dispatch(setLoader(false));
  }


  const handlePostReport=async(id)=>{

    // console.log("post user",id)
    try {
      // const res=await reportServices.getReportedUserData(id);
      const res=await reportServices.getReportedData(id);
      if(res.success){
        setPostReportData(res.data.reportedPosts);
      }else{
        toast.error(res.message);
      }
     
    } catch (error) {
      console.log("error",error)
    }
console.log("postreportData",postreportData)

  }

  const preventEmailSend=(e)=>{
    e.preventDefault();

    const pagename=params.pagename;

  
    if (!module) {
      switch (pagename) {
        case "list":
          module = "User / User List";
        
          break;
        case "verify":
          module = "User / Verify Account";
          break;
        case "expert":
          module = "User / Expert Request";
          break;
        case "upgrade":
          module = "User / Upgradation Request";
          break;
        default:
          module = ""; 
      }
    }
  
    
    
    const hasAccess = permissions.some(
      (perm) =>
        perm.module == module && perm.readAccess==true 
    );

    if (!hasAccess) {
      return showNotification("error","Access denied","you are not allowed to access")
  
    }

    

  }

    // ===================================time to day convertions================================
function getRelativeTime(createdDate) {
  const createdTime = new Date(createdDate);
  const currentTime = new Date();

  const diffInMs = currentTime - createdTime; // Difference in milliseconds
  const diffInHours = Math.floor(diffInMs / (1000 * 60 * 60)); // Convert to hours
  const diffInDays = Math.floor(diffInHours / 24); // Convert to days

  // Logic for displaying time
  if (diffInHours < 24) {
    return `${diffInHours} ${diffInHours === 1 ? "hour" : "hours"} ago`;
  } else {
    return `${diffInDays} ${diffInDays === 1 ? "day" : "days"} ago`;
  }
}

  
  // const getAllReportUser=async(item)=>{
  //   console.log("post user",item.postId)
  //   try {
  //     const res=await reportServices.getReportedUserData(item.postId);
  //     if(res.success){
  //       setUserReportrdata(res.data.reportedPosts)
  //      console.log("All Reported Users:",res.data.reportedPosts)
       
  //     }else{
  //       toast.error(res.message);
  //     }
     
  //   } catch (error) {
      
  //   }
  // }
  const getAllReportUser=async(item)=>{

    try {
      const res=await reportServices.getReportedUserData(item.postId);
      if(res.success){
        setUserReportrdata(res.data.reportedPosts)
       console.log("All Reported Users:",res.data.reportedPosts)
       
      }else{
        toast.error(res.message);
      }
     
    } catch (error) {
      console.log(error)
    }


  }

  return (
    <>
      <div className="WrapperArea">
        <div className="WrapperBox bgBackground">
          <div className="row">
            <div className="col-lg-12">
              <div className="row">
                <div className="col-sm-4">
                  <div className="Dashboard Dashboard4">
                    <figcaption>
                      <h4>Total Posts</h4>
                      <h2>32.90M</h2>
                    </figcaption>
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="Dashboard Dashboard5">
                    <figcaption>
                      <h4>Total Followers</h4>
                      <h2>32.90M</h2>
                    </figcaption>
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="Dashboard Dashboard6">
                    <figcaption>
                      <h4>Total Communities</h4>
                      <h2>32.90M</h2>
                    </figcaption>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="PersonalDetailsBody">
            <span className="Reports">Personal Details</span>
            <div className="ProfileBody">
              <figure>
                <div className="profileImage">
                  <img
                    src={
                      user?.profileImage == "" ? actress : user?.profileImage
                    }
                  />
                </div>

                <figcaption>{user == null ? "-" : user?.name}</figcaption>
              </figure>
              <ul className="Buttons">
                <li>
                  <a href={`mailto:${user?.email}`}
                  onClick={(e)=>preventEmailSend(e)}>
                    <i className="fa-regular fa-envelope" /> Write Mail
                  </a>
                </li>
              </ul>
            </div>
            <article className="pr-0">
              <aside>
                <div className="PersonalBox">
                  {user?.accountType=="Bussiness"?

                  <h5>
                    <strong>Bussiness Name</strong>
                  </h5>
                  :<strong>Full Name</strong>
                  }
                  <p>{user == null?"-": user.accountType=="Bussiness" ?user.businessName:user.name}</p>
                </div>
              </aside>
              <aside>
                <div className="PersonalBox">
                  <h5>
                    <strong>Username</strong>
                  </h5>
                  <p>{user == null ? "-" : user?.username}</p>
                </div>
              </aside>
              <aside>
                <div className="PersonalBox pr-2">
                  <h5>
                    <strong>Email Address</strong>
                  </h5>
                  <p>{user == null ? "-" : user?.email}</p>
                </div>
              </aside>
              <aside>
                <div className="PersonalBox">
                  <h5>
                    <strong>Mobile Number</strong>
                  </h5>
                  <p>{user == null ? "-" : user?.phoneNumber}</p>
                </div>
              </aside>
              <aside>
                <div className="PersonalBox">
                {user?.accountType=="Bussiness" ?
                  <h5>
                    <strong>Established Date</strong>
                  </h5>
                  :
                  <h5>
                    <strong>DOB</strong>{" "}
                  </h5>
                  }
                  <p>
                    {user == null
                      ? "-"
                      : getFormattedDate(user?.establishmentYear, "YYYY-MM-DD")}
                  </p>
                </div>
              </aside>
              <aside>
                <div className="PersonalBox">
                  <h5>
                    <strong>Account Type</strong>
                  </h5>
                  <p>{user == null ? "-" : user?.accountType}</p>
                </div>
              </aside>
            </article>
            <div className="ParaGraphBox">
              {
                user?.accountType=="Bussiness"
                ?
                <><p><strong>WebSite Link</strong></p>
                 {/* <a user?.websiteURL==""?"":href={user?.websiteURL} target="_blank">{user == null ? "-" : user?.websiteURL==""?"N/A":user?.websiteURL}</a> */}
                 <a 
                  {...(user?.websiteURL ? { href: user.websiteURL, target: "_blank", rel: "noopener noreferrer" } : {})}
                >
                  {user == null ? "-" : user?.websiteURL === "" ? "N/A" : user?.websiteURL}
                </a>

                 </>
                 : user?.accountType=="Individual"
                  ?<>
                  <strong>Short Bio</strong> 
                  <p>{user == null ? "-" : user?.shortBio==""?"N/A":user?.shortBio}</p>
                  </>
                  : 
                  <>
                  <strong>BIO</strong> 
                  <p>{user == null ? "-" : user?.detailBio==""?"N/A": user?.detailBio}</p>
                  </>
                
              }
             
              {/* <p>{user == null ? "-" : user?.shortBio}</p> */}
            </div>
            {
             user?.accountType=="Bussiness"
             ?
            <>
             <div className="ParaGraphBox">
              <strong>Services</strong>
              <p>{user == null ? "-" :user?.services==""?"N/A": user?.services}</p>
            </div>
           
            </> 
            : user?.accountType!="Individual"&&<>
             <div className="ParaGraphBox">
              <strong>Detailed BIO</strong>
              <p>{user == null ? "-" :user?.detailBio==""?"N/A": user?.detailBio}</p>
            </div>
            </>
            }
            {/* <div className="ParaGraphBox">
              <p>Detailed BIO</p>
              <p>{user == null ? "-" : user?.detailBio}</p>
            </div> */}
          </div>
          <div className="PersonalDetailsBody">
            <span className="Reports">Professional Details</span>
            <article>
              <aside>
                <div className="PersonalBox">
                  <h5>
                    <strong>Education</strong>
                  </h5>
                  <p className=" education-section">
                    {user == null || !user?.education?.length
                      ? "-"
                      : user?.education?.map((edu, ind) => (
                          <p key={ind}>
                            {edu.eductionType} - {edu.college}
                          </p>
                        ))}
                    <br />
                  </p>
                </div>
              </aside>
              <aside>
                <div className="PersonalBox">
                  <h5>
                    <strong>Company Name</strong>
                  </h5>
                  <p className="">
                    {user?.companyName ? user?.companyName : "-"}
                  </p>
                </div>
              </aside>
              <aside>
                <div className="PersonalBox">
                  <h5>
                    <strong>Occupational sector</strong>
                  </h5>
                  <p className="">
                    {user?.occupationSector ? user?.occupationSector : "-"}
                  </p>
                </div>
              </aside>
              <aside>
                <div className="PersonalBox">
                  <h5>
                    <strong>Occupational Title</strong>
                  </h5>
                  <p className="">
                    {user?.occupationTitle ? user?.occupationTitle : "-"}
                  </p>
                </div>
              </aside>
              <aside>
                <div className="PersonalBox">
                  <h5>
                    <strong>Work Experience</strong>
                  </h5>
                  <p className="">
                    {user?.workExperience ? user?.workExperience : "-"}
                  </p>
                </div>
              </aside>
            </article>
          

            {
  user && user?.accountType === "Expert" && (
    <div className="AreasOfExpertise">
      <h5>
        <strong>Areas of Expertise</strong>
      </h5>
      <div className="Box">{user.expertise}</div>
    </div>
  )
}



              {/* <div className="AreasOfExpertise">
              <h5>
                <strong>Areas of Expertise</strong>
              </h5>
              <div className="Box">Research, Wifeframe, SQL, Database</div>
               </div> */}
               

          
            <div className="DocumentsUploaded">
              <h5>
                <strong>Documents Uploaded</strong>
              </h5>
              <ul>
                {user?.documents?.length ? (
                  user?.documents?.map((doc, index) => (
                    <li key={index}>
                      <a
                        href="javascript:void(0);"
                        onClick={() => triggerDownloadFromLink(doc?.doclink)}
                      >
                        {doc?.docName}
                      </a>
                    </li>
                  ))
                ) : (
                  <li>No documents to show</li>
                )}
              </ul>
            </div>

            {user == null
              ? "-"
              : user?.interest?.length
              ? user?.interest?.map((item, index) => (
                   
                  <div className="InterestArea">
                    {/* <h5 className="mb-2">{item.interestType}</h5> */}
                    <strong className="mb-2">{item.interestType}</strong>
                    <ul className="Interest">
                      {item?.value?.length
                        ? item.value.map((item2, ind) => <li>{item2}</li>)
                        : null}
                    </ul>
                  </div>
                ))
              : "-"}

            {/* <div className="InterestArea">
              <h5 className="mb-2">Interest</h5>
              <ul className="Interest">
                <li>Bollywood gossip</li>
                <li>Ask India</li>
                <li>Tech News</li>
                <li>Entertainment News</li>
                <li>Tollywood</li>
                <li>Celebrities</li>
                <li>Mumbai</li>
                <li>Ask India</li>
                <li>Bollywood gossip</li>
                <li>Ask India</li>
                <li>Tech News</li>
                <li>Entertainment News</li>
                <li>Tollywood</li>
                <li>Celebrities</li>
                <li>Mumbai</li>
                <li>Ask India</li>
              </ul>
            </div> */}
          </div>
          <div className="PersonalDetailsBody">
            <span className="Reports">Reports</span>
            <div className="CommonTabs d-flex justify-content-between align-items-center">
              <ul className="nav nav-tabs">
                <li className="nav-item">
                  <a
                    className="nav-link active"
                    data-toggle="tab"
                    href="#AccountReports"
                  >
                    Account Reports
                  </a>
                </li>
                <li className="nav-item" onClick={()=>handlePostReport(user._id)}>
                  <a className="nav-link" data-toggle="tab" href="#PostReports">
                    Post Reports
                  </a>
                </li>
              </ul>

              <div className="FilterBody">
                <div className="FilterRight">
                  <div className="form-group">
                    <div className="dropdown">
                      <a
                        className="Filter"
                        href="javascript:void(0);"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <i className="fa-solid fa-filter" />
                      </a>
                      <div
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuLink"
                      >
                        <ul>
                          <li>
                            <a
                              href="javascript:void(0);"
                              className="dropdown-item"
                            >
                              Unseen
                            </a>
                          </li>
                          <li>
                            <a
                              href="javascript:void(0);"
                              className="dropdown-item"
                            >
                              Seen
                            </a>
                          </li>
                          <li>
                            <a
                              href="javascript:void(0);"
                              className="dropdown-item"
                            >
                              All
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div className="tab-content">
              <div className="tab-pane fade active show" id="AccountReports">
                <div className="AccountReportsArea">
                  <aside>
                    <div className="ProfileBodyAccount">
                      <label className="CheckBox">
                        <input type="checkbox" />
                        <span className="checkmark" />
                      </label>
                      <figure>
                        <img src="images/actress-1.svg" />
                      </figure>
                      <div>
                        <h4>
                          Rhea Sharma <span>1h ago</span>
                        </h4>
                        <h5>@rheasharma</h5>
                      </div>
                    </div>
                    <p>
                      Reason - <span>Span</span>
                    </p>
                  </aside>
                  <aside>
                    <div className="ProfileBodyAccount">
                      <label className="CheckBox">
                        <input type="checkbox" />
                        <span className="checkmark" />
                      </label>
                      <figure>
                        <img src="images/actress-1.svg" />
                      </figure>
                      <div>
                        <h4>
                          Rhea Sharma <span>1h ago</span>
                        </h4>
                        <h5>@rheasharma</h5>
                      </div>
                    </div>
                    <p>
                      Reason - <span>Abusive and Harassing Content</span>
                    </p>
                  </aside>
                  <aside>
                    <div className="ProfileBodyAccount">
                      <label className="CheckBox">
                        <input type="checkbox" />
                        <span className="checkmark" />
                      </label>
                      <figure>
                        <img src="images/actress-1.svg" />
                      </figure>
                      <div>
                        <h4>
                          Rhea Sharma <span>1h ago</span>
                        </h4>
                        <h5>@rheasharma</h5>
                      </div>
                    </div>
                    <p>
                      Reason - <span>MisInformation</span>
                    </p>
                  </aside>
                  <aside>
                    <div className="ProfileBodyAccount">
                      <label className="CheckBox">
                        <input type="checkbox" />
                        <span className="checkmark" />
                      </label>
                      <figure>
                        <img src="images/actress-1.svg" />
                      </figure>
                      <div>
                        <h4>
                          Rhea Sharma <span>1h ago</span>
                        </h4>
                        <h5>@rheasharma</h5>
                      </div>
                    </div>
                    <p>
                      Reason - <span>Privacy Violation</span>
                    </p>
                  </aside>
                  <aside>
                    <div className="ProfileBodyAccount">
                      <label className="CheckBox">
                        <input type="checkbox" />
                        <span className="checkmark" />
                      </label>
                      <figure>
                        <img src="images/actress-1.svg" />
                      </figure>
                      <div>
                        <h4>
                          Rhea Sharma <span>1h ago</span>
                        </h4>
                        <h5>@rheasharma</h5>
                      </div>
                    </div>
                    <p>
                      Reason - <span>MisInformation</span>
                    </p>
                  </aside>
                  <aside>
                    <div className="ProfileBodyAccount">
                      <label className="CheckBox">
                        <input type="checkbox" />
                        <span className="checkmark" />
                      </label>
                      <figure>
                        <img src="images/actress-1.svg" />
                      </figure>
                      <div>
                        <h4>
                          Rhea Sharma <span>1h ago</span>
                        </h4>
                        <h5>@rheasharma</h5>
                      </div>
                    </div>
                    <p>
                      Reason - <span>Privacy Violation</span>
                    </p>
                  </aside>
                  <aside>
                    <div className="ProfileBodyAccount">
                      <label className="CheckBox">
                        <input type="checkbox" />
                        <span className="checkmark" />
                      </label>
                      <figure>
                        <img src="images/actress-1.svg" />
                      </figure>
                      <div>
                        <h4>
                          Rhea Sharma <span>1h ago</span>
                        </h4>
                        <h5>@rheasharma</h5>
                      </div>
                    </div>
                    <p>
                      Reason - <span>Span</span>
                    </p>
                  </aside>
                  <aside>
                    <div className="ProfileBodyAccount">
                      <label className="CheckBox">
                        <input type="checkbox" />
                        <span className="checkmark" />
                      </label>
                      <figure>
                        <img src="images/actress-1.svg" />
                      </figure>
                      <div>
                        <h4>
                          Rhea Sharma <span>1h ago</span>
                        </h4>
                        <h5>@rheasharma</h5>
                      </div>
                    </div>
                    <p>
                      Reason - <span>Abusive and Harassing Content</span>
                    </p>
                  </aside>
                  <aside>
                    <div className="ProfileBodyAccount">
                      <label className="CheckBox">
                        <input type="checkbox" />
                        <span className="checkmark" />
                      </label>
                      <figure>
                        <img src="images/actress-1.svg" />
                      </figure>
                      <div>
                        <h4>
                          Rhea Sharma <span>1h ago</span>
                        </h4>
                        <h5>@rheasharma</h5>
                      </div>
                    </div>
                    <p>
                      Reason - <span>MisInformation</span>
                    </p>
                  </aside>
                </div>
              </div>


              <div className="tab-pane fade" id="PostReports">
                {
               
                 postreportData?.length?
                 
                  postreportData.map((item,idx)=>(


                <div className="NewSide" style={{display:'flex',justifyContent:'space-between'}}>
                  <div className="PostReports" style={{width:'70%'}}>
                  <div className="row">
                    <div className="col-lg-7">
                      <div className="ReportLeft">
                        <label className="CheckBox">
                          <input type="checkbox" />
                          <span className="checkmark" />
                        </label>
                        <h6>{item.reportedToPost.title}</h6>
                        <p>

                    {expandedCardId === idx
              ? item.reportedToPost.description
              : `${ item.reportedToPost.description.slice(0, 100)}...`
              }
                    {index==idx && isExpanded }
                    </p>
                    <p>
                        {item.reportedToPost.tags?.map(tag => <span className="" >{tag}</span>)}
                        #couple#happiness#infinite#together#life#bollywood#celebs#crickter#photos#photoshop#bollywoodstyle
                     </p>
                
             
               <button
               onClick={() => handleShowMore(idx)}
                style={{
                  color: "blue",
                  background: "none",
                  border: "none",
                  cursor: "pointer",
                }}
              >
                 {expandedCardId === idx ? 'Show Less' : 'Show More'}
              </button>
         
                      </div>
                    </div>

{/* =========video section============ */}
      
<div className="col-lg-5">
                    <div className="ReportRight">
                      <a className="Red  cursor-pointer " onClick={()=>deletedPost(item)}>
                        <i className="fa fa-trash"  />
                      </a>

                      <div className="owl-carousel owl-theme">

                        {/* ==========carousel start========= */}
                        {/* <Swiper 
                        pagination={true}
                         modules={[Pagination]} 
                         className="mySwiper"
                         > */}
                        <Swiper 
                       pagination={{ clickable: true }}
                       modules={[Pagination]}
                       className="mySwiper"
                       preventClicks={false}
                       preventClicksPropagation={false}
                         >
                        {/* <SwiperSlide>Slide 1</SwiperSlide>
                        <SwiperSlide>Slide 2</SwiperSlide> */}
                  
                  

      {/* <Carousel activeIndex={index} onSelect={handleSelect}> */}
  {item.reportedToPost.imagesOrVideos.map((data, idx) => (
    <>
    
    {data.fileType === "image" ? (

     <SwiperSlide>
 
        <img
          className="d-block w-100"
          src={data.uri} 
         
          alt="Carousel slide"
         style={{ height: "150px" }}
    />
    </SwiperSlide>
     ) : (
      <SwiperSlide>
         <video
          controls
          style={{ height: "150px" }}
        >
         <source src={data.uri} type={`video/mp4`} />
     
           {/* Your browser does not support the video tag. */}
         </video> 
         <br />
         &nbsp;&nbsp;
        
    </SwiperSlide>

  )}
  

    </>

    // <Carousel.Item key={idx}> {/* Add a unique key */}

    //   {data.fileType === "image" ? (
    //     <img
    //       className="d-block w-100"
    //       src={data.uri} // Dynamic image URL
    //       alt="Carousel slide"
    //       style={{ height: "150px" }}
    //     />
    //   ) : (
    //     <video
    //       controls
    //       style={{ height: "150px" }}
    //     >
    //       <source src={data.uri} type={`video/${data.fileType}`} />
    //       {/* Fallback text */}
    //       Your browser does not support the video tag.
    //     </video>
    //   )}
    // </Carousel.Item>
  ))}
{/* </Carousel> */}
</Swiper>

{/* ========================carousel end=================== */}

                        {/* <div className="item">
                          <figure>
                            <img src={report} />
                          </figure>
                        </div>
                        <div className="item">
                          <figure>
                            <img src={report} />
                          </figure>
                        </div>
                        <div className="item">
                          <figure>
                            <img src={report} />
                          </figure>
                        </div> */}
                      </div>
                    </div>
                  </div>



                    {/* <div className="col-lg-5">
                      <div className="ReportRight">
                        <a className="Red">
                          <i className="fa fa-trash" />
                        </a>
                        <div className="owl-carousel owl-theme">
                          <div className="item">
                            <figure>
                              <img src="images/report-1.png" />
                            </figure>
                          </div>
                          <div className="item">
                            <figure>
                              <img src="images/report-1.png" />
                            </figure>
                          </div>
                          <div className="item">
                            <figure>
                              <img src="images/report-1.png" />
                            </figure>
                          </div>
                        </div>
                      </div>
                    </div> */}

{/* ============video section end ============ */}



                    <div className="col-lg-12">
                      <div className="Card">
                        <h5>Reported By</h5>
                        <a onClick={()=>getAllReportUser(item)}>
                        <img src={roundedImg}  style={{cursor:"pointer"}}/>
                        </a>
                      </div>
                    </div>
                  </div>
                  </div>


                 <div className="ReportedByBody position-static" style={{width:'auto'}}>
                <h1>
                  {" "}
                  <span>
                  <img src={roundedImg}  style={{cursor:"pointer"}}/>
                  </span>{" "}
                  Reported By
                </h1>
                {userReportrdata.map((item,idx)=>(
                  item.reportedBy.map((rpData,index)=>(

              <aside>
                  <div className="ProfileBody">
                    <figure>
                      <img src={rpData.profileImage}  />
                    </figure>
                    <h4>
                   {rpData.name}<span>{getRelativeTime(item.createdAt)}</span>
                    </h4>

                    <h5>{rpData.username}</h5>
                  </div>
                 
                 
                  {item.reportNameDetails.map((reason,i)=>(

                    <p  className="mt-6 pt-3">
                     
                 
                      Reason - <b className="">{reason.reportName}</b>
                    </p>
                    
                  ))}
                
                </aside>
                  ))
                ))}
              </div>


                </div>
                  ))
                  :<div>No record found </div>
                }




{/* ================end code========== */}


                {/* <div className="PostReports">
                  <div className="row">
                    <div className="col-lg-7">
                      <div className="ReportLeft">
                        <label className="CheckBox">
                          <input type="checkbox" />
                          <span className="checkmark" />
                        </label>
                        <h6>This is the Bollywood Gossip</h6>
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipisicing
                          elit. Consequatur amet provident odio hic
                          necessitatibus veniam tenetur doloribus harum
                          dignissimos animi quae numquam eum doloremque
                          accusantium perferendis voluptate.
                        </p>
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipisicing
                          elit. Consequatur cupiditate amet provident odio hic
                          necessitatibus veniam tenetur doloribus harum
                          dignissimos.
                        </p>
                        <p>
                          #couple#happiness#infinite#together#life#bollywood#celebs#crickter#photos#photoshop#bollywoodstyle
                        </p>
                        <a href="javascript:void(0);">Show More</a>
                      </div>
                    </div>
                    <div className="col-lg-5">
                      <div className="ReportRight">
                        <a className="Red">
                          <i className="fa fa-trash" />
                        </a>
                        <div className="owl-carousel owl-theme">
                          <div className="item">
                            <figure>
                              <img src="images/report-1.png" />
                            </figure>
                          </div>
                          <div className="item">
                            <figure>
                              <img src="images/report-1.png" />
                            </figure>
                          </div>
                          <div className="item">
                            <figure>
                              <img src="images/report-1.png" />
                            </figure>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="Card">
                        <h5>Reported By</h5>
                        <a href="javasctipt:void(0);">
                          <img src="images/round-1.svg" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="PostReports">
                  <div className="row">
                    <div className="col-lg-7">
                      <div className="ReportLeft">
                        <label className="CheckBox">
                          <input type="checkbox" />
                          <span className="checkmark" />
                        </label>
                        <h6>This is the Bollywood Gossip</h6>
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipisicing
                          elit. Consequatur amet provident odio hic
                          necessitatibus veniam tenetur doloribus harum
                          dignissimos animi quae numquam eum doloremque
                          accusantium perferendis voluptate.
                        </p>
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipisicing
                          elit. Consequatur cupiditate amet provident odio hic
                          necessitatibus veniam tenetur doloribus harum
                          dignissimos.
                        </p>
                        <p>
                          #couple#happiness#infinite#together#life#bollywood#celebs#crickter#photos#photoshop#bollywoodstyle
                        </p>
                        <a href="javascript:void(0);">Show More</a>
                      </div>
                    </div>
                    <div className="col-lg-5">
                      <div className="ReportRight">
                        <a className="Red">
                          <i className="fa fa-trash" />
                        </a>
                        <div className="owl-carousel owl-theme">
                          <div className="item">
                            <figure>
                              <img src="images/report-1.png" />
                            </figure>
                          </div>
                          <div className="item">
                            <figure>
                              <img src="images/report-1.png" />
                            </figure>
                          </div>
                          <div className="item">
                            <figure>
                              <img src="images/report-1.png" />
                            </figure>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="Card">
                        <h5>Reported By</h5>
                        <a href="javasctipt:void(0);">
                          <img src="images/round-1.svg" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>


              
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserDetails;
