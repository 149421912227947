import axiosInstance from "../axios/axios";


class CommunityServices{
    async getCommunity(value=""){
        
        try {
            const res=await axiosInstance.get(`/admin/community/getAllCommunities?search=${value}`);
             console.log("res is",res.data.data.communities);

            if(res.data.success){
                // return res.data.data;
                return res.data.data.communities;
            }else{
                return false;
            }
            
        } catch (error) {
            console.error(error);
        }
    }
    async getCommunityById(id){

        try {
            const res=await axiosInstance.get(`/admin/community/getCommunityDetails?communityId=${id}`);
            console.log("Get All Community by id",res.data.success)
            if(res.data.success){
                return res.data.data;
            }else{
                return false;
            }
            
        } catch (error) {
            console.error(error);
        }
    }

    async communityPopMember(data){
      const res=await axiosInstance.get(`/admin/community/getAllCommunityMembers?communityId=${data.com_id}&roleType=${data.roleType}&search=${data.search} `);
    //  console.log("dfsdfs",res.data.success)
      if(res?.data?.success){
        // console.log("true value",res.data?.data.communityRoles)
        return res.data.data?.communityRoles;
      }else{
        return false;
      }
    }

    async deleteCommunityById(id){

        try {
            const res=await axiosInstance.get(`/admin/community/deleteCommunityById?communityId=${id}`);
            console.log("Delete community",res.data.success)
            if(res.data.success){
                return true;
            }else{
                return false;
            }
            
        } catch (error) {
            console.error(error);
        }
    }

    async getReportedData(comm_id=""){
    
        try {
          
          const res = await axiosInstance.get(`/admin/report/getAllReportedPosts?communityId=${comm_id}`);
          console.log("Communition reported  data",res.data.data.reportedPosts
          )
          if (res.data.success) {
            return {
              success: true,
              data: res.data.data.reportedPosts,
            };
          } else {
            return false;
          }
        } catch (err) {
          console.log("Error Message:", err.message);  // Specific error message
          console.log("Error Response:", err.response); // Server response (if available)
          console.log("Error Config:", err.config);     // Axios request configuration
        }
      }
    

}

export const communityServices=new CommunityServices();