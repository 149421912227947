import React, { useEffect, useState } from 'react'
import { subAdminServices } from '../../services/subAdminServices'
import { showNotification } from '../../helper/helper';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';

function AddSubAdmin() {
const [dropDownData,setDropDownData] =useState([])

  const [addData,setAddData]=useState(

    {
      name: "",
      email: "",
      phoneNumber: "",
      roleId: "",

      permissions: [

        { module: "Dashboard", readAccess: false, fullAccess: false },
        {module:"User / User List", readAccess :false, fullAccess:false},
        { module: "User / Verify Account", readAccess: false, fullAccess: false },
        { module: "User / Expert Request", readAccess: false, fullAccess: false },
        { module: "User / Upgradation Request", readAccess: false, fullAccess: false },
        { module: "Community", readAccess: false, fullAccess: false },
        { module: "Message", readAccess: false, fullAccess: false },
        { module: "Reported Post", readAccess: false, fullAccess: false },
        { module: "Configuration / Interest", readAccess: false, fullAccess: false },
        { module: "Configuration / Report", readAccess: false, fullAccess: false },
        { module: "Configuration / Education", readAccess: false, fullAccess: false },
        { module: "Configuration / Business Cateogry", readAccess: false, fullAccess: false },
        { module: "Configuration / Area Of Expertise", readAccess: false, fullAccess: false },
        { module: "Sub-Admin", readAccess: false, fullAccess: false },
        { module: "Notification", readAccess: false, fullAccess: false },
        { module: "CMS", readAccess: false, fullAccess: false },
      ]
    }
  )


useEffect(()=>{
getDropDown();
},[]);


  const getDropDown=async()=>{

    try {
      const res=await subAdminServices.getRole();
      if(res.success){

        setDropDownData(res.data.data)
        
      
      }
      
    } catch (error) {
      console.log(error)
    }

  }

  // =================input handler================
  const inputHandler=(e)=>{
    setAddData({...addData,[e.target.name]:e.target.value})
  }


      // =========handle checkbox change===========
    const handleCheckboxChange = (e, module) => {
    
      const { name, checked } = e?.target;

      setAddData((prev) => ({
        ...prev,
        permissions: prev.permissions.map((item) =>(
         
           item.module == module
            ? { ...item, [name]: checked } 
            : item
         ) ),
      }));
    };
  

  // =========select dropDown Id===========
      const dropDownHandler=(id)=>{
        setAddData({ ...addData, ["roleId"]: id });
      }

      const submitHandler= async(e)=>{
        e.preventDefault();

        try {
          const res= await subAdminServices.addStaff(addData);

          console.log("res is ",res)
          if(res){
            showNotification("success","add staff","add staff successfully");
          }

        } catch (error) {
          toast.error(error.message)
        }
       
      }

  return (
   <>

   <div className="WrapperArea">
  <div className="WrapperBox">
    <div className="Small-Wrapper">

      <form onSubmit={submitHandler}>

      <div className="CommonForm">
        <h4>Basic Information</h4>
        <div className="row">
          <div className="col-sm-6">
            <div className="form-group">
              <label>Sud Admin Name</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Sub Admin Name"
                value={addData.name}
                name='name'
                onChange={(e) => inputHandler(e)}
              />
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-group">
              <label>Contact Number</label>
              <input
                type="number"
                name='phoneNumber'
                className="form-control"
                placeholder="Enter Contact Number"
                value={addData.phoneNumber}
                onChange={(e) => inputHandler(e)}
              />
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-group">
              <label>Email ID</label>
              <input
                type="email"
                name='email'
                className="form-control"
                placeholder="Enter Email ID"
                value={addData.email}
                onChange={(e) => inputHandler(e)}
              />
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-group">
              <label>Role</label>
              <select className="form-control" onChange={(e)=>dropDownHandler(e.target.value)}>
                <option>--select--</option>
                {dropDownData.map(item=>
                  <option key={item._id} value={item._id} >{item.roleName}</option>
                )}
                {/* <option>Manager</option> */}
              </select>
            </div>
          </div>
        </div>
        <h4>Sub-Admin Rights Access</h4>
        <div className="AdminAccess">
          <table className="table">
            <thead>
              <tr>
                <th>Module</th>
                <th width="150px">Read</th>
                <th width="150px">Full Access</th>
              </tr>
            </thead>
            <tbody>

            {addData.permissions.map((perm) => {
              return (
                <tr key={perm.module}>
                  <td><strong>{perm.module}</strong></td>
                  <td>

                    <div className="Read">
                    <input
                    type="checkbox"
                    name="readAccess"
                    // value={perm.readAccess}
                    checked={perm.readAccess || perm.fullAccess}
                  onChange={(e) => handleCheckboxChange(e, perm.module)}
           
                   />
                      <span>
                        <i className="fa fa-eye" />
                      </span>
                    </div>
                  </td>
                  <td>
                    <div className="Access">
                     <input
                    type="checkbox"
                    name="fullAccess"
                    value={perm.fullAccess}
                  onChange={(e) => handleCheckboxChange(e, perm.module)} 
           
                   />
                      <span>
                        <i className="fa fa-lock" />
                      </span>
                    </div>
                  </td>
                </tr>
              );
            })}


            </tbody>
          </table>
        </div>
        <button className="Button mx-4">Add Sub Admin</button>
        <Link  to="/sub-admin" >Back</Link>
      </div>
      </form>
    </div>
  </div>
</div>
   </>
  )
}

export default AddSubAdmin