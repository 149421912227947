import React from 'react'
import { Modal } from 'react-bootstrap'

function AddNotification({ showModal, handleClose  }) {
  return (
    <Modal show={showModal} onHide={handleClose}>

         <Modal.Body >
         <div className="modal-body">
  <div className="Category">
    <div className='text-end'>
    <a href="javascript:void(0);" className="CloseModal" onClick={()=>handleClose()}>
      ×
    </a>

    </div>
    <h3>Add Notification</h3>
    <div className="form-group mt-3">
      <label>Notification Title</label>
      <input
        type="text"
        className="form-control"
        placeholder="Enter Notification Title"
      />
    </div>
    <div className="form-group mt-3">
      <label>User Type</label>
      <select className="form-control">
        <option>--select--</option>
      </select>
    </div>
    <div className="form-group mt-3">
      <label>Select User</label>
      <select className="form-control">
        <option>--select--</option>
      </select>
    </div>
    <div className="form-group mt-3">
      <label>Select User</label>
      <textarea
        className="form-control"
        rows={4}
        placeholder="write here.."
        defaultValue={""}
      />
    </div>
    <div className="d-flex  my-3 justify-content-between">
      <button className="Button" data-dismiss="modal">
        Add
      </button>
    </div>
  </div>
</div>

            
         </Modal.Body>
    </Modal>
  )
}

export default AddNotification