// store/LoaderSlice.js

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  value: false, // State for the loader (true/false)
};

// Renamed the action to setLoader for clarity
export const loaderSlice = createSlice({
  name: "loader",
  initialState,
  reducers: {
    setLoader: (state, action) => {
      // console.log("action triggered", action); // Debugging line
      // console.log("state before update", state.value);
      state.value = action.payload; // Update state value
      // console.log("state after update", state.value); // Debugging line
    },
  },
});

export const { setLoader } = loaderSlice.actions; // Export the renamed action
export default loaderSlice.reducer; // Export the reducer
