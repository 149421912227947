import thumbnail from "../../assets/images/thumbnail.svg";
import graph from "../../assets/images/graph.svg";
import { configurationService } from "../../services/ConfigurationService";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";

import { Link, Navigate, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
// import { Modal, Button, Form } from 'react-bootstrap';
import ReportPop from "./Popups/ReportPop";

import { showNotification } from "../../helper/helper";
import {setLoader} from "../../store/LoaderSlice"
import AddReportPop from "./Popups/AddReportPop";


// ================tooltips========
import { Tooltip as ReactTooltip } from 'react-tooltip'
import UnauthorzePage from "./UnauthorzePage";


const Report = () => {
  const [hasAccess, setHasAccess] = useState(true); // Default: true
const navigate=useNavigate()
  const permissions = useSelector((state) => state.admin.value.permissions);
  const dispatch = useDispatch();
  const [currentClick,setCurrentClick]=useState("")
  const [reportStatus,setReportStatus]=useState("")
  const [reLoadPage,setReLoadPage] = useState(true)

  const [reports, setReports] = useState([]);

  // ==============pagination code===================

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

const indexOfLastItem = currentPage * itemsPerPage;
const indexOfFirstItem = indexOfLastItem - itemsPerPage;
const currentItems = reports.slice(indexOfFirstItem, indexOfLastItem);

const totalPages = Math.ceil(reports.length / itemsPerPage);

const handlePageChange = (pageNumber) => {
  setCurrentPage(pageNumber);
};


  // =================pagination code ends =================





  const [showModal, setShowModal] = useState(false);
  const [showAddReportModal, setShowAddReportModal] = useState(false);
 

  // =========serach==================
 
  const [interestsBackup, setinterestsBackup] = useState([]);



  const [search, setsearch] = useState("");
  const [searchedString, setsearchedString] = useState("");
  const [limit, setlimit] = useState(10);




  const [editReport,setEditReport]=useState({
    Report_Name:"",
    Description:""
  });
// =====================Modal code===========
  const handleClose = () => setShowModal(false);
  const handleCloseAddReport = () =>setShowAddReportModal(false);
  
  const handleShow = (e,id,status,item,prmis) =>{

    e.preventDefault(); 
   
      if (!prmis?.fullAccess) {
       return showNotification("error","Access denied","you are not allowed to access")
      }
  
    setReportStatus(status)
    setEditReport()
    setEditReport({
      Report_Name: item.reportName || "",   // Set Report_Name if it exists in item
      Description: item.description || ""    // Set Description if it exists in item
    });
    setCurrentClick(id);
    setShowModal(true);
  } 
  
  const handleShowModel=(e)=>{
    e.preventDefault();

    if(permissions?.some((perm) => perm.module == "Configuration / Report" && perm.fullAccess != true)){
       return showNotification("error","Access denied","you are not allowed to access")
    };

     setShowAddReportModal(true)
    }
//  =======================================Modal code end==========


  const inputHandler=(e)=>{
    
    setEditReport({...editReport,[e.target.name]:e.target.value})
  }



  //states for search limit and pagination.



  //.....................................


  useEffect(() => {
    if (search !== "") {
      const lowerCaseSearch = search.toLowerCase();
      const filtered =reports.filter(
        (record) =>
         
          record.reportName.toLowerCase().includes(lowerCaseSearch) ||
          record.status.toLowerCase().includes(lowerCaseSearch) 
       
      );
      if (filtered?.length) {
        setReports(filtered);
      } else {
        toast("No match found.");
      }
    }
  }, [search]);

  useEffect(() => {
    const limitedData = interestsBackup.slice(0, limit);
    if (limitedData?.length) {
    }

 
  }, [limit]);

  async function getInterests() {
   
    const res = await configurationService.getInterest("reports");

    if (res?.success) {
     
      setReports(res.data);
      setinterestsBackup(res.data);
    }
    // dispatch(loader(false));
  }


  // ================================Switch Handler Code===============================
  const statusHandler=async(e,item)=>{
    e.preventDefault();
    if(permissions?.some((perm) => perm.module == "Configuration / Report" && perm.fullAccess != true)){
      return showNotification("error","Access denied","you are not allowed to access")
   };


    const newStatus = item.status === "active" ? "inactive" : "active";
    const data=  {
      configType:"reports",
      reportId: item._id,
      status:newStatus
    }      
    const res = await configurationService.updateConfigData(data);
    if (res?.success) {

 // ==============getintrest data-==============================
      dispatch(setLoader(true));
    const res = await configurationService.getInterest("reports");

    if (res.success) {
      console.log("Status UPdated Reports",res.data)
      setReports(res.data);
      setinterestsBackup(res.data);
      dispatch(setLoader(false));
    }
  
    // ==============genintrest data end-==============================

  
      setinterestsBackup(res.data);
    }
    dispatch(setLoader(false));
  }
  

  // ==============================switch Handler Code end–=============================


  async function deleteConfigHandler(e,id,prmis) {
    e.preventDefault();
    if(!prmis.fullAccess){
      return showNotification("error","Access denied","you are not allowed to access")
    }


    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "OK",
      cancelButtonText: "Cancel",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        const data = {
          configType: "reports",
          reportId: id,
        };
  
        try {
          const res = await configurationService.deleteConfigData(data);
          if (res?.success) {
            console.log("delete", res);
            showNotification("success", "deleted", "deleted successfully");
            // showNotification("success","Bussiness ","bussiness added successfully!")
            setReLoadPage(prevStatus => !prevStatus);
            setinterestsBackup(res.data);
          }
        } catch (error) {
          console.error("Error deleting config:", error);
        } finally {
          dispatch(setLoader(false));
        }
      }
    });
  }
  


  const editFormHandler=async(e)=>{

    e.preventDefault()
    if(editReport.Report_Name?.trim() !== "" && editReport.Description?.trim() !== ""){
    
          const data=  {
            configType:"reports",
            reportId: currentClick,
            reportName:editReport.Report_Name,
            description: editReport.Description,
            // status:isActive ? "active" : 'inactive',
      
          }      
          const res = await configurationService.updateConfigData(data);
          if (res?.success) {
            console.log("Reports",res?.data)
            showNotification("success","Report","Report added successfully!")
            setReLoadPage(prevStatus =>!prevStatus)
            setinterestsBackup(res.data);
            setEditReport({
              Report_Name:"",
              Description:""
             });
            handleClose()
      
          }
          dispatch(setLoader(false));
    }else{
  
      toast("Please provide an input.")
    }

  }

  useEffect(() => {

    const access = permissions?.some(
      (perm) =>
      perm.module === "Configuration / Report" && (perm.fullAccess === true ||perm.readAccess === true)
    );

    if (!access) {
      setHasAccess(false); 
      return; 
    }
  
    getInterests();
  }, [reLoadPage]);

// ====================== Render UnauthorzePage if no access===================
  if (!hasAccess) {
    return <UnauthorzePage />; 
  }

  
  return (
    <>

      <div className="WrapperArea">
        <div className="WrapperBox">
          {/* ===============filter body================= */}
          <div className="FilterBody">
            <div className="FilterLeft d-flex gap-1">
              <div className="form-group">
                <input
                  type="text"
                  value={searchedString}
                  className="form-control"
                  placeholder="Search For Report Name"
                  onChange={(e) => setsearchedString(e.target.value)}
                />
                <span className="Icon">
                  <i className="fa-solid fa-magnifying-glass" />
                </span>
              </div>
              <div className="d-flex gap-1">
                {searchedString.length ? (
                  <button
                    className="btn btn-outline-dark mx-2"
                    onClick={() => {
                      searchedString !== ""
                        ? setsearch(searchedString)
                        : toast("Please provide an input.");
                    }}
                  >
                    Search
                  </button>
                ) : null}
                {search.length ? (
                  <button
                    className="Button"
                    onClick={() => {
                   
                      setReports(interestsBackup);
                      setsearch("");
                      setsearchedString("");
                    }}
                  >
                    Clear
                  </button>
                ) : null}
              </div>
            </div>
            <div className="FilterRight">
              {/* <Link className="Button" to="/add-category"  > */}
              <Link className="Button"  onClick={(e)=>handleShowModel(e)}  >
                Add New &nbsp; <i className="fa-solid fa-circle-plus" />
              </Link>
            </div>
          </div>
          {/* =================filter body=================== */}
          <div className="TableHeader">
            <h5>Total Report Name: {currentItems?.length}/{reports?.length}</h5>
          </div>
          <div className="TableList">
            <table>
              <thead>
                <tr>
                  <th>Sr No.</th>
                  <th>Report Name</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {
                  currentItems.length?
               currentItems?.map((item,index)=>(
                    <tr>
                    <td>{index+1}</td>
                    <td >{item?.reportName}</td>
                    <td>
                      <div className="Actions">
                        <label className="switch">

                          {/* <input type="checkbox" checked={statusValue=== "active"} onClick={(e)=>statusHandler(e,item.status,item)}/> */}
                          <input type="checkbox" checked={item.status== "active"} onClick={(e)=>statusHandler(e,item)}/>
                          <span className="slider" />
                        </label>
                      </div>
                    </td>
                    <td>
                      <div className="Actions">

                      {permissions.map((prmis) => (
                      prmis.module === "Configuration / Report" && (
                       <> 
                        <Link className="Blue" 
                        
                      data-tooltip-id="my-tooltip-1"
                      to="/report/view"
                       state={
                        { item: item
                         }
                        }
                        onClick={(e) => {
                          // console.log("prmis.readAccess",prmis.readAccess)
                          if (!prmis.readAccess) {
                                   
                            e.preventDefault(); 
                            showNotification("error","Access denied","you are not allowed to access")
                          }
                        }}
                        >
                        <i className="fa fa-eye"  />
                      </Link>

                      <Link
                       onClick={(e)=>handleShow(e,item._id,item.status,item ,prmis)}
                        className="Green"
                    
                        data-tooltip-id="my-tooltip-2"
                      >
                        <i className="fa fa-pencil" />
                      </Link>
                      <Link
                        className="Red"
                         data-tooltip-id="my-tooltip-3"
                      >
                        <i className="fa fa-trash"  onClick={(e)=>deleteConfigHandler(e,item._id,prmis)}/>
                      </Link>
                      </>

                   
                 
              )
            ))}


                        {/* <Link className="Blue" 
                        
                        data-tooltip-id="my-tooltip-1"
                        to="/report/view" state={
                          { item: item
                           }
                          } >
                          <i className="fa fa-eye"  />
                        </Link>
                        <Link
                         onClick={()=>handleShow(item._id,item.status,item)}
                          className="Green"
                          // data-toggle="modal"
                          // data-target="#EditReport"
                          data-tooltip-id="my-tooltip-2"
                        >
                          <i className="fa fa-pencil" />
                        </Link>
                        <Link
                          className="Red"
                           data-tooltip-id="my-tooltip-3"
                          // data-toggle="modal"
                          // data-target="#DeleteBusinessCategory"
                        >
                          <i className="fa fa-trash"  onClick={()=>deleteConfigHandler(item._id)}/>
                        </Link> */}
                      </div>
                    </td>
                  </tr>
                  ))
                  :<div>No data found</div>
                }

                {/* <tr>
                  <td>01</td>
                  <td>Spam</td>
                  <td>
                    <div className="Actions">
                      <label className="switch">
                        <input type="checkbox" />
                        <span className="slider" />
                      </label>
                    </div>
                  </td>
                  <td>
                    <div className="Actions">
                      <a className="Blue" href="report-details.html">
                        <i className="fa fa-eye" />
                      </a>
                      <a
                        className="Green"
                        data-toggle="modal"
                        data-target="#EditReport"
                      >
                        <i className="fa fa-pencil" />
                      </a>
                      <a
                        className="Red"
                        data-toggle="modal"
                        data-target="#DeleteBusinessCategory"
                      >
                        <i className="fa fa-trash" />
                      </a>
                    </div>
                  </td>
                </tr> */}
               
              </tbody>
            </table>
          </div>
          <div className="Pagination">
            <div className="Left">
              <p>Show Records:</p>
              <select className="form-control" value={itemsPerPage} onChange={(e)=>setItemsPerPage(e.target.value)}>
                <option selected="" value={10}>10</option>
                <option value={20}>20</option>
                <option value={30}>30</option>
                <option value={40}>40</option>
              </select>
            </div>
            <ul>
            <li>
                <a
                  href="javascript:void(0);"
                  onClick={() => {
                    if (currentPage > 1) {
                      handlePageChange(currentPage - 1);
                    }
                  }}
                  className={currentPage === 1 ? "disabled" : ""}
                >
                  <i className="fa-solid fa-chevron-left" />
                </a>
          </li>

          {[...Array(totalPages)].map((_, i) => {
              const pageNumber = (i + 1).toString().padStart(2, "0"); // Pad single-digit numbers
              return (
                <li key={i} onClick={() => handlePageChange(i + 1)}>
                  <a
                    href="javascript:void(0);"
                    className={currentPage === i + 1 ? "active" : ""}
                  >
                    {pageNumber}
                  </a>
                </li>
              );
            })}




              <li>
              <a
                href="javascript:void(0);"
                onClick={() => {
                  if (currentPage < totalPages) {
                    handlePageChange(currentPage + 1);
                  }
                }}
                className={currentPage === totalPages ? "disabled" : ""}
              >
                <i className="fa-solid fa-chevron-right" />
              </a>
            </li>


            </ul>
          </div>
        </div>
      </div>

      <div className="ModalBox">
  <div id="AddReport" className="modal fade" role="dialog">
    <div className="modal-dialog">
      <ReportPop 
      show={showModal}  
      handleClose={handleClose} 
      showModal={showModal}
      editReport={editReport}
       editFormHandler={editFormHandler}
       inputHandler={inputHandler}
       
      />
    </div>
  </div>
  <div id="EditReport" className="modal fade" role="dialog">
    <div className="modal-dialog">
      <div className="modal-content">
        <div className="modal-body">
          <div className="Category">
            <a
              href="javascript:void(0);"
              className="CloseModal"
              data-dismiss="modal"
            >
              ×
            </a>
            <h3>Configuration - Report</h3>
            <div className="form-group">
              <label>Report Name</label>
              <input
                type="password"
                className="form-control"
                placeholder="Enter Report Name"
              />
            </div>
            <div className="form-group">
              <label>Description</label>
              <textarea
                rows={4}
                className="form-control"
                placeholder="Enter Description"
                defaultValue={""}
              />
            </div>
            <div className="d-flex justify-content-center">
              <button className="Button Cancel mr-3" data-dismiss="modal">
                Cancel
              </button>
              <button className="Button ml-3" data-dismiss="modal">
                Update
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div id="DeleteBusinessCategory" className="modal fade" role="dialog">
    {/* <div className="modal-dialog">
      <div className="modal-content">
        <div className="modal-body">
          <div className="Decline">
            <a
              href="javascript:void(0);"
              className="CloseModal"
              data-dismiss="modal"
            >
              ×
            </a>
            <h3>Delete</h3>
            <p>Are you sure you want to delete this Report ?</p>
            <h4>
              <a href="javascript:void(0);" data-dismiss="modal">
                no
              </a>
              <a href="javascript:void(0);" data-dismiss="modal">
                Yes
              </a>
            </h4>
          </div>
        </div>
      </div>
    </div> */}
  </div>
  <div id="LogOutModal" className="modal fade SmallModal" role="dialog">
    <div className="modal-dialog">
      <div className="modal-content">
        <div className="modal-body">
          <div className="Category">
            <a
              href="javascript:void(0);"
              className="CloseModal"
              data-dismiss="modal"
            >
              ×
            </a>
            <div className="LogOutModalArea">
              <span>
                <img src="images/logout-icon.png" />
              </span>
              <h5>Log Out</h5>
              <p>Are you sure you want to log out?</p>
              <div className="Buttons TwoButtons">
                <button
                  type="button"
                  className="Button Cancel"
                  data-dismiss="modal"
                >
                  No
                </button>
                <a href="login.html" className="Button Red">
                  Yes
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
      </div>

      <AddReportPop   
      showModal={showAddReportModal} 
      handleClose={handleCloseAddReport}
      setReLoadPage={setReLoadPage}
     
      />

      <ReactTooltip
        id="my-tooltip-2"
        place="top"
        content="Edit "
        style={{  fontSize:"10px",padding:"5px 10px" }}
    />
      <ReactTooltip
        id="my-tooltip-3"
        place="top"
        content="Delete "
        style={{  fontSize:"10px",padding:"5px 10px" }}
    />
      <ReactTooltip
        id="my-tooltip-1"
        place="top"
        content="View "
        style={{  fontSize:"10px",padding:"5px 10px" }}
    />
    </>
  );

  // ===================pop up==================
  
}

export default Report;
