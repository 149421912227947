import Header from "./Header";
import Footer from "./Footer";
import Sidebar from "./Sidebar";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Loader from "../Loader/Loader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useEffect } from "react";

const Layout = ({ children }) => {
  const permissions = useSelector((state) => state.admin.value.permissions);
  const loader = useSelector((state) => state.loader.value);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const admin = localStorage.getItem("askbiz_admin");
    if (!admin) {
      if (
        ![
          "/forgot-password",
          "/verification",

          "/success",
          "/send-otp",
          "/reset-password",
        ].includes(location.pathname)
      ) {
        console.log(location.pathname);
        navigate("/");
      }
    }
    if (location.pathname == "/") {
      if (admin) {
        navigate("/dashboard");
      }
    }
// // ======================check route==========
//     if(permissions.some((perm) => perm.module == "Configuration / Area Of Expertise" && perm.fullAccess != true)){
//       return showNotification("error","Access denied","you are not allowed to access")
//     };




  }, [location.pathname]);

  return (
    <>
      {location.pathname == "/" ? null : [
          "/forgot-password",
          "/verification",

          "/success",
          "/send-otp",
          "/reset-password",
        ].includes(location.pathname) ? null : (
        <Header />
      )}
      {/* <Header /> */}
      {location.pathname == "/" ? null : [
          "/forgot-password",
          "/verification",

          "/success",
          "/send-otp",
          "/reset-password",
        ].includes(location.pathname) ? null : (
        <Sidebar />
      )}
      <ToastContainer position="bottom-right" />
      {children}

      {loader ? <Loader /> : null}
      <Footer />
    </>
  );
};

export default Layout;
