import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { store, persistor } from "./store/store.js";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";


import {
  useQuery,
  useMutation,
  useQueryClient,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'

// Create a client

const queryClient = new QueryClient()

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      
      <PersistGate loading={null} persistor={persistor}>
        {/* =========react query========= */}
      <QueryClientProvider client={queryClient}>
        <App />
       </QueryClientProvider>




      </PersistGate>
    </Provider>
  </React.StrictMode>
);

reportWebVitals();
