import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const UserRegister = () => {
  const data = [
    { name: "2020", newUsers: 4000 },
    { name: "2021", newUsers: 3000 },
    { name: "2022", newUsers: 2000 },
    { name: "2023", newUsers: 2780 },
    { name: "2024", newUsers: 1890 },
    { name: "2025", newUsers: 2390 },
    { name: "2026", newUsers: 3490 },
  ];

  return (
    <ResponsiveContainer width="100%" height={250}>
      <BarChart data={data}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="newUsers" fill="#86cba0" barSize={45} />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default UserRegister;
