import React, { useEffect, useState } from "react";
import { communityServices } from "../../services/CommunityService";
import { useParams } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { useQuery } from "@tanstack/react-query";

import roundedImg from "../../assets/images/round-1.svg"
import { useDebounce } from "use-debounce";
import { toast } from "react-toastify";
import { reportServices } from "../../services/ReportServices";

import { Swiper, SwiperSlide } from "swiper/react";
// import required modules
import { Pagination } from 'swiper/modules';
import Swal from "sweetalert2";
import { showNotification } from "../../helper/helper";
import { useDispatch, useSelector } from "react-redux";
import { setLoader } from "../../store/LoaderSlice";
function CommunityDetails() {

const dispatch=useDispatch();


  // ===========search variables =================================
  const [searchText, setSearchText] = useState("");
  const [searchModeratorText, setSearchModeratorText] = useState("");
  const [ModeratorValue] = useDebounce(searchModeratorText, 500);
  const [value] = useDebounce(searchText, 500);

  // ========member variable-==========
  const [searchMemberText, setSearchMemberText] = useState("");
  const [memberValue] = useDebounce(searchMemberText, 500);

  const [showAdminPopData, setShowAdminPopData] = useState([]);
  const [showModeratorPopData, setShowModeratorPopData] = useState([]);
  const [showMemberPopData, setShowMemberPopData] = useState([]);

  const [reportedData,setReportedData] = useState([]);
  const [userReportrdata,setUserReportrdata]=useState([]);

  const [expandedCardId, setExpandedCardId] = useState(null);
  const [isExpanded, setExpanded] = useState(false)



  // =========show more =======
  const handleShowMore=(index)=>{
    setExpandedCardId(index=== expandedCardId ? null : index);
   
  
  }

  useEffect(() => {
    searchFunction();
  }, [value]);

  useEffect(() => {
    searchModeratorFunction();
  }, [ModeratorValue]);

  useEffect(() => {
    searchMemberFunction();
  }, [memberValue]);

  const [showMember, setMemberShow] = useState(false);
  const [showAdmin, setAdminShow] = useState(false);
  const [showModerator, setModeratorShow] = useState(false);

  

  const handleCloseAdmin = () => setAdminShow(false);
  const handleAdminShow = () => setAdminShow(true);

  const handleCloseModerator = () => setModeratorShow(false);
  const handleShowModerator = () => setModeratorShow(true);

  const handleShowMember = () => setMemberShow(true);
  const handleCloseMember = () => setMemberShow(false);

  //  const [communityDetails,setCommunityDetails]=useState({})
  const { id } = useParams();

  const getDetailsById = async (id) => {
    try {
      const res = await communityServices.getCommunityById(id);

      if (res) {
        // setCommunityDetails(res.communityDetails)
        return res.communityDetails;
      }
    } catch (error) {}
  };

  const {
    data: communityDetails,
    isLoading,
    error,
  } = useQuery({
    queryKey: ["communityDetails", id],
    queryFn: () => getDetailsById(id),
  });

  useEffect(() => {
    if (!isLoading && communityDetails) {
      setShowAdminPopData(communityDetails?.admins ?? []);
    }
  }, [communityDetails, isLoading]);

  useEffect(()=>{
    fetchReportedData()
  },[])
  console.log("Ree",reportedData)

  // =============search impilimentation=

  const searchFunction = async () => {
    if (value != "") {
      const data = {
        com_id: id,
        roleType: "admin",
        search: value,
      };
      const searchData = await communityServices.communityPopMember(data);

      setShowAdminPopData(searchData);
    } else {
      const data = {
        com_id: id,
        roleType: "admin",
        search: "",
      };
      const searchData = await communityServices.communityPopMember(data);

      setShowAdminPopData(searchData);
    }
  };

  const searchModeratorFunction = async () => {
    if (ModeratorValue != "") {
      const data = {
        com_id: id,
        roleType: "moderator",
        search: ModeratorValue,
      };
      const searchData = await communityServices.communityPopMember(data);

      setShowModeratorPopData(searchData);
    } else {
      const data = {
        com_id: id,
        roleType: "moderator",
        search: "",
      };
      const searchData = await communityServices.communityPopMember(data);

      setShowModeratorPopData(searchData);
    }
  };

  const searchMemberFunction = async () => {
    if (memberValue != "") {
      const data = {
        com_id: id,
        roleType: "member",
        search: memberValue,
      };
      const searchData = await communityServices.communityPopMember(data);
      console.log("searchData", searchData);
      setShowMemberPopData(searchData);
    } else {
      const data = {
        com_id: id,
        roleType: "member",
        search: "",
      };
      const searchData = await communityServices.communityPopMember(data);
      console.log("searchData", searchData);
      setShowMemberPopData(searchData);
    }
  };

  const DataConversion = (dataString) => {
    const data = new Date(dataString);

    const year = data.getFullYear();

    const month = data.getMonth() + 1;

    const day = data.getDate();

    return `${day}/${month}/${year}`;
  };

   const fetchReportedData=async()=>{
    try {
      const res=await communityServices.getReportedData(id);
      if(res.success){
        // console.log("Reported Data:",res.data)
        setReportedData(res.data);
        const descriptions = res.data.reportedPosts.map((item) => ({
         description: item.reportedToPost.description,
       }));
 
       // Update showMoreDescription with the prepared array
      //  setShowMoreDescription(descriptions);
      }else{
        toast.error(res.message);
      }
     
    } catch (error) {
     console.log(error)
    }

   }

   const deletedPost=async(id)=>{
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "OK",
      cancelButtonText: "Cancel",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
    
  
        try {
          const res = await reportServices.deletedRepost(id);
          if (res?.success) {
            console.log("delete", res);
            showNotification("success", "deleted", "deleted successfully");
            // showNotification("success","Bussiness ","bussiness added successfully!")
          
            // setinterestsBackup(res.data);
          }
        } catch (error) {
          console.error("Error deleting config:", error);
        } finally {
          dispatch(setLoader(false));
        }
      }
    });
  }

  const getAllReportUser=async(item)=>{

    try {
      const res=await reportServices.getReportedUserData(item.postId);
      if(res.success){
        setUserReportrdata(res.data.reportedPosts)
       console.log("All Reported Users:",res.data.reportedPosts)
       
      }else{
        toast.error(res.message);
      }
     
    } catch (error) {
      console.log(error)
    }


  }

  // ===================================time to day convertions================================
function getRelativeTime(createdDate) {
  const createdTime = new Date(createdDate);
  const currentTime = new Date();

  const diffInMs = currentTime - createdTime; // Difference in milliseconds
  const diffInHours = Math.floor(diffInMs / (1000 * 60 * 60)); // Convert to hours
  const diffInDays = Math.floor(diffInHours / 24); // Convert to days

  // Logic for displaying time
  if (diffInHours < 24) {
    return `${diffInHours} ${diffInHours === 1 ? "hour" : "hours"} ago`;
  } else {
    return `${diffInDays} ${diffInDays === 1 ? "day" : "days"} ago`;
  }
}

  if (isLoading) return <p>Loading...</p>;


  return (
    <>
      <div className="WrapperArea">
        <div className="WrapperBox">
          {/* <h6 data-toggle="modal" data-target="#AdminMembers">Add Members</h6>
      <h6 data-toggle="modal" data-target="#ModeratorMembers">ModeratorMembers</h6> */}
          <div className="CommunityBody">
            <div className="CommunityProfile">
              <figure>
                <img
                  src={communityDetails?.coverImage}
                  style={{ width: "70%", borderRadius: "10px" }}
                />
              </figure>
              <div className="ProfileBody">
                <figure>
                  <img src={communityDetails?.logo} className="communityLogo w-100" />
                </figure>
                <figcaption>
                  <h3>{communityDetails?.communityName}</h3>
                  <ul>
                    <li>{communityDetails?.communityUsername}</li>
                    <li>{communityDetails?.location?.cityName}</li>
                  </ul>
                </figcaption>
              </div>
            </div>
            <aside>
              <p>
                <label>Community Name-</label>
                <span>{communityDetails?.communityName}</span>
              </p>
              <p>
                <label>Community Username-</label>
                <span>{communityDetails?.communityUsername}</span>
              </p>
              <p>
                <label>Location-</label>
                <span>{communityDetails?.location?.cityName}</span>
              </p>
              <p>
                <label>Joined Date -</label>
                <span>{DataConversion(communityDetails?.createdAt)}</span>
              </p>
              <p>
                <label>Type -</label>
                <span>{communityDetails?.communityType}</span>
              </p>
              <figcaption>
                {communityDetails?.interest?.map((item, index) => (
                  <>
                    <p>
                      <label>Interest-</label>
                      {/* <label>{item?.interestType}</label> */}
                    </p>
                    <ul>
                      {/* <li>{item?.interestType}</li> */}
                      {item?.value?.map((value, index) => (
                        <li>{value}</li>
                      ))}
                    </ul>
                  </>
                ))}
                {/* <p>
            <label>Interest -</label>
          </p>
          <ul>
            <li>Bollywood Fashion</li>
            <li>Nostalgia</li>
            <li>Ask India</li>
            <li>Bollywood gossip</li>
            <li>Anime</li>
            <li>Delhi</li>
            <li>Big Boss</li>
          </ul> */}
              </figcaption>
              <p>
                <label>Description - </label>
                <span>{communityDetails?.description}</span>
              </p>
              <p>
                <label>Total Members - </label>
                <span>
                  {communityDetails?.members?.length}
                  {communityDetails?.members?.length > 0 && (
                    <a
                      href="javascript:void(0);"
                      onClick={() => handleShowMember()}
                    >
                      View members
                    </a>
                  )}
                </span>
              </p>
              <p>
                <label>Admin Members - </label>
                <span>
                  {communityDetails?.admins?.length}{" "}
                  {communityDetails?.admins?.length > 0 && (
                    <a
                      href="javascript:void(0);"
                      data-toggle="modal"
                      onClick={() => handleAdminShow()}
                    >
                      View members
                    </a>
                  )}
                </span>
              </p>
              <p>
                <label>Moderators - </label>
                <span>
                  {communityDetails?.moderators?.length}{" "}
                  {communityDetails?.moderators?.length > 0 && (
                    <a
                      href="javascript:void(0);"
                      onClick={() => handleShowModerator()}
                    >
                      View members
                    </a>
                  )}
                </span>
              </p>
            </aside>
            <div className="NewSide">
              <article style={{ width: "70%" }}>
                <span className="ReportsBtn">Reports</span>

                {reportedData && reportedData.length > 0 ? (
           reportedData.map((item, index) => (
                <div className="row pt-5">
                  {/* <div className="col-lg-12">
                    <div className="FilterBody">
                      <div className="FilterRight">


                        <div className="form-group">
                          <div className="dropdown">
                            <a
                              className="Filter"
                              href="javascript:void(0);"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <i className="fa-solid fa-filter" />
                            </a>
                            <div
                              className="dropdown-menu"
                              aria-labelledby="dropdownMenuLink"
                            >
                              <ul>
                                <li>
                                  <a
                                    href="javascript:void(0);"
                                    className="dropdown-item"
                                  >
                                    Pending
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="javascript:void(0);"
                                    className="dropdown-item"
                                  >
                                    Approved
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="javascript:void(0);"
                                    className="dropdown-item border-0"
                                  >
                                    Rejected
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div> */}

                  <div className="col-lg-7 ">
                  {/* {reportedData && reportedData.length > 0 ? (
  reportedData.map((item, index) => ( */}
    <div className="ReportLeft" key={index}>
      <h6>{item.reportedToPost.title}</h6>
     
      <p>

        {expandedCardId === index
        ? item.reportedToPost.description
        : `${ item.reportedToPost.description.slice(0, 100)}...`
        }
        {index==index && isExpanded }
        </p>
      <p>
       {item.reportedToPost.tags}
      </p>
      <button
          onClick={() => handleShowMore(index)}
                style={{
                  color: "blue",
                  background: "none",
                  border: "none",
                  cursor: "pointer",
                }}
              >
                 {expandedCardId === index? 'Show Less' : 'Show More'}
              </button>
  
    </div>
  {/* ))
                      ) : (
                        <p>No reports available</p>
                      )} */}
                      

             
           
              </div>

                  <div className="col-lg-5">
                     <div className="ReportRight">
                     <a className="Red"   onClick={()=>deletedPost(item.postId)} style={{cursor:"pointer"}}>
                        <i className="fa fa-trash" />
                      </a>

                      <div className="owl-carousel owl-theme">
                      <Swiper 
                       pagination={{ clickable: true }}
                       modules={[Pagination]}
                       className="mySwiper"
                       preventClicks={false}
                       preventClicksPropagation={false}
                         >
                        {/* <SwiperSlide>Slide 1</SwiperSlide>
                        <SwiperSlide>Slide 2</SwiperSlide> */}
                  
                  

      {/* <Carousel activeIndex={index} onSelect={handleSelect}> */}
  {item.reportedToPost.imagesOrVideos.map((data, idx) => (
    <>
    
    {data.fileType === "image" ? (

     <SwiperSlide className="mb-5">
 
        <img
          className="d-block w-100"
          src={data.uri} 
         
          alt="Carousel slide"
         style={{ height: "150px" }}
    />
    </SwiperSlide>
     ) : (
      <SwiperSlide>
         <video
          controls
          style={{ height: "150px" }}
        >
         <source src={data.uri} type={`video/mp4`} />
     
           {/* Your browser does not support the video tag. */}
         </video>
    </SwiperSlide>

  )}
  

    </>

    // <Carousel.Item key={idx}> {/* Add a unique key */}

    //   {data.fileType === "image" ? (
    //     <img
    //       className="d-block w-100"
    //       src={data.uri} // Dynamic image URL
    //       alt="Carousel slide"
    //       style={{ height: "150px" }}
    //     />
    //   ) : (
    //     <video
    //       controls
    //       style={{ height: "150px" }}
    //     >
    //       <source src={data.uri} type={`video/${data.fileType}`} />
    //       {/* Fallback text */}
    //       Your browser does not support the video tag.
    //     </video>
    //   )}
    // </Carousel.Item>
  ))}
{/* </Carousel> */}
</Swiper>



                        {/* <div className="item">
                          <figure>
                            <img src="images/report-1.png" />
                          </figure>
                        </div>
                        <div className="item">
                          <figure>
                            <img src="images/report-1.png" />
                          </figure>
                        </div>
                        <div className="item">
                          <figure>
                            <img src="images/report-1.png" />
                          </figure>
                        </div> */}
                        
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="Card">
                    <h5>Reported By</h5>
                      <a onClick={()=>getAllReportUser(item)}>
                        <img src={roundedImg}  style={{cursor:"pointer"}}/>
                      </a>
                    </div>
                  </div>
                </div>
                 ))
                ) : (
                  <p className="my-5 pt-5">No reported post available</p>
                )}
              </article>
              <div className="ReportedByBody position-static" style={{width:'auto'}}>
                <h1>
                  {" "}
                  <span>
                  <img src={roundedImg}  style={{cursor:"pointer"}}/>
                  </span>{" "}
                  Reported By
                </h1>
                {userReportrdata.map((item,idx)=>(
                  
                  item.reportedBy.map((rpData,index)=>(

              <aside>
                  <div className="ProfileBody">
                    <figure>
                      <img src={rpData.profileImage} />
                    </figure>
                    <h4>
                   {rpData.name}<span>{getRelativeTime(item.createdAt)}</span>
                    </h4>
                    <h5>{rpData.username}</h5>
                  </div>
                 
                 
                  {item.reportNameDetails.map((reason,i)=>(

                    <p >
                     
                      Reason - <p> <b className="mx-2">{reason.reportName}</b></p>
                    </p>
                    
                  ))}
                
                </aside>
                  ))
                ))}




              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal show={showAdmin} onHide={handleCloseAdmin}>
        <Modal.Body>
          <div className="modal-body">
            <div className="Category">
              <div className="text-end ">
                <a
                  href="javascript:void(0);"
                  className="CloseModal"
                  onClick={() => handleCloseAdmin()}
                >
                  ×
                </a>
              </div>
              <h3>Admin Members</h3>
              <div className="form-group">
                {/* <div className="Search position-relative">
    
        <input
          type="text"
          className="form-control"
          placeholder="Search for admin members"
        />
        <span className="Icon position-absolute t-0">
          <i className="fa-solid fa-magnifying-glass" />
        </span>
      </div> */}

                <div className="input-group my-1">
                  <span className="input-group-text bg-white border-end-0">
                    <i className="fa-solid fa-magnifying-glass text-muted"></i>
                  </span>
                  <input
                    type="search"
                    className="form-control border-start-0"
                    placeholder="Search for admin members"
                    onChange={(e) => {
                      setSearchText(e.target.value);
                    }}
                  />
                </div>
              </div>
              <ul className="ModalUl">
                {showAdminPopData?.length ? (
                  showAdminPopData?.map((item) => (
                    <li key={item._id}>
                      <figure>
                        <img
                          src={item?.memberDetails?.profileImage}
                          alt={`${
                            item.memberDetails?.name ||
                            item.memberDetails?.businessName
                          }'s profile`}
                        />
                      </figure>
                      {item?.memberDetails?.name ||
                        item?.memberDetails?.businessName}
                      <a href="javascript:void(0)">
                        <img
                          src={require("../../assets/images/chatquare.png")}
                          alt="chat icon"
                        />
                      </a>
                    </li>
                  ))
                ) : (
                  <div className="mt-3">No data found</div>
                )}
              </ul>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={showModerator} onHide={handleCloseModerator}>
        <Modal.Body>
          <div className="modal-body">
            <div className="Category">
              <div className="text-end">
                <a
                  href="javascript:void(0);"
                  className="CloseModal"
                  onClick={() => handleCloseModerator()}
                >
                  ×
                </a>
              </div>
              <h3>Moderator Members</h3>
              <div className="form-group">
                <div className="input-group my-2">
                  <span className="input-group-text bg-white border-end-0">
                    <i className="fa-solid fa-magnifying-glass text-muted"></i>
                  </span>
                  <input
                    type="search"
                    className="form-control border-start-0"
                    placeholder="Search for moderators members"
                    onChange={(e) => {
                      setSearchModeratorText(e.target.value);
                    }}
                  />
                </div>
              </div>
              <ul className="ModalUl">
                {showModeratorPopData?.map((item) => (
                  <li key={item?.memberDetails._id}>
                    <figure>
                      <img
                        src={item.memberDetails?.profileImage}
                        alt="invalid url"
                      />
                    </figure>{" "}
                    {item?.memberDetails?.name}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={showMember} onHide={handleCloseMember}>
        <Modal.Body>
          <div className="modal-body">
            <div className="Category">
              <div className="text-end">
                <a
                  href="javascript:void(0);"
                  className="CloseModal"
                  onClick={() => handleCloseMember()}
                >
                  ×
                </a>
              </div>
              <h3>Members</h3>
              <div className="form-group">
                <div className="input-group my-2">
                  <span className="input-group-text bg-white border-end-0">
                    <i className="fa-solid fa-magnifying-glass text-muted"></i>
                  </span>
                  <input
                    type="search"
                    className="form-control border-start-0"
                    placeholder="Search for moderators members"
                    onChange={(e) => {
                      setSearchMemberText(e.target.value);
                    }}
                  />
                </div>
              </div>
              <ul className="ModalUl">
                {showMemberPopData.length > 0 ? (
                  showMemberPopData?.map((item) => (
                    <li key={item?.memberDetails._id}>
                      <figure>
                        <img
                          src={item.memberDetails?.profileImage}
                          alt="invalid url"
                        />
                      </figure>{" "}
                      {item?.memberDetails?.name ||
                        item?.memberDetails?.businessName}
                    </li>
                  ))
                ) : (
                  <div>Data not found</div>
                )}
              </ul>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default CommunityDetails;
