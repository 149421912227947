import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setLoader } from "../../store/LoaderSlice";
import { admin } from "../../store/AdminSlice";
import loginPic from "../../assets/images/login.svg";
import { loginService } from "../../services/LoginService";
import {
  isValidEmail,
  showNotification,
  validatePassword,
} from "../../helper/helper";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";

const Home = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loader = useSelector((state) => state.loader.value);
  const [email, setemail] = useState("");
  const [password, setpassword] = useState("");
  const [inputType, setinputType] = useState("password");

  //function for admin login

  async function handleLogin(e) {
    e.preventDefault();
    console.log("function hit");
    console.log("Email=>", email);

    try {
      dispatch(setLoader(true));
      if (isValidEmail(email) && validatePassword(password).isValid) {
        const res = await loginService.adminLogin(email, password);
        if (res.data.success) {
          showNotification(
            "success",
            "Login Successful",
            "Logged in Successfuly"
          );

          console.log("profile==>", res.data.data.admin_data);
          let userData = res.data.data.admin_data;
          userData.isLoggedIn = true;
          const decoded = jwtDecode(userData.accessToken);
          userData.permissions = decoded.data.permissions;
          dispatch(admin(userData));
          localStorage.setItem(
            "askbiz_admin",
            res.data.data.admin_data.accessToken
          );
          navigate("/dashboard");
        } else {
          showNotification(
            "error",
            "Login Failed",
            "Please check email and password."
          );
        }
      } else {
        toast("Email or Password is incorrect");
      }

      dispatch(setLoader(false));
    } catch (err) {
      console.log(err.message);
      dispatch(setLoader(false));
    }
  }

  return (
    <>
      <div className="LoginArea">
        <div className="LoginRight">
          <img src={loginPic} alt="img" />
        </div>
        <div className="LoginLeft">
          {/* <h2>Askbiz</h2> */}
          <h3>
            <span>Login</span>
          </h3>
          <h4>Please enter the credentials to get into the system.</h4>
          <form onSubmit={handleLogin}>
            <div className="form-group">
              <label>Email Address</label>
              <input
                type="email"
                placeholder="Enter Email Address"
                className="form-control"
                onChange={(e) => setemail(e.target.value)}
                required
              />
              <span className="icon">
                <i className="fa-solid fa-envelope" />
              </span>
            </div>
            <div className="form-group">
              <label>Password </label>
              <input
                type={inputType}
                placeholder="Enter Password"
                className="form-control"
                onChange={(e) => setpassword(e.target.value)}
                minLength="6"
                pattern=".*[\W_].*"
                required
              />

              <span className="icon">
                {inputType == "password" ? (
                  <i
                    className="fa-solid fa-eye passwordEye"
                    onClick={() => {
                      inputType == "password"
                        ? setinputType("text")
                        : setinputType("password");
                    }}
                  />
                ) : (
                  <i
                    class="fa fa-eye-slash passwordEye"
                    aria-hidden="true"
                    onClick={() => {
                      inputType == "password"
                        ? setinputType("text")
                        : setinputType("password");
                    }}
                  />
                )}
              </span>
            </div>
            <div className="Checkboxs">
              {/* <label class="CheckBox">Remember Me 
                  <input type="checkbox">
                  <span class="checkmark"></span>
              </label> */}
              <Link to="/forgot-password">Forgot password?</Link>
            </div>
            <button type="submit" className="Login">
              Log In
            </button>
            {/* <button>Log In <i class="fa fa-sign-in"></i></button> */}
          </form>
        </div>
      </div>
    </>
  );
};

export default Home;
