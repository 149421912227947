const NotFound = () => {
  return (
    <div className="d-flex justify-content-center align-items-center mt-5 pt-5" style={{height: "100vh;"}}>
    <div className="mt-5 pt-5">
        Page not found
    </div>
  </div>
  );
};

export default NotFound;
