import avatar from "../../assets/images/Avatar-1.png";
import { userService } from "../../services/UserService";
import { useState, useEffect } from "react";
import { setLoader } from "../../store/LoaderSlice";
import { useDispatch, useSelector } from "react-redux";
import { getFormattedDate, showNotification } from "../../helper/helper";
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import CountUp from "react-countup";
import UnauthorzePage from "./UnauthorzePage";
const VerifyAccount = () => {
  const dispatch = useDispatch();

  const [hasAccess, setHasAccess] = useState(true); 
  const permissions = useSelector((state) => state.admin.value.permissions);



  const [list, setlist] = useState([]);
  const [data, setdata] = useState({});
  const [extra_data, setextra_data] = useState({});

  //states for filter , search and pagination

  const [filter, setfilter] = useState("");
  const [search, setSearch] = useState("");
  const [searchTemp, setsearchTemp] = useState("");

  const [currentPage, setcurrentPage] = useState("1");
  const [page, setPage] = useState("1");

  const [totalPages, settotalPages] = useState("");
  const [nextPage, setnextPage] = useState(null);
  const [prevPage, setprevPage] = useState(null);

  //...........................................

  const [selectedValue, setSelectedValue] = useState("10");

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  //...........................................

  useEffect(() => {
    const access = permissions.some(
      (perm) =>
        perm.module === "User / Verify Account" && perm.readAccess === true
    );

    if (!access) {
      setHasAccess(false); 
      return; 
    }
    getList(filter, search, selectedValue);
    getExtraData();
  }, []);

  useEffect(() => {

    const access = permissions.some(
      (perm) =>
        perm.module === "User / Verify Account" && perm.readAccess === true
    );

    if (!access) {
      setHasAccess(false); 
      return; 
    }

    getList();
  }, [filter, search, selectedValue, page]);

  async function getList() {
    dispatch(setLoader(true));

    const res = await userService.getVerifyAndExpertList(
      "Bussiness",
      "",
      filter,
      search,
      selectedValue,
      page
    );
    if (res?.success) {
      // console.log("==>", res?.data?.accountRequests);
      setlist(res?.data?.accountRequests);
      setdata(res?.data);

      setnextPage(res?.data?.nextPage);
      setprevPage(res?.data?.prevPage);
      setcurrentPage(res?.data?.currentPage);
    }

    dispatch(setLoader(false));
  }

  async function getExtraData() {
    dispatch(setLoader(true));
    const res = await userService.getExtraDataVerifyList();

    if (res?.success) {
      setextra_data(res?.data);
    }

    dispatch(setLoader(false));
  }

  async function approveReject(id, status,item) {
  //  console.log("send id is",id)
  //  console.log(item)
  //  console.log(status)
  if(permissions.some((perm) => perm.module == "User / Verify Account" && perm.fullAccess != true)){
    return showNotification("error","Access denied","you are not allowed to access")
  };
  
  
    if(item?.status=="pending"){

      dispatch(setLoader(true));
  
      if (status == "Approve") {
        const res = await userService.approveRequest(id);
         console.log("response is",res)
        if (res) {
          toast("Approved");
          getList();
        }
      } else {
        const res = await userService.rejectRequest(id);
        if (res) {
          toast("Rejected");
          getList();
        }
      }
  
      dispatch(setLoader(false));
    }
   
  }

  function resetFilter() {
    setfilter("");
    setSearch("");
    setsearchTemp("");
    setSelectedValue("10");
    setcurrentPage("1");
  }

   // ====================== Render UnauthorzePage if no access===================
 if (!hasAccess) {
  return <UnauthorzePage />; 
}

  return (
    <>
      <div className="WrapperArea">
        <div className="WrapperBox">
          <div className="row">
            <div className="col-sm-4">
              <div className="Dashboard Dashboard4">
                <figcaption>
                  <h4>Total Verified Account</h4>
             
                  <h2><CountUp end={extra_data?.totalBussinessUsers} /></h2>
                </figcaption>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="Dashboard Dashboard5">
                <figcaption>
                  <h4>Total New Request</h4>
                  <h2><CountUp end={extra_data?.bussinessPendingReq} /></h2>
                  {/* <h2>{extra_data?.bussinessPendingReq}</h2> */}
                </figcaption>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="Dashboard Dashboard6">
                <figcaption>
                  <h4>Total Rejected</h4>
                  <h2><CountUp end={extra_data?.bussinessRejectReq} /></h2>
                  {/* <h2>{extra_data?.bussinessRejectReq}</h2> */}

                </figcaption>
              </div>
            </div>
          </div>
          <div className="FilterBody">
            <div className="FilterLeft">
              <div className="form-group">
                <div className="d-flex gap-1">
                  <input
                    type="text"
                    value={searchTemp}
                    className="form-control"
                    placeholder="Search requested users here"
                    onChange={(e) => setsearchTemp(e.target.value)}
                  />
                  <button
                    className="btn btn-outline-dark"
                    onClick={() => {
                      setSearch(searchTemp);
                    }}
                  >
                    Search
                  </button>
                  {filter != "" || search != "" || selectedValue != "10" ? (
                    <button className="Button" onClick={() => resetFilter()}>
                      Clear
                    </button>
                  ) : null}
                </div>
                <span className="Icon">
                  <i className="fa-solid fa-magnifying-glass" />
                </span>
              </div>
            </div>
            <div className="FilterRight">
              <div className="form-group">
                <Dropdown>
                  <Dropdown.Toggle
                    variant="light"
                    id="dropdown-filter"
                    className="Filter"
                  >
                    <i className="fa-solid fa-filter"></i>
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <ul className="filter-list">
                      <li
                        className={filter == "pending" ? "active2" : null}
                        onClick={() => setfilter("pending")}
                      >
                        Pending
                      </li>
                      <li
                        className={filter == "approved" ? "active2" : null}
                        onClick={() => setfilter("approved")}
                      >
                        Approved
                      </li>
                      <li
                        className={filter == "rejected" ? "active2" : null}
                        onClick={() => setfilter("rejected")}
                      >
                        Rejected
                      </li>
                    </ul>
                  </Dropdown.Menu>
                </Dropdown>

                {/* <div className="dropdown">
                  <a
                    className="Filter"
                    href="javascript:void(0);"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i className="fa-solid fa-filter" />
                  </a>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuLink"
                  >
                    <ul>
                      <li>
                        <a href="javascript:void(0);" className="dropdown-item">
                          Pending
                        </a>
                      </li>
                      <li>
                        <a href="javascript:void(0);" className="dropdown-item">
                          Approved
                        </a>
                      </li>
                      <li>
                        <a
                          href="javascript:void(0);"
                          className="dropdown-item border-0"
                        >
                          Rejected
                        </a>
                      </li>
                    </ul>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
          <div className="TableHeader">
            <h5>
              Total Users: {data?.totalUserInAPage}/{data?.totalUsers}
            </h5>
          </div>
          <div className="TableList">
            <table>
              <thead>
                <tr>
                  <th>Sr No.</th>
                  <th>Profile</th>
                  <th>Full Name &amp; Email</th>
                  <th>Username</th>
                  <th>Mobile Number</th>
                  <th>Requested Date</th>
                  <th>Status</th>
                  <th>Approve/Reject</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {list?.length ? (
                  list?.map((item, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>
                        <figure>
                          <img src={avatar} />
                        </figure>
                      </td>
                      <td>
                        {item?.sendByUserUsername || "-"} <br /> {item?.sendByUserEmail}{" "}
                      </td>
                      <td>{item?.sendByUserBusinessName || "-"}</td>
                      <td>{item?.sendByUserPhoneNumber || "-"}</td>
                      <td>
                        {getFormattedDate(item?.requestDate, "YYYY/MM/DD")}
                      </td>
                      <td>
                        <span
                          className={
                            item.status == "approved"
                              ? "Approved"
                              : item.status == "pending"
                              ? "Pending"
                              : "Rejected"
                          }
                        >

                          {item?.status}
                        </span>
                      </td>
                      <td>
                        {item.status === "approved" && (
                          <div className="Actions">
                            <a
                              href="javascript:void(0);"
                              // className="Xmark"
                              className="Check"

                              // onClick={() => approveReject(item._id, "Reject")}
                              onClick={() => approveReject(item._id, "Reject",item)}
                          
                            >
                              <i className="fa-solid fa-check" />
                              {/* <i className="fa-solid fa-xmark" /> */}
                            </a>
                          </div>
                        )}

                        {item.status === "rejected" && (
                          <div className="Actions">
                            <a
                              href="javascript:void(0);"
                              // className="Check"
                              className="Xmark"
                              // onClick={() => approveReject(item._id, "Reject")}
                              onClick={() => approveReject(item._id, "Reject",item)}
                            >
                              <i className="fa-solid fa-xmark" />
                              {/* <i className="fa-solid fa-check" /> */}
                            </a>
                          </div>
                        )}

                        {item.status === "pending" && (
                          <div className="Actions">
                            <a
                              href="javascript:void(0);"
                              className="Xmark"
                              // onClick={() => approveReject(item._id, "Reject")}
                              onClick={() => approveReject(item._id, "Reject",item)}
                            >
                              <i className="fa-solid fa-xmark" />
                            </a>
                            <a
                              href="javascript:void(0);"
                              className="Check"
                              onClick={() => approveReject(item._id, "Approve",item)}
                            >
                              <i className="fa-solid fa-check" />
                            </a>
                          </div>
                        )}
                      </td>

                      <td>
                        <div className="Actions">
                          {/* <Link
                            className="Blue"
                            to={`/view-details?id=${item._id}&type=verify`}
                          >
                            <i className="fa fa-eye" />
                          </Link> */}
                           <Link
                            className="Blue"
                            to={`/user-details/verify/${item?.sendByUserId}`}
                          >
                            <i className="fa fa-eye" />
                          </Link>
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <td>No Data to Dispaly.</td>
                )}

                {/* <tr>
                  <td>1</td>
                  <td>
                    <figure>
                      <img src={avatar} />
                    </figure>
                  </td>
                  <td>
                    TechNova Solutions <br /> technova@gmail.com{" "}
                  </td>
                  <td>technovasol</td>
                  <td>+91 8938920283</td>
                  <td>12th May 2024</td>
                  <td>
                    <span className="Pending">Pending</span>
                  </td>
                  <td>
                    <div className="Actions">
                      <a href="javascript:void(0);" className="Check">
                        <i className="fa-solid fa-check" />
                      </a>
                      <a href="javascript:void(0);" className="Xmark">
                        <i className="fa-solid fa-xmark" />
                      </a>
                    </div>
                  </td>
                  <td>
                    <div className="Actions">
                      <a className="Blue" href="verify-account-details.html">
                        <i className="fa fa-eye" />
                      </a>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>1</td>
                  <td>
                    <figure>
                      <img src={avatar} />
                    </figure>
                  </td>
                  <td>
                    TechNova Solutions <br /> technova@gmail.com{" "}
                  </td>
                  <td>technovasol</td>
                  <td>+91 8938920283</td>
                  <td>12th May 2024</td>
                  <td>
                    <span className="Rejected">Rejected</span>
                  </td>
                  <td>
                    <div className="Actions">
                      <a href="javascript:void(0);" className="Check">
                        <i className="fa-solid fa-check" />
                      </a>
                    </div>
                  </td>
                  <td>
                    <div className="Actions">
                      <a className="Blue" href="verify-account-details.html">
                        <i className="fa fa-eye" />
                      </a>
                    </div>
                  </td>
                </tr> */}
              </tbody>
            </table>
          </div>
          <div className="Pagination">
            <div className="Left">
              <p>Show Records:</p>
              <select
                className="form-control"
                value={selectedValue}
                onChange={handleChange}
              >
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="30">30</option>
                <option value="40">40</option>
              </select>
            </div>
            <ul>
              {nextPage === null ? (
                <li onClick={() => setPage(prevPage)}>
                  <a href="javascript:void(0);">
                    <i className="fa-solid fa-chevron-left" />
                  </a>
                </li>
              ) : null}
              {prevPage == null ? null : (
                <li onClick={() => setPage(prevPage)}>
                  <a href="javascript:void(0);">{prevPage}</a>
                </li>
              )}
              <li>
                <a href="javascript:void(0);" className="active">
                  {currentPage}
                </a>
              </li>

              {nextPage == null ? null : (
                <li onClick={() => setPage(nextPage)}>
                  <a href="javascript:void(0);">{nextPage}</a>
                </li>
              )}

              {prevPage == null ? (
                <li onClick={() => setPage(nextPage)}>
                  <a href="javascript:void(0);">
                    <i className="fa-solid fa-chevron-right" />
                  </a>
                </li>
              ) : null}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default VerifyAccount;
