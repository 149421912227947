// utils/notificationUtils.js
import Swal from "sweetalert2";
import axiosInstance from "../axios/axios";
import moment from "moment/moment";
import { parseISO, format } from "date-fns";
import Papa from "papaparse";

const icons = {
  success: "success",
  error: "error",
  warning: "warning",
};

export const showNotification = (icon, title, message) => {
  if (!icons[icon]) {
    console.error(`Invalid icon type: ${icon}`);
    return;
  }

  return Swal.fire({
    icon: icons[icon],
    title: `<span style="font-size: 1.2rem; font-weight: bold; color: #333;">${title}</span>`,
    html: `<span style="font-size: 1rem; color: #666;">${message}</span>`,
    showConfirmButton: true, // Show the "OK" button
    confirmButtonText: "OK", // Customize the button text
    allowOutsideClick: false, // Prevent closing by clicking outside the modal
    customClass: {
      confirmButton: "custom-confirm-button", // Add your custom class here
    },
  });
};

export const isValidEmail = (email) => {
  // Regular expression for basic email validation
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

export const isValidIndianPhone = (phoneNumber) => {
  // Regular expression to match Indian phone number format
  const phoneRegex = /^[6-9]\d{9}$/;

  // Check if phoneNumber is a non-empty string and matches the regex pattern
  return (
    typeof phoneNumber === "string" &&
    phoneNumber.trim().length > 0 &&
    phoneRegex.test(phoneNumber)
  );
};

export function calculateAge(dobString) {
  // Parse the DOB string into a Date object
  const dob = new Date(dobString);

  // Get the current date
  const today = new Date();

  // Calculate the difference in milliseconds
  let ageDiff = today - dob;

  // Convert the age difference from milliseconds to years
  let ageDate = new Date(ageDiff);
  let calculatedAge = Math.abs(ageDate.getUTCFullYear() - 1970);

  return calculatedAge;
}

export function isImage(file) {
  const allowedTypes = ["image/jpeg", "image/png", "image/gif"];
  return allowedTypes.includes(file.type);
}

// export function generateTransactionId(length = 10) {
//   const characters =
//     "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
//   let result = "";

//   for (let i = 0; i < length; i++) {
//     const randomIndex = Math.floor(Math.random() * characters.length);
//     result += characters.charAt(randomIndex);
//   }

//   return result;
// }

export function generateTransactionId(length = 10, type = "string") {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let result = "";

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    result += characters.charAt(randomIndex);
  }

  if (type === "number") {
    // Convert the string result to a number
    // Note: This conversion might not be possible if the length of result is too large
    const numericResult = parseInt(result, 36); // Base-36 conversion
    return isNaN(numericResult) ? 0 : numericResult;
  }

  return result;
}

export function formatDate(dateString) {
  // Create a Date object from the input date string
  const date = new Date(dateString);

  // Format options for date and time
  const optionsDate = {
    weekday: "long", // Full weekday name
    year: "numeric",
    month: "short", // Short month name
    day: "numeric",
  };

  const optionsTime = {
    hour: "2-digit",
    minute: "2-digit",
    timeZone: "America/Los_Angeles",
    timeZoneName: "short", // Time zone abbreviation (PST/PDT)
  };

  // Create formatters
  const dateFormatter = new Intl.DateTimeFormat("en-US", optionsDate);
  const timeFormatter = new Intl.DateTimeFormat("en-US", optionsTime);

  // Format the date and time
  const formattedDate = dateFormatter.format(date);
  const formattedTime = timeFormatter.format(date);

  return `${formattedDate}, ${formattedTime}`;
}

export function getFormattedDate(dateString, format = "YYYY-MM-DD") {
  // Parse the input date string using Moment.js
  const date = moment(dateString);

  // Check if the date is valid
  if (!date.isValid()) {
    throw new Error("Invalid date format");
  }

  // Format the date using the specified format
  return date.format(format);
}

export function extractTime(dateTimeString) {
  // Create a Date object from the ISO 8601 string
  const date = new Date(dateTimeString);

  // Extract hours and minutes
  const hours = date.getUTCHours().toString().padStart(2, "0");
  const minutes = date.getUTCMinutes().toString().padStart(2, "0");

  // Return time in HH:mm format
  return `${hours}:${minutes}`;
}

export function calculateLastDate(startDate, numberOfMonths) {
  console.log("start date in helper", startDate);
  // Parse the input date string
  const [year, month, day] = startDate.split("/").map(Number);
  const date = new Date(year, month - 1, day); // JavaScript months are 0-based

  // Add the number of months to the date
  date.setMonth(date.getMonth() + numberOfMonths);

  // Calculate the last date of the resulting month
  // Set the date to 0th day of the next month
  const lastDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);

  // Format the date as 'YYYY/MM/DD'
  const lastDateString = [
    lastDate.getFullYear(),
    String(lastDate.getMonth() + 1).padStart(2, "0"),
    String(lastDate.getDate()).padStart(2, "0"),
  ].join("/");

  return lastDateString;
}

export async function startcsvdownload(data) {
  console.log("function hit");
  // Convert JSON to CSV
  const csv = Papa.unparse(data);

  // Create a Blob from the CSV data
  const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

  // Create a link element
  const link = document.createElement("a");
  if (link.download !== undefined) {
    // Create a URL for the Blob and set it as the href attribute
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", "urHealthCare.csv");
    link.style.visibility = "hidden";

    // Append the link to the body
    document.body.appendChild(link);

    // Programmatically click the link to trigger the download
    link.click();

    // Remove the link from the document
    document.body.removeChild(link);
  }
}

export function triggerDownloadFromLink(link) {
  // Create a new anchor element
  const anchor = document.createElement("a");

  // Set the href attribute to the file URL
  anchor.href = link;

  // Do not set the download attribute if you want to use the filename from the URL

  // Append the anchor to the document body (required for Firefox)
  document.body.appendChild(anchor);

  // Programmatically click the anchor to trigger the download
  anchor.click();

  // Remove the anchor from the document body
  document.body.removeChild(anchor);
}

export function isCurrentDateAfter(dateString) {
  // Check if the input is a valid ISO 8601 string
  if (!moment(dateString, moment.ISO_8601, true).isValid()) {
    console.error("Invalid date format");
    return false;
  }

  // Convert the input date string to a moment object
  const givenDate = moment(dateString);

  // Get the current date and time as a moment object
  const currentDate = moment();

  // Compare the two dates
  const result = currentDate.isAfter(givenDate);

  // Log and return the result
  console.log(`Current Date: ${currentDate.format()}`);
  console.log(`Given Date: ${givenDate.format()}`);
  console.log(`Is current date after given date? ${result}`);

  return result;
}

export function formatTimeToAMPM(time) {
  const [hours, minutes] = time.split(":");
  const parsedHours = parseInt(hours, 10);
  const suffix = parsedHours >= 12 ? "PM" : "AM";
  const formattedHours = parsedHours % 12 || 12;
  return `${formattedHours}:${minutes} ${suffix}`;
}

export function formatTimeForInputs(time) {
  const [hours, minutes] = time.split(":");
  const parsedHours = parseInt(hours, 10);
  const suffix = parsedHours >= 12 ? "PM" : "AM";
  const formattedHours = parsedHours % 12 || 12;
  return `${formattedHours}:${minutes} ${suffix}`;
}

export function validatePassword(password) {
  // Regular expressions for password validation
  const minLengthRegex = /.{6,}/; // Checks if the length is at least 6 characters
  const specialCharRegex = /[!@#$%^&*(),.?":{}|<>]/; // Checks for at least 1 special character

  // Create an object to return validation status and message
  let result = {
    isValid: true, // Assume valid unless proven otherwise
    message: "Password is valid.", // Default success message
  };

  // Check if the password is at least 6 characters long
  if (!minLengthRegex.test(password)) {
    result.isValid = false;
    result.message = "Password must be at least 6 characters long.";
    return result;
  }

  // Check if the password contains at least one special character
  if (!specialCharRegex.test(password)) {
    result.isValid = false;
    result.message = "Password must contain at least 1 special character.";
    return result;
  }

  // If all conditions are met, return the valid status
  return result;
}

export function isTimestampValid(timestamp) {
  // Parse the timestamp into a Date object
  const date = new Date(timestamp);

  // Get the current time
  const now = new Date();

  // Calculate the difference in milliseconds
  const differenceInMs = now - date;

  // Convert 5 minutes to milliseconds (5 * 60 * 1000)
  const fiveMinutesInMs = 5 * 60 * 1000;

  // Return true if the timestamp is within the last 5 minutes, otherwise false
  return differenceInMs <= fiveMinutesInMs && differenceInMs >= 0;
}

export function hasData(obj) {

  return obj && Object.keys(obj).length > 0;
}
