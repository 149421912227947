import React from 'react'

function UnauthorzePage() {
  return (
    <div className="d-flex justify-content-center align-items-center mt-5 pt-5" style={{height: "100vh;"}}>
    <div className="mt-5 pt-5">
        You are not allowed to access this page
    </div>
  </div>
  )
}

export default UnauthorzePage