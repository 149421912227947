import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { subAdminServices } from '../../services/subAdminServices';
import { showNotification } from '../../helper/helper';
import { toast } from 'react-toastify';

function EditSubAdmin() {

  const location=useLocation();
  const { id } = location.state || ""

  const [subAdminDetails,setSubAdminDetails] = useState([])
 
  const [roleDropdownData,setRoleDropdownData]=useState([])


  const [editData,setEditData] = useState(
    {
      subAdminId: "",
      status: "",
      name: "",
      email: "",
      phoneNumber: "",
    }
    
  )

  useEffect(()=>{
    getsubAdminDetails();
    getRole();
  },[id])




  const getsubAdminDetails=async()=>{

    try {
      const res=await subAdminServices.getSubAdminById(id);
      if(res.success){

          // Set subAdmin details
        console.log("goele",res.data.data)

      setSubAdminDetails(res.data.data);

    const updatedSubAdminDetails = res.data.data;
    // console.log("updatedSubAdminDetails",updatedSubAdminDetails)
    setEditData((prev) => ({
      ...prev,
      subAdminId: updatedSubAdminDetails._id, 
      ...updatedSubAdminDetails, // Merge other details
    }));
       

      }
    } catch (error) {
      console.log(error)
    }

  }


  const getRole=async()=>{

    try {
      const res=await subAdminServices.getRole();
      if(res.success){
        setRoleDropdownData(res.data.data)
      }
      
    } catch (error) {
      console.log(error)
    }

  }


  const handleInputChange=(e)=>{

    setEditData({...editData,[e.target.name]:e.target.value})
  }


  const handleUpdate = (e, module) => {
    setEditData((prev) => {
      return {
        ...prev,
        permissions: prev.permissions.map((perm) =>
          perm.module === module
            ? {
                ...perm,
                [e.target.name]: e.target.checked,
                ...(e.target.name === "fullAccess" && e.target.checked && { readAccess: true }),
              }
            : perm
        ),
      };
    });
  };


  // const handleUpdate = (e, module) => {
  //   setEditData((prev) => {
  //     return {
  //       ...prev,
  //       permissions: prev.permissions.map((perm) =>
  //         perm.module== module
  //           ? { ...perm, [e.target.name]: e.target.checked } // Toggle fullAccess
  //           : perm
  //       ),
  //     };
  //   });
  // };
  
  const submitHandler=async(e)=>{
    e.preventDefault();
    try {
      const res=await subAdminServices.updateStaf(editData) ;
      console.log("updated data resp",res)
      if(res){

        showNotification("success","updated","updated successfully");

      }
    } catch (error) {
      toast.error(error);
 
     console.log(error)
    }
  }


  return (
   <>
   <div className="WrapperArea">
  <div className="WrapperBox">
    {
      // subAdminDetails.map((item,idx)=>(
      // editData?.map((item,idx)=>(
    <div className="Small-Wrapper">
      <div className="CommonForm">
        <h4>Basic Information</h4>
    <form onSubmit={submitHandler}>
        <div className="row">
          <div className="col-sm-6">
            <div className="form-group">
              <label>Sud Admin Name</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Sub Admin Name"
                name='name'
                value={editData.name}
                onChange={(e)=>handleInputChange(e)}
              />
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-group">
              <label>Contact Number</label>
              <input
                type="text"
                name='phoneNumber'
                value={editData.phoneNumber}
                className="form-control"
                placeholder="Enter Contact Number"
                onChange={(e)=>handleInputChange(e)}
              />
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-group">
              <label>Email ID</label>
              <input
                type="text"
                name='email'
                value={editData.email}
                
                className="form-control"
                placeholder="Enter Email ID"
                onChange={(e)=>handleInputChange(e)}
              />
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-group">
              <label>Role</label>
              <select className="form-control" name='roleId' onClick={(e)=>setEditData({...editData,[e.target.name]:e.target.value})}>
                {roleDropdownData?.map((item) => (
                item.roleName == editData.roleName ? (
                  <option value={item._id} selected key={item.id}>
                    {editData.roleName}
                  </option>
                ) : (
                  <option value={item._id} key={item._id}>
                    {item.roleName}
                  </option>
                )
              ))}
              </select>
            </div>
          </div>
        </div>


        <h4>Sub-Admin Rights Access</h4>
        <div className="AdminAccess">
          <table className="table">
            <thead>
              <tr>
                <th>Module</th>
                <th width="150px">Read</th>
                <th width="150px">Full Access</th>
              </tr>
            </thead>
            <tbody>
   {
          editData?.permissions?.map((item,idx)=>(

              <tr key={item._id}>
                <td>
                  <strong>{item.module}</strong>
                </td>
                <td>
                  <div className="Read">
                    <input type="checkbox"  name='readAccess' checked={item.readAccess ||item.fullAccess} defaultChecked=""  onClick={(e)=>handleUpdate(e,item.module)}/>
                    <span>
                    <i class="fa-solid fa-eye-slash"></i>
                    </span>
                  </div>
                </td>
                <td>
                  <div className="Access">
                    <input type="checkbox" name='fullAccess' checked={item.fullAccess} onClick={(e)=>handleUpdate(e,item.module)}/>
                    <span>
                      <i className="fa fa-lock" />
                    </span>
                  </div>
                </td>
              </tr>
    ))
   }


            
            </tbody>
          </table>
        </div>


      <button className="Button mx-4" >Update</button>
      
      <Link to="/sub-admin" >Back</Link>

    </form>
      </div>
    </div>

      // ))
    }
  </div>
</div>

   </>
  )
}

export default EditSubAdmin