import { useLocation } from "react-router-dom";
import Avatar from "../../assets/images/Avatar-1.png";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setLoader } from "../../store/LoaderSlice";
import { userService } from "../../services/UserService";
import { getFormattedDate, hasData } from "../../helper/helper";

const ViewUserDetails = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  const queryParams = new URLSearchParams(location.search);

  const [data, setdata] = useState({});

  useEffect(() => {
    const id = queryParams.get("id");
    const type = queryParams.get("type");

    console.log("dataaaaaaaa",data)

    console.log("hasData",hasData(data))
    if (id) {
      getDetails(id);
    }
  }, []);

  async function getDetails(id) {
    dispatch(setLoader(true));

    const res = await userService.getDetailedData(id);
    if (res.success) {
      setdata(res.data);
    }
    dispatch(setLoader(false));
  }

 


  //...........................................
  return (
    <>
      <div className="WrapperArea">
        <div className="WrapperBox">
          <div className="Small-Wrapper">
            <div className="SubAdminBox mb-0">
              <figure>
                <img src={Avatar} />
              </figure>
              <figcaption>
                <p>
                  <strong>Full Name</strong>
                  {console.log(hasData(data))}
                  <span> {hasData(data) ? (data?.sendBy?.name ||data?.sendBy?.businessName): "-"}</span>
                </p>
                <p>
                  <strong>Email</strong>
                  <span>{hasData(data) ? data?.sendBy?.email : "-"}</span>
                </p>
                <p>
                  <strong>Username</strong>
                  <span>{hasData(data) ? data?.sendBy?.username : "-"}</span>
                </p>
                <p>
                  <strong>Mobile Number</strong>
                  <span>{hasData(data) ? data?.sendBy?.phoneNumber : "-"}</span>
                </p>
                <p>
                  <strong>Requested Date</strong>
                  <span>
                    {hasData(data) ? getFormattedDate(data?.requestDate) : "-"}
                  </span>
                </p>
                <p>
                  <strong>Status</strong>
                  <span className="text-capitalize">
                    {hasData(data) ? data?.status : "-"}
                  </span>
                </p>
              </figcaption>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewUserDetails;
