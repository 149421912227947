import { toast } from "react-toastify";
import axiosInstance from "../axios/axios";

class SubAdminServices {
    async getSubAdmin(){
        try {

          const res = await axiosInstance.get(`/admin/subAdmin/get-subAdmins`);
        //   console.log("Sub admin Data",res.data)
          if (res.data.success) {
            return {
              success: true,
              data: res.data,
            };
          } else {
            return false;
          }
        } catch (err) {
          console.log("Error Message:", err.message);  // Specific error message
          console.log("Error Response:", err.response); // Server response (if available)
          console.log("Error Config:", err.config);     // Axios request configuration
        }
      }
    async getSubAdminById(id){
        try {

          const res = await axiosInstance.get(`/admin/subAdmin/get-subAdmin-detail?subAdminId=${id}`);
          // console.log("Sub admin Data",res.data)
          if (res.data.success) {
            return {
              success: true,
              data: res.data,
            };
          } else {
            return false;
          }
        } catch (err) {
          console.log("Error Message:", err.message);  // Specific error message
          console.log("Error Response:", err.response); // Server response (if available)
          console.log("Error Config:", err.config);     // Axios request configuration
        }
      }
    async addStaff(data){
        try {
          const res = await axiosInstance.post(`/admin/subAdmin/add-subAdmin`,data);
          console.log("Sub admin Data",res)
          if (res.data.success) {
            return {
              success: true,
            };
          } else {
            return false;
          }
        } catch (err) {
          console.log("Error Message:", err.message);  // Specific error message
          toast.error(err.response.data.message)
          console.log("Error Config:", err.config);     // Axios request configuration
        }
      }
      
    async getRole(){
        try {

          const res = await axiosInstance.get(`/admin/role/getRoles`);
          // console.log("Sub admin Data",res.data)
          if (res.data.success) {
            return {
              success: true,
              data: res.data,
            };
          } else {
            return false;
          }
        } catch (err) {
          console.log("Error Message:", err.message);  // Specific error message
          toast.error(err.response.data.message)
          console.log("Error Config:", err.config);     // Axios request configuration
        }
      }

      async updateStaf(data){
        try {

          const res = await axiosInstance.put(`/admin/subAdmin/updateSubAdmin`,data);
          console.log("data Updated",res.data)
          if (res.data.success) {
            return {
              success: true,
            };
          } else {
            return res.message;
          }
        } catch (err) {
          console.log("Error Message:", err.message);  // Specific error message
          console.log("Error Response:", err.response); // Server response (if available)
          toast.error(err.response.data.message)
          console.log("Error Config:", err.config);     // Axios request configuration
        }
      }


}

export const  subAdminServices = new SubAdminServices();
