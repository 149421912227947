import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const CommunityPopularity = () => {
  const data = [
    { name: "Community A", popularity: 4000 },
    { name: "Community B", popularity: 3000 },
    { name: "Community C", popularity: 2000 },
    { name: "Community D", popularity: 2780 },
    { name: "Community E", popularity: 1890 },
  ];

  return (
    <ResponsiveContainer width="100%" height={300}>
      <BarChart data={data}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="popularity" fill="#ffdefe" barSize={45} />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default CommunityPopularity;
