import { useEffect, useState } from "react";
import { cmsServices } from "../../services/CmsServices";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { showNotification } from "../../helper/helper";



const Cms = () => {


  const permissions = useSelector((state) => state.admin.value.permissions);
  const [cmsAboutData,setCmsAboutData]=useState("");
  const [cmsContactData,setCmsContactData]=useState("");
  const [cmsCommunityData,setCmsCommunityData]=useState("");
  const [cmsPrivacyData,setPrivacyData]=useState("");

  
    async function getCmsData(type){
    try {
      const res=await cmsServices.getCmsContent(type);
      // console.log("CMS data ",res)
      if(res.success){
       
        // "communityGuidelines", "privacyPolicy" , "aboutUs", "contactUs",
        if(type=="aboutUs"){
          setCmsAboutData(res.data.data)
        }else if(type=="contactUs"){
          setCmsContactData(res.data.data)
        }else if(type=="termsAndConditions"){
          setCmsCommunityData(res.data.data)
        }else if(type=="privacyPolicy") {
          setPrivacyData(res.data.data)
        }
   
      }
     
      
    } catch (error) {
      
    }
   }
    


  return (
    <>
      <div className="WrapperArea">
        <div className="WrapperBox">
          <div className="StaticArea">
            <div id="accordion">


              <div className="card" onClick={()=>getCmsData("privacyPolicy")} >
                <div
                  className="card-header"
                  data-toggle="collapse"
                  data-parent="#accordion"
                  href="#collapse1"
                >
                  <h4>Privacy Policy</h4>

                  {/* =============for hide  key======== */}


                  {permissions.map((item) => (
                    item.module === "CMS" && (
                    <Link
                      to={item.fullAccess ? "/cms/edit-containt" : "#"}
                      state={
                        item.fullAccess
                          ? { id: 1, data: cmsPrivacyData } 
                          : null
                      }
                      onClick={(e) => {
                        e.stopPropagation();
                        if (!item.fullAccess) {
                          e.preventDefault(); 
                          showNotification("error","Access denied","you are not allowed to access")
                        }
                      }}
                    
                    >
                      <i className="fa fa-pencil" />
                    </Link>
              )
            ))}

 



                  {/* {permissions.map((item)=>(
                    item.module=="CMS" && item.fullAccess==true &&
                    <Link 
                            to="/cms/edit-containt"
                            state={{
                              id: 1,
                              data: cmsPrivacyData
                            }}
                          >  
                          
                                <i className="fa fa-pencil" />
                              </Link>

                  ))} */}

                 
                 {/* <Link 
                 to="/cms/edit-containt"
                 state={{
                   id: 1,
                   data: cmsPrivacyData
                 }}
               >
                    <i className="fa fa-pencil" />
                  </Link>
                */}
                </div>
                <div id="collapse1" className="panel-collapse collapse in">
                  <div className="card-body">

                    <p dangerouslySetInnerHTML={{__html:cmsPrivacyData}}></p>
                  
                   
                  </div>
                </div>
              </div>

              <div className="card" onClick={()=>getCmsData("aboutUs")}>
                <div
                  className="card-header"
                  data-toggle="collapse"
                  data-parent="#accordion"
                  href="#collapse2"
                >
                  <h4>About Us</h4>


        {permissions.map((item)=>(
        item.module=="CMS" &&
        <Link 
        to="/cms/edit-containt"
        state={{
          id: 2,
          data: cmsAboutData
        }}
        onClick={(e) => {
          e.stopPropagation();
          if (!item.fullAccess) {
            e.preventDefault(); 
            showNotification("error","Access denied","you are not allowed to access")
          }
        }}
      >
           <i className="fa fa-pencil" />
         </Link>

      ))}

                  {/* <Link 
                 to="/cms/edit-containt"
                 state={{
                   id: 2,
                   data: cmsAboutData
                 }}
               >
                    <i className="fa fa-pencil" />
                  </Link> */}
                </div>
                <div id="collapse2" className="panel-collapse collapse">
                  <div className="card-body">

                    <p dangerouslySetInnerHTML={{__html:cmsAboutData}}></p>
                   
                  </div>
                </div>
              </div>


              <div className="card" onClick={()=>getCmsData("contactUs")}>
                <div
                  className="card-header"
                  data-toggle="collapse"
                  data-parent="#accordion"
                  href="#collapse3"
                >
                  <h4>Contact Us</h4>

                  {permissions.map((item)=>(
                item.module=="CMS" &&
                <Link 
                to="/cms/edit-containt"
                state={{
                  id: 3,
                  data: cmsContactData
                }}
                onClick={(e) => {
                  if (!item.fullAccess) {
                    e.stopPropagation();
                    e.preventDefault(); 
                    showNotification("error","Access denied","you are not allowed to access")
                  }
                }}
              >
                  <i className="fa fa-pencil" />
                </Link>

               ))}

                  {/* <Link 
                 data-toggle="collapse"
                  data-parent="#accordion"
                  href="#collapse3"
                 to="/cms/edit-containt"
                 state={{
                   id: 3,
                   data: cmsContactData
                 }}
               >
                    <i className="fa fa-pencil" />
                  </Link> */}


                </div>
                <div id="collapse3" className="panel-collapse collapse">
                  <div className="card-body">
                    <p dangerouslySetInnerHTML={{__html:cmsContactData}}></p>
                  
                 
                  </div>
                </div>
              </div>


              <div className="card" onClick={()=>getCmsData("termsAndConditions")}>
                <div
                  className="card-header"
                  data-toggle="collapse"
                  data-parent="#accordion"
                  href="#collapse4"
                >
                  <h4>Terms &amp; Conditions</h4>

                  {permissions.map((item)=>(
                item.module=="CMS" &&
                <Link 
                to="/cms/edit-containt"
                state={{
                  id: 4,
                  data: cmsCommunityData
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  if (!item.fullAccess) {
                    e.preventDefault(); 
                    showNotification("error","Access denied","you are not allowed to access")
                  }
                }}
              >
                   <i className="fa fa-pencil" />
                 </Link>

               ))}


                  {/* <Link 
                 to="/cms/edit-containt"
                 state={{
                   id: 4,
                   data: cmsCommunityData
                 }}
               >
                    <i className="fa fa-pencil" />
                  </Link> */}


                </div>
                <div id="collapse4" className="panel-collapse collapse">
                  <div className="card-body">

                    <p dangerouslySetInnerHTML={{__html:cmsCommunityData}}></p>
                 
                 
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Cms;
