import React, { useEffect, useState } from "react";

const Timer = ({ initialTime = 60, onComplete }) => {
  const [time, setTime] = useState(initialTime);

  useEffect(() => {
    if (time > 0) {
      const interval = setInterval(() => {
        setTime((prevTime) => prevTime - 1);
      }, 1000);
      return () => clearInterval(interval); // Cleanup on unmount
    } else {
      onComplete(); // Trigger completion callback when time runs out
    }
  }, [time, onComplete]);

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`; // Format as MM:SS
  };

  return (
    <div className="countdown-timer">
      <span>Resend in : {formatTime(time)}</span>
    </div>
  );
};

export default Timer;
