
import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { configurationService } from '../../../services/ConfigurationService';
import { useDispatch } from 'react-redux';
import { setLoader } from '../../../store/LoaderSlice';
import { showNotification } from '../../../helper/helper';
import { toast } from 'react-toastify';

function AddReportPop( { showModal, handleClose,setReLoadPage  }) {
  const dispatch = useDispatch();

  const [addData,setAddData] =useState({
    Report_Name:"",
    Description:""
  })

  const inputHandler=(e)=>{

    setAddData({...addData,[e.target.name]:e.target.value})

  }

  const AddFormHandler=async(e)=>{
    e.preventDefault(addData);
    if(addData.Report_Name?.trim() !== "" && addData.Description?.trim() !== ""){
    const  data={
    configType:"reports",
    reports:{
      reportName:addData.Report_Name,
       description:addData.Description,
        status:"active"
    }
    }

    try {
   
      const res=await configurationService.addInterest(data)
      if(res.success){
       
        showNotification("success","Report",'Report added successfully!')
        handleClose()
        setReLoadPage(prevStatus => !prevStatus);
        setAddData({
          Report_Name:"",
          Description:""
        })
       
      }
      dispatch(setLoader(false));
     
      
    } catch (error) {
      
    }
   }
    else{
      toast("Please provide an input.")
    }
  }

  
  return (
    <>
  <Modal show={showModal} onHide={handleClose}>
    <Modal.Body >
    <div className="modal-body">
          <div className="Category">
            <div style={{textAlign: 'end'}}>

            <a 
              href="javascript:void(0);"
              className="CloseModal"
              data-dismiss="modal"

             onClick={()=>handleClose()} 
            >
              ×
            </a>
            </div>
            <h3>Add Report</h3>
            <form onSubmit={AddFormHandler}>

            <div className="form-group">
              <label>Report Name</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Report Name"
                // value={editReport.Report_Name}
                name='Report_Name'
                onChange={inputHandler}
              />
            </div>
            <div className="form-group">
              <label>Description</label>
              <textarea
                rows={4}
                // value={editReport.Description}
                className="form-control"
                placeholder="Enter Description"
                name='Description'
                defaultValue={""}
                onChange={inputHandler}
              />
            </div>
            <div className="d-flex justify-content-evenly mt-4">
              <p className="Button Cancel mr-3 mx-3"  data-dismiss="modal"   onClick={()=>handleClose()} style={{cursor:"pointer"}}>
                Cancel
              </p>
              <button className="Button" data-dismiss="modal">
                Submit
              </button>
            </div>
            </form>

          </div>
        </div>


  </Modal.Body>
  </Modal>
  </>
  )
}

export default AddReportPop