import React from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import UnauthorzePage from '../Pages/UnauthorzePage';

const ProtectedRoute = ({ children, module}) => {
  const permissions = useSelector((state) => state.admin.value.permissions);

  // Check permissions for the required module
  const hasAccess = permissions.some(
    (perm) =>
      perm.module == module && perm.fullAccess == true
   
  );
  return hasAccess ? children : <UnauthorzePage />;
  // return hasAccess ? children : showNotification("error","Permission denied","you have no permissions");
};
export default ProtectedRoute;
