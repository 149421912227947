import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { cmsServices } from '../../services/CmsServices';
import { showNotification } from '../../helper/helper';


function CmsEdit() {

    const location = useLocation()
    const { id,data } = location.state || ""
    const [editedvalue,setEditValue] = useState(data);
    console.log("daata is",id)
 

    const handleEdit=async(e)=>{
        e.preventDefault();
        try {
        let data;
            switch(id){
                 // "communityGuidelines", "privacyPolicy" , "aboutUs", "contactUs", "termsAndConditions".
                case 1:
                      data={
                        cmsType : "privacyPolicy", 
                       content : editedvalue
                    }
                    break;
                case 2:
                     data={
                        cmsType : "aboutUs", 
                       content : editedvalue
                    }
                    break;
                case 3:
                    data={
                        cmsType : "contactUs", 
                       content : editedvalue
                    }
                    break;
                case 4:
                    data={
                        cmsType : "termsAndConditions", 
                       content : editedvalue
                    }
                    break;
              
            }


            const res=await cmsServices.editCmsContent(data) 
            console.log("res edited response",res)
            if(res?.success){
                showNotification("success","update", "data update successful")
                console.log("data updated successfully")
            }else{
                showNotification("error","update", "failed to update data")
                console.log("failed to update data")
            }
            
        } catch (error) {
            console.error(error.message)
        }

    }
 
  return (
    <>
    <div className="WrapperArea">
  <div className="WrapperBox">
    <div className="Small-Wrapper">
      <div className="CommonForm">
        <h4>Update Content</h4>
        <div className="form-group">
          <div className="row">
            <div className="col-sm-2">
              <label>Description</label>
            </div>
            <form onSubmit={handleEdit}>

            <div className="col-sm-10">
            

              <ReactQuill theme="snow" value={editedvalue} onChange={setEditValue}  style={{height:"200px"}}/>
           
              <div className='mt-5 pt-2 '>

              <button className="Button  border">Submit</button>
              </div>
            </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

    
    </>
    
  )
}

export default CmsEdit