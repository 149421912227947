import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  value: {},
};

export const adminSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {
    admin: (state, action) => {
      console.log("action triggered", action);
      console.log("state before update", state.value);
      state.value = action.payload;
      console.log("state after update", state.value);
    },
  },
});

export const { admin } = adminSlice.actions;
export default adminSlice.reducer;
