import React from 'react';
import { useSelector } from 'react-redux';
import UnauthorzePage from '../Pages/UnauthorzePage';
import { useParams } from 'react-router-dom';





// function ViewProtechtedRoute({children,module}) {
function ViewProtechtedRoute({children,module}) {
  const permissions = useSelector((state) => state.admin.value.permissions);
  const {pagename}=useParams();

  
    if (!module) {
      switch (pagename) {
        case "list":
          module = "User / User List";
        
          break;
        case "verify":
          module = "User / Verify Account";
          break;
        case "expert":
          module = "User / Expert Request";
          break;
        case "upgrade":
          module = "User / Upgradation Request";
          break;
        default:
          module = ""; 
      }
    }
  
    
    
    const hasAccess = permissions.some(
      (perm) =>
        perm.module == module && perm.readAccess==true 
    );
    return hasAccess ? children : <UnauthorzePage />;
}

export default ViewProtechtedRoute