import avatar from "../../assets/images/Avatar-1.png";
const Message = () => {
  return (
    <>
      <div className="WrapperArea">
        <div className="WrapperBox p-0">
          <div className="MessageArea">
            <div className="MessageLeft">
              <div className="AvatarBox">
                <div className="Search">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search by name"
                  />
                  <i className="fa-solid fa-magnifying-glass" />
                </div>
              </div>
              <ul>
                <li>
                  <aside>
                    <span className="Profile">
                      <img src={avatar} />
                    </span>
                    <figcaption>
                      <h6>Kim kardashian</h6>
                      <p>
                        Contrary to popular belief, Lorem Ipsum is not simply
                        random text
                      </p>
                    </figcaption>
                    <h5>1:00 PM</h5>
                    <span className="Count">2</span>
                  </aside>
                </li>
                <li>
                  <aside>
                    <span className="Profile">
                      <img src={avatar} />
                    </span>
                    <figcaption>
                      <h6>
                        Kim kardashian <img src={avatar} />{" "}
                      </h6>
                      <p>
                        Contrary to popular belief, Lorem Ipsum is not simply
                        random text
                      </p>
                    </figcaption>
                    <h5>1:00 PM</h5>
                    <span className="Count">2</span>
                  </aside>
                </li>
                <li>
                  <aside>
                    <span className="Profile">
                      <img src={avatar} />
                    </span>
                    <figcaption>
                      <h6>Kim kardashian</h6>
                      <p>
                        Contrary to popular belief, Lorem Ipsum is not simply
                        random text
                      </p>
                    </figcaption>
                    <h5>1:00 PM</h5>
                    <span className="Count">2</span>
                  </aside>
                </li>
                <li>
                  <aside>
                    <span className="Profile">
                      <img src={avatar} />
                    </span>
                    <figcaption>
                      <h6>Kim kardashian</h6>
                      <p>
                        Contrary to popular belief, Lorem Ipsum is not simply
                        random text
                      </p>
                    </figcaption>
                    <h5>1:00 PM</h5>
                  </aside>
                </li>
                <li>
                  <aside>
                    <span className="Profile">
                      <img src={avatar} />
                    </span>
                    <figcaption>
                      <h6>Kim kardashian</h6>
                      <p>
                        Contrary to popular belief, Lorem Ipsum is not simply
                        random text
                      </p>
                    </figcaption>
                    <h5>1:00 PM</h5>
                  </aside>
                </li>
                <li>
                  <aside>
                    <span className="Profile">
                      <img src={avatar} />
                    </span>
                    <figcaption>
                      <h6>Kim kardashian</h6>
                      <p>
                        Contrary to popular belief, Lorem Ipsum is not simply
                        random text
                      </p>
                    </figcaption>
                    <h5>1:00 PM</h5>
                    <span className="Count">2</span>
                  </aside>
                </li>
                <li>
                  <aside>
                    <span className="Profile">
                      <img src={avatar} />
                    </span>
                    <figcaption>
                      <h6>Kim kardashian</h6>
                      <p>
                        Contrary to popular belief, Lorem Ipsum is not simply
                        random text
                      </p>
                    </figcaption>
                    <h5>1:00 PM</h5>
                    <span className="Count">2</span>
                  </aside>
                </li>
                <li>
                  <aside>
                    <span className="Profile">
                      <img src={avatar} />
                    </span>
                    <figcaption>
                      <h6>Kim kardashian</h6>
                      <p>
                        Contrary to popular belief, Lorem Ipsum is not simply
                        random text
                      </p>
                    </figcaption>
                    <h5>1:00 PM</h5>
                    <span className="Count">2</span>
                  </aside>
                </li>
              </ul>
            </div>
            <div className="MessageRight">
              <div className="AvatarBox">
                <aside>
                  <span>
                    <img src={avatar} />
                  </span>
                  <h5>Kian Sharma</h5>
                  <p>Kiansharma</p>
                </aside>
                <div className="dropdown">
                  <a
                    href="javascript:void(0);"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i className="fa-solid fa-ellipsis" />
                  </a>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuLink"
                  >
                    <ul>
                      <li className="FilterOprions">
                        <h5>More Options</h5>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#">
                          Clear Chat
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#">
                          Delete Chat
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <article>
                <ul>
                  <li>
                    <div className="SentMessage">
                      <a href="javascript:void(0);">
                        <i className="fa-solid fa-trash" />
                      </a>
                      <p>Hey what u r doing?</p>
                      <h6>10:45 AM</h6>
                    </div>
                  </li>
                  <li>
                    <div className="ReceiveMessage">
                      <p>
                        Out for travel, as days are passing and we forget to
                        travel! Out for travel, as days are passing and we
                        forget to travel!
                      </p>
                      <h6>10:46 AM</h6>
                    </div>
                  </li>
                  <li>
                    <div className="SentMessage">
                      <a href="javascript:void(0);">
                        <i className="fa-solid fa-trash" />
                      </a>
                      <p>Nice, Enjoy</p>
                      <h6>10:45 AM</h6>
                    </div>
                  </li>
                  <li>
                    <div className="SentMessage">
                      <a href="javascript:void(0);">
                        <i className="fa-solid fa-trash" />
                      </a>
                      <p>Hey what u r doing?</p>
                      <h6>10:45 AM</h6>
                    </div>
                  </li>
                  <li>
                    <div className="ReceiveMessage">
                      <p>
                        Out for travel, as days are passing and we forget to
                        travel! Out for travel, as days are passing and we
                        forget to travel!
                      </p>
                      <h6>10:46 AM</h6>
                    </div>
                  </li>
                  <li>
                    <div className="SentMessage">
                      <a href="javascript:void(0);">
                        <i className="fa-solid fa-trash" />
                      </a>
                      <p>Nice, Enjoy</p>
                      <h6>10:45 AM</h6>
                    </div>
                  </li>
                  <li>
                    <div className="SentMessage">
                      <a href="javascript:void(0);">
                        <i className="fa-solid fa-trash" />
                      </a>
                      <p>Hey what u r doing?</p>
                      <h6>10:45 AM</h6>
                    </div>
                  </li>
                  <li>
                    <div className="ReceiveMessage">
                      <p>
                        Out for travel, as days are passing and we forget to
                        travel! Out for travel, as days are passing and we
                        forget to travel!
                      </p>
                      <h6>10:46 AM</h6>
                    </div>
                  </li>
                  <li>
                    <div className="ReceiveImage">
                      <figure>
                        <img src={avatar} />
                      </figure>
                    </div>
                  </li>
                  <li>
                    <div className="SentMessage">
                      <a href="javascript:void(0);">
                        <i className="fa-solid fa-trash" />
                      </a>
                      <p>Nice, Enjoy</p>
                      <h6>10:45 AM</h6>
                    </div>
                  </li>
                </ul>
                <div className="SendMessageArea">
                  <div className="Search">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Write a message here"
                    />
                    <div>
                      <a href="javascript:void(0);" className="First">
                        <i className="fa-solid fa-paperclip" />
                      </a>
                      <a href="javascript:void(0);" className="Last">
                        <img src={avatar} />
                      </a>
                    </div>
                  </div>
                  <button className="SendBtn">Send</button>
                </div>
              </article>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Message;
