import { useState } from "react";
import EditNotification from "./Popups/EditNotification";
import { Link } from "react-router-dom";
import AddNotification from "./Popups/AddNotification";

const Notification = () => {
  const [showModal, setShowModal] = useState(false);
  const [AddshowModal, setAddShowModal] = useState(false);

  // ====================handleShowModel===================
  const handleClose = () => setShowModal(false);
  const AddhandleClose = () => setAddShowModal(false);
  const handleShow =()=> setShowModal(true);
  const handleShowModel=()=>{
  
    setShowModal(true)
    }

  const AddhandleShowModel=()=>{
  
    setAddShowModal(true)
    }


  return (
    <>
      <div className="WrapperArea">
        <div className="WrapperBox">
          <div className="FilterBody">
            <div className="FilterLeft Notification">
              <div className="form-group">
                <label>Search</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search users here"
                />
                <span className="Icon">
                  <i className="fa-solid fa-magnifying-glass" />
                </span>
              </div>
              <div className="form-group">
                <label>From Date</label>
                <input
                  type="date"
                  className="form-control2"
                  placeholder="Search users here"
                />
              </div>
              <div className="form-group">
                <label>To Date</label>
                <input
                  type="date"
                  className="form-control2"
                  placeholder="Search users here"
                />
              </div>
              <div className="form-group">
                <label>&nbsp;</label>
                <button className="Button">Apply</button>
                <button className="Button Cancel">
                  <i className="fa fa-refresh" />
                </button>
              </div>
            </div>
            <div className="FilterRight">
              <Link
                className="Button"
                href="javascript:void(0);"
                // data-toggle="modal"
                // data-target="#AddNotification"
                onClick={()=>AddhandleShowModel()}
              >
                Add Notification &nbsp;{" "}
                <i className="fa-solid fa-circle-plus" />
              </Link>
            </div>
          </div>
          <div className="TableList">
            <table>
              <thead>
                <tr>
                  <th>Sr No.</th>
                  <th>Title</th>
                  <th>Message</th>
                  <th>User Type</th>
                  <th>To User</th>
                  <th>No. of Users</th>
                  <th>Sent On</th>
                  <th>Resend</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>ABC</td>
                  <td>Lorem Ipsum</td>
                  <td>All</td>
                  <td>All</td>
                  <td>100</td>
                  <td>06-09-2024, 09:00 AM</td>
                  <td>
                    <a className="RedLink">Resend</a>
                  </td>
                  <td>
                    <div className="Actions">
                      <Link
                       onClick={()=>handleShowModel()}
                        className="Green"
                        // data-toggle="modal"
                        // data-target="#EditNotification"
                      >
                        <i className="fa fa-pencil" />
                      </Link>
                      <a
                        className="Red"
                        data-toggle="modal"
                        data-target="#DeleteNotification"
                      >
                        <i className="fa fa-trash" />
                      </a>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>ABC</td>
                  <td>Lorem Ipsum</td>
                  <td>All</td>
                  <td>All</td>
                  <td>100</td>
                  <td>06-09-2024, 09:00 AM</td>
                  <td>
                    <a className="RedLink">Resend</a>
                  </td>
                  <td>
                    <div className="Actions">
                      <a
                        className="Green"
                        data-toggle="modal"
                        data-target="#EditNotification"
                      >
                        <i className="fa fa-pencil" />
                      </a>
                      <a
                        className="Red"
                        data-toggle="modal"
                        data-target="#DeleteNotification"
                      >
                        <i className="fa fa-trash" />
                      </a>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>1</td>
                  <td>ABC</td>
                  <td>Lorem Ipsum</td>
                  <td>All</td>
                  <td>All</td>
                  <td>100</td>
                  <td>06-09-2024, 09:00 AM</td>
                  <td>
                    <a className="RedLink">Resend</a>
                  </td>
                  <td>
                    <div className="Actions">
                      <a
                        className="Green"
                        data-toggle="modal"
                        data-target="#EditNotification"
                      >
                        <i className="fa fa-pencil" />
                      </a>
                      <a
                        className="Red"
                        data-toggle="modal"
                        data-target="#DeleteNotification"
                      >
                        <i className="fa fa-trash" />
                      </a>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>1</td>
                  <td>ABC</td>
                  <td>Lorem Ipsum</td>
                  <td>All</td>
                  <td>All</td>
                  <td>100</td>
                  <td>06-09-2024, 09:00 AM</td>
                  <td>
                    <a className="RedLink">Resend</a>
                  </td>
                  <td>
                    <div className="Actions">
                      <a
                        className="Green"
                        data-toggle="modal"
                        data-target="#EditNotification"
                      >
                        <i className="fa fa-pencil" />
                      </a>
                      <a
                        className="Red"
                        data-toggle="modal"
                        data-target="#DeleteNotification"
                      >
                        <i className="fa fa-trash" />
                      </a>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="Pagination">
            <div className="Left">
              <p>Show Records:</p>
              <select className="form-control">
                <option selected="">10</option>
                <option>20</option>
                <option>30</option>
                <option>40</option>
              </select>
            </div>
            <ul>
              <li>
                <a href="javascript:void(0);">
                  <i className="fa-solid fa-chevron-left" />
                </a>
              </li>
              <li>
                <a href="javascript:void(0);" className="active">
                  01
                </a>
              </li>
              <li>
                <a href="javascript:void(0);">02</a>
              </li>
              <li>
                <a href="javascript:void(0);">03</a>
              </li>
              <li>
                <a href="javascript:void(0);">
                  <i className="fa-solid fa-chevron-right" />
                </a>
              </li>
            </ul>
          </div>
        </div>
        
      </div>
  
  <EditNotification
      
      showModal={showModal} 
      handleClose={handleClose}
  />
 <AddNotification
  showModal={AddshowModal} 
  handleClose={AddhandleClose}
 />

    </>
  );
};

export default Notification;
